import React from "react";
import styles from './Footer.module.css'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import telegram from '../../assets/images/footer/telegram.svg'
import vk from '../../assets/images/footer/vk.svg'
import yandex from '../../assets/images/footer/yandex.png'
import youtube from '../../assets/images/footer/youtube.png'
import android from '../../assets/images/footer/android.svg'
import apple from '../../assets/images/footer/apple.svg'
import iphone from '../../assets/images/footer/iphoneBg.png'
import screen from '../../assets/images/footer/iphoneScreen.png'
import QR from '../../assets/images/footer/QR.png'
import {NavLink} from "react-router-dom";
import pdf from '../../assets/PDF/ECO_MARKET.pdf'
import {thresholds} from "../..//constants";
import { style } from "@mui/system";

export function isMobile() {
    return window.innerWidth <= thresholds.mobile
}

const Footer = () => {

    return <div className={styles.wrapper}>
        <div className={styles.container}>

            <div className={styles.logoContent}>
                <NavLink to={'/'} onClick={() => window.scrollTo(0, 0)}>
                    <div style={{marginBottom: 25}}><SText size={32} weight={900} color={Colors.green}
                                                           lineHeight={40}>{'Ecomarket'}</SText></div>
                </NavLink>
                <div className={styles.socials}>
                    <a target={'_blank'} href="https://vk.com/ecomarket_russia" rel="noreferrer"><img src={vk}/></a>
                    <a href="https://t.me/ecomarketru" target={'_blank'} rel="noreferrer"><img src={telegram}/></a>
                    <a href="https://www.youtube.com/channel/UC9n4Aw4wGlUuUCcusNiEyTg" target={'_blank'} rel="noreferrer"><img src={youtube}/></a>
                    <a href="https://zen.yandex.ru/id/5ef1d13e31fe214a5ab83316" target={'_blank'} rel="noreferrer"><img src={yandex}/></a>
                </div>
                {!isMobile() && <div>
                    <SText size={12} weight={500} color={'#cdcdcd'} lineHeight={15}>{'© 2022 “OOO Экомаркет”'}</SText>
                </div> }
            </div>
            

            <div className={styles.info}>
                <div>
                    <NavLink to={'/about'} onClick={() => window.scrollTo(0, 0)}>
                        <div style={{marginBottom: 25, cursor: 'pointer'}}><SText size={14} weight={500} color={'#999'}
                                                                                  lineHeight={17}>{'О нас'}</SText>
                        </div>
                    </NavLink>
                    <NavLink to={'/pay'} onClick={() => window.scrollTo(0, 0)}>
                        <div style={{marginBottom: 25, cursor: 'pointer'}}><SText size={14} weight={500} color={'#999'}
                                                                                  lineHeight={17}>{'Оплата'}</SText>
                        </div>
                    </NavLink>
                    <NavLink to={'/delivery'} onClick={() => window.scrollTo(0, 0)}>
                        <div style={{marginBottom: 25, cursor: 'pointer'}}><SText size={14} weight={500} color={'#999'}
                                                                                  lineHeight={17}>{'Доставка'}</SText>
                        </div>
                    </NavLink>
                </div>

                <div>
                <   NavLink to={'/offer'} onClick={() => window.scrollTo(0, 0)}>
                        <div style={{marginBottom: 25, cursor: 'pointer'}}><SText size={14} weight={500} color={'#999'}
                                                                                  lineHeight={17}>{'Оферта'}</SText>
                        </div>
                    </NavLink>
                    <NavLink to={'/save-nature'} onClick={() => window.scrollTo(0, 0)}>
                        <div style={{marginBottom: 25, cursor: 'pointer'}}><SText size={14} weight={500} color={'#999'}
                                                                                  lineHeight={17}>{'Бережём природу'}</SText>
                        </div>
                    </NavLink>
                    <NavLink to={'/contacts'}>
                        <div style={{marginBottom: 25, cursor: 'pointer'}}><SText size={14} weight={500} color={'#999'}
                                                                                  lineHeight={17}>{'Контакты'}</SText>
                        </div>
                    </NavLink>
                    
                </div>
            </div>

            {isMobile() &&
                <div className={styles.mobileCopyright}>
                    <SText size={12} weight={500} color={'#cdcdcd'} lineHeight={15}>{'© 2022 “OOO Экомаркет”'}</SText>
                </div>
            }

            <div className={styles.QR}>
                <div className={styles.QRtext}>
                    {'300 рублей \n' +
                        ' в подарок \n' +
                        'на первый заказ \n' +
                        'через приложение'}
                    <div style={{display: 'flex', gap: 5, justifyContent: 'flex-end', cursor: 'pointer'}}>
                        <a onClick={() => window.yaCounter49140634.reachGoal('google_play')} href="https://play.google.com/store/apps/details?id=com.ecomarket_ooo"><img src={android}/></a>
                        <a onClick={() => window.yaCounter49140634.reachGoal('apple_store')} href="itms-apps://itunes.apple.com/app/apple-store/id1486148531"><img src={apple}/></a>
                    </div>
                </div>

                <div className={styles.QRphone}>
                    <img src={iphone}/>
                    <div className={styles.screen}><img src={screen}/></div>
                    <div className={styles.QRimg}><img src={QR}/></div>
                </div>
            </div>

            

        </div>
    </div>
}

export default Footer