import React, {useContext, useEffect, useRef, useState, useMemo} from 'react'
import BottomModal from "../../components/BottomModal";
import ExpandedList from "./components/ExpandedList";
import ModeSelector from "./components/ModeSelector";
import ShortList from "./components/ShortList";
import SearchBar from "./components/SearchBar";
import useSearch, {SearchContext} from "../../../components/utils/search";
import SearchResults from "./components/SearchResults";

const modes = [
    {
        label: 'Свернуть'
    },
    {
        label: 'Развернуть'
    },
]

function getContent (mode) {
    switch (mode) {
        case 0: return <ShortList/>
        case 1: return <ExpandedList/>
        case 3: return <SearchResults/>
    }
}


const Search = ({onClose}) => {
    const [_mode, setMode] = useState(1)
    const [searchInputIsFocused, setSearchfocused] = useState(false)
    const scrollRef = useRef(null)
    const {results, query} = useContext(SearchContext)

    useEffect(() =>
    {   
        if (window.initWithSearch) {
            setSearchfocused(true)
        }
    }, [])

    const mode = useMemo(() => {
        if (query.length > 0 || window.initWithSearch || searchInputIsFocused) {
            return 3
        }

        return _mode
    }, [_mode, query, searchInputIsFocused, window.initWithSearch])

    useEffect(()=>{
        if (scrollRef.current)
            scrollRef.current.scrollTo({top: 0, behavior: 'smooth'})
    },[mode, scrollRef.current])

    const doClose = () =>
    {
        setSearchfocused(false)
        onClose()
    }

    return <BottomModal onClose={doClose} ref={scrollRef} useKeyboardDetect={true} init={window.initWithSearch}>
        {/*<ModeSelector modes={modes} mode={mode} onSwitch={setMode}/>*/}
        <SearchBar setSearchfocused={setSearchfocused} />
        {getContent(mode)}
        
    </BottomModal>
}

export default (props)=>{
    const searchData = useSearch()

    return <SearchContext.Provider value={searchData}>
        <Search {...props}/>
    </SearchContext.Provider>
}
