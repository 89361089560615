import React from "react";
import InnerProduct from "../../screens/InnerProduct/InnerProduct";
import {useLocation, useSearchParams} from "react-router-dom";
import OrdersHistoryModal from "../../screens/UserAccount/OrdersHistoryModal";
import CourierChat from "../../screens/Chat/CourierChat";
import DeliveryInfo from "../../screens/Cart/components/DeliveryInfo";
import DeliveryInfoM from '../../mobile/screens/Cart/components/DeliveryInfo'
import {isMobile} from "../../App";
import ProductModal from "../../mobile/screens/ProductModal";
import AuthM from "../../mobile/screens/Auth";
import AuthD from "./../../screens/Auth"
import Search from "../../mobile/screens/Search";
import OrderTradeOff from "../../screens/OrderTradeOff/OrderTradeOff";
import OrderTradeOffM from "../../mobile/screens/OrderTradeOff";
import AddProductModal from "../../screens/UserAccount/AddProductModal";
import AddProductModalM from '../../mobile/screens/UserAccount/components/AddProductModal'
import SupportChat from "../../screens/Chat/SupportChat";
import Cart from "../../mobile/screens/Cart";
import OrderDetails from "../../mobile/screens/OrderDetails";

export function useQueryParams() {
    const [searchParams, setSearchParams] = useSearchParams()
    const {search: paramString} = useLocation()

    const params = paramString
        .substring(1)
        .split('&')
        .map(pairString => pairString.split('='))

    function push(param = []) {
        const newParams = [
            ...params.filter(p => p[0] !== param[0]),
            param
        ].map(p => p.join('=')).join('&')
        setSearchParams('?' + newParams)
    }

    function remove(paramName) {
        const newParams = params.filter(pair => {
            return pair[0] !== paramName
        }).map(pair => pair.join('=')).join('&')
        setSearchParams('?' + newParams)
    }

    function get(paramName) {
        return searchParams.get(paramName)
    }

    return {push, remove, get, params}
}

const ModalController = ({paramName, Component, ComponentMobile = false, ...props}) => {
    const {params, remove} = useQueryParams()

    const paramIndex = params.findIndex(pair => pair[0] === paramName)
    const render = paramIndex !== -1
    const onTop = paramIndex === params.length - 1

    function onClose() {
        remove(paramName)
    }

    if ((!Component && !ComponentMobile) || !render) return null

    return <div style={{zIndex: !onTop ? -1 : undefined}}>
        {(ComponentMobile && isMobile()) ?
            <ComponentMobile {...props} onClose={onClose}/> :
            <Component {...props} onClose={onClose}/>
        }
    </div>
}
const ModalSpectator = () => {
    const {get} = useQueryParams()

    return <>
        <ModalController paramName={'product'}
                         Component={InnerProduct}
                         ComponentMobile={ProductModal}
                         id={get('product')}
        />
        <ModalController paramName={'cart'}
                         ComponentMobile={Cart}
        />
        <ModalController paramName={'search'}
                         ComponentMobile={Search}
                         query={get('search')}
        />
        <ModalController paramName={'auth'}
                         ComponentMobile={AuthM}
                         Component={AuthD}
        />
        <ModalController paramName={'orderDetails'}
                         Component={OrdersHistoryModal}
                         ComponentMobile={OrderDetails}
                         id={get('orderDetails')}
        />
        <ModalController paramName={'chat'}
                         Component={CourierChat}
                         orderId={get('chat')}
        />
        <ModalController paramName={'supportChat'}
                         Component={SupportChat}
        />
        <ModalController paramName={'deliveryInfo'}
                         Component={DeliveryInfo}
                         ComponentMobile={DeliveryInfoM}
        />
        <ModalController paramName={'orderTradeOff'}
                         Component={OrderTradeOff}
                         ComponentMobile={OrderTradeOffM}
        />
        <ModalController paramName={'addProducts'}
                         Component={AddProductModal}
                         ComponentMobile={AddProductModalM}
        />

    </>

}

export default ModalSpectator
