import React, {useContext} from "react";
import styles from "../styles.module.scss";
import car from '../../../../assets/images/cart/car.svg'
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import DeliveryPriceInfo from "../../../../screens/Cart/components/DeliveryPriceInfo";
import HelperContext from "../../../../redux/HelperContext";
import BottomModal from "../../../components/BottomModal";
import {useSelector} from "react-redux";

const Row = ({cart, price, min}) => {

    return (
        <div className={styles.deliveryPriceRow}>
            <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                <img src={car}/>
                <SText size={14} weight={500} lineHeight={15}
                       color={Colors.darkGray}>{'Доставка '} {price} {'₽'}</SText>
            </div>
            <div>
                <SText size={14} weight={500} lineHeight={15}
                       color={Colors.darkGray}>{`при корзине от ${Math.max(min, cart)}`} {'₽'}</SText>
            </div>
        </div>
    )
}

const DeliveryInfo = ({onClose}) => {
    const {delivery} = useContext(HelperContext)
    const slots = useSelector(state=>state.deliverySlots)
    const options = slots.data.options || []

    return <BottomModal onClose={onClose}>
        <div className={styles.modal}>
            <div className={styles.deliveryPriceInfo}><DeliveryPriceInfo/></div>
            <div style={{marginBottom: 28}}><SText size={12} weight={500} color={'#9c9c9c'}
                                                   lineHeight={12}>{`Минимальная сумма заказа ${delivery.minimalOrder} руб`}</SText>
            </div>
            {options.length !== 0 && <><div style={{marginBottom: 20}}><SText size={16} weight={700} lineHeight={20}
                                                   color={Colors.darkGray}>{'Стоимость доставки'}</SText></div>
            <div style={{marginBottom: 33}}>

                {options.map(([cart, price])=>(
                    <Row cart={cart} key={cart} price={price} min={delivery.minimalOrder}/>
                ))}

            </div></>}
            <div className={styles.grayLine}/>
            <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                  color={Colors.darkGray}>{'Информация о доставке'}</SText>
            </div>
            <div className={styles.text}>
                Принимаем и отгружаем заказы 24/7. <br/>
                Доставляем от 60 минут. <br/>
                Минимальная сума заказа {delivery.minimalOrder}. <br/>
            </div>
            <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                  color={Colors.darkGray}>{'Сохранность свежести продуктов'}</SText>
            </div>
            <div className={styles.text}>
                Бережно пакуем и укладываем и везём, соблюдая температурный режим и принцип соседства товаров.
            </div>
{/*            <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                  color={Colors.darkGray}>{'Получение заказа'}</SText>
            </div>
            <div className={styles.text}>
                Если вы не сможете принять заказ в обозначенное время и хотите выбрать другое время доставки, либо у
                вас
                есть дополнения к заказу, то пожалуйста, свяжитесь с контакт-центром. <br/>
                Если у вас есть пожелания по работе сервиса, пожалуйста, также обратитесь в контакт-центр.

            </div>*/}
        </div>
    </BottomModal>
}

export default DeliveryInfo