import React from "react";
import {useDispatch, useSelector} from "react-redux";
import SText from "../../../../components/SText";
import styles from './styles.module.css'
import Colors from "../../../../components/utils/Colors";
import {cartMinus, cartPlus} from "../../../../redux/reducers/cart";
import cartPlusIcon from '../../../../assets/images/cart/plus.svg'
import cartMinusIcon from '../../../../assets/images/cart/minus.svg'
import cartTrashIcon from '../../../../assets/images/cart/garbage.svg'
import {getWeight} from "../../../../components/utils/other";

const BUTTONS_WIDTH = 168
const BUTTON_SIZE = 44

const Buttons = ({product, amount}) => {
    const dispatch = useDispatch()

    function onPlus () {
        dispatch(cartPlus(product.id))
    }

    function onMinus() {
        dispatch(cartMinus(product.id))
    }

    if (product.available <= 0) return <div
        className={styles.addToCartButton}
        style={{backgroundColor: 'transparent', borderColor: Colors.orange, borderStyle: 'solid', borderWidth: 1}}
        >
        <SText color={Colors.orange} weight={500}>{'Нет в наличии'}</SText>
    </div>

    if (amount === 0) return <div onClick={onPlus} className={styles.amountButtonsContainer}>
        <div className={styles.addToCartButton}>
            <SText color={'#fff'} size={16} weight={500}>{'Добавить в корзину'}</SText>
        </div>
    </div>

    return <div className={styles.amountButtonsContainer}>
        <div className={styles.amountButton} onClick={onMinus}>
            <img src={amount === 1 ? cartTrashIcon : cartMinusIcon}/>
        </div>
        <div>
            <SText color={'#333'} size={15} weight={500}>
                {`${Number((product.peramount * amount).toFixed(2))} ${product.ed_izm === 'л' ? 'шт' : product.ed_izm}`}
            </SText>
        </div>
        <div className={styles.amountButton} onClick={onPlus}>
            <img src={cartPlusIcon}/>
        </div>
    </div>
}

const AmountController = ({product}) => {
    //const product = useSelector(state=>state.catalogue.products[productId])
    const amount = useSelector(state=>(state.cart.items[product.id] || 0))


    const isDiscount = !!product.old_price

    const discount = Math.round(100 * (1 - parseFloat(product.price) / parseFloat(product.old_price)))

    return <div className={styles.amountController}>
        <div>
            {isDiscount && <div style={{display: 'flex', gap: 10, alignItems: 'center', marginBottom: 4}}>
                <SText size={16} weight={500} color={'#bdbdbd'} style={{textDecoration: 'line-through'}}>{`${product.old_price} ₽`}</SText>
                <div style={{backgroundColor: '#f1f1f1', padding: '3px 6px', borderRadius: 6}}>
                    <SText weight={700} size={16} color={Colors.orange}>
                        {`${-discount}%`}
                    </SText>
                </div>
            </div>}
            <SText size={28} weight={700}>
                {`${product.price} ₽`}
                <SText size={14} weight={500}>{`  за ${product.peramount} ${product.ed_izm === 'л' ? 'шт' : product.ed_izm}`}</SText>
            </SText>
        </div>
        <div>
           <Buttons product={product} amount={amount}/>
        </div>
    </div>

}

export default AmountController
