import React, {useEffect, useContext, useMemo} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {SearchContext} from "../../components/utils/search";

import goBack from '../../assets/images/cart/goBack.svg'
import notFounded from '../../assets/images/notFoundSearch.svg'
import styles from './Search.module.scss'
import SText from "../../components/SText";
import ProductCard from "../../screens/ProductCard/ProductCard";

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const {query, onQueryChange, loading, results} = useContext(SearchContext)
    const catalogue = useSelector(state => state.catalogue)
    
    useEffect(() => {
        const searchString = searchParams.get('q')
        onQueryChange(searchString)
    },[])

    const filteredResults = useMemo(() => {
        return results.filter(id => !!catalogue.products[id])
    },[results])

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div onClick={() => navigate('/')} className={styles.goBack}><img src={goBack}/></div>
                <SText size={16} lineHeight={16} weight={500}>{'Результаты поиска'}</SText>
            </div>
            { query.length > 0 ?
                <div>
                    <h1>Результаты поиска по запросу: {query}</h1>
                    { loading && 
                        <div style={{display: 'grid', placeItems: 'center', paddingTop: 32}}>
                            {'Поиск...'}
                        </div>
                    }
                    { !results.length && !loading &&
                        <div style={{display: 'grid', placeItems: 'center', paddingTop: 32}}>
                            <div style={{textAlign: 'center'}}>
                                <SText size={18} weight={700} lineHeight={20}>{'К сожалению, этот товар'}</SText> <br/>
                                <SText size={18} weight={700} lineHeight={20}>{'не можем найти и привезти вам'}</SText>
                                <div style={{marginTop: 25}}><img src={notFounded}/></div>
                            </div>
                        </div> 
                    }
                    <div className={styles.products}>
                        {
                            filteredResults.map((productId, index) => <div key={index}><ProductCard string={query} id={productId}/></div>)
                        }
                    </div>
                </div>
                :
                <div>
                    <h1>Вы не указали поисковый запрос!</h1>
                </div>
            }
        </div>
    )
}

export default Search