import React from 'react'
import styles from './Delivery.module.scss'
import SText from "../../../components/SText";
import first from '../../../assets/images/delivery/1.jpg'
import second from '../../../assets/images/delivery/2.jpg'
import third from '../../../assets/images/delivery/3.jpg'
import fourth from '../../../assets/images/delivery/4.jpg'
import fifth from '../../../assets/images/delivery/5.jpg'
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import Colors from "../../../components/utils/Colors";

const Delivery = () => {

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'Доставка'}</SText></div>
        </div>
        <div className={styles.container}>
            <div style={{marginBottom: 30}}><SText size={36} lineHeight={36} weight={500}>{'Доставка продуктов'}</SText>
            </div>
            <div>
                <SText>{'Ecomarket предлагает круглосуточную доставку продуктов на дом, в офис, на дачу или в любое другое удобное для вас место.'}</SText>
            </div>
            <div><SText>{'Минимальная сумма заказа – 2000 руб.'}</SText></div>
            <div style={{marginBottom: 30}}>
                <SText>{'Вы можете оформить доставку заказа на любой удобный день в течение 1 месяца.'}</SText></div>
            <div style={{marginBottom: 15}}><SText size={16} weight={400}
                                                   lineHeight={20}>{'Сохранность свежести продуктов'}</SText></div>
            <div style={{marginBottom: 30}}>
                <SText>{'Доставка осуществляется с соблюдением всех рекомендаций по хранению и транспортировке продуктов питания. Мы учитываем принцип соседства, температурный режим, особенности условий хранения. Чтобы продукты не помялись, мы бережно их упаковываем и укладываем.'}</SText>
            </div>
            <div style={{marginBottom: 15}}><SText size={16} weight={400} lineHeight={20}>{'Получение заказа'}</SText>
            </div>
            <div>
                <SText>{'Если вы не сможете принять заказ в обозначенное время и хотите выбрать другое время доставки, либо у вас есть дополнения в заказе, то пожалуйста, свяжитесь с контакт-центром.'}</SText>
            </div>
            <div style={{marginBottom: 30}}>
                <SText>{'Если вас не устроило качество товара, пожалуйста, обратитесь в контакт-центр и оставьте заявку на возврат.'}</SText>
            </div>
            <div className={styles.grid}>
                <img src={first}/>
                <img src={second}/>
                <img src={third}/>
                <img src={fourth}/>
                <img src={fifth}/>
            </div>
        </div>
    </div>
}

export default Delivery