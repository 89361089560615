import React, {useRef} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import styles from './CatalogueCarouselV2.module.scss'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import {categoryColors, colorToFilter} from "../../../components/utils/ColorFilter";
import {NavLink} from "react-router-dom";
import {useWindowDimensions} from "../../../components/utils/hooks";
import ScrollContainer from "react-indiana-drag-scroll";
import arrow from '../../../assets/images/cart/goBack.svg'

const CatalogueCarouselV2 = ({
                               currentSection, setCurrentSection = () => {
    }
                           }) => {
    const catalogue = useSelector(state => state.catalogue.categories.master)
    const masterCategories = Object.values(catalogue.map)
    const order = catalogue.order
    const orderedMasterCategories = order.map(orderedId => masterCategories.find(elem => elem.id === orderedId))
    const scrollRef = useRef(null)

    const itemRef = useRef(null)
    const prevRef = useRef(null)
    const nextRef = useRef(null)
    const baseRef = useRef(null)

    const onCategoryClick = (sectionId, catId) => {
        setCurrentSection(prev => ({id: sectionId, parentId: catId || prev.parentId}));
    }

    function next() {
        if (scrollRef.current) {
            const itemWidth = itemRef.current.offsetWidth;

            const currentLeft = scrollRef.current.scrollLeft;
            
            scrollRef.current.scrollLeft = currentLeft + itemWidth;

            onScroll()
        }
    }

    function prev() {
        if (scrollRef.current) {
            const itemWidth = itemRef.current.offsetWidth;

            const currentLeft = scrollRef.current.scrollLeft;
            
            if (currentLeft - itemWidth > itemWidth * 2) {
                scrollRef.current.scrollLeft = currentLeft - itemWidth;
            } else {
                scrollRef.current.scrollLeft = 0;
            }

            onScroll()
        }
    }

    const onScroll = () => {
        if (scrollRef.current) {
            const itemWidth = itemRef.current.offsetWidth;
            const scrollRight = scrollRef.current.scrollWidth - (scrollRef.current.scrollLeft + scrollRef.current.clientWidth) + 1;

            if (scrollRef.current.scrollLeft < itemWidth / 2) {
                prevRef.current.classList.add(styles.invicible)
            } else {
                prevRef.current.classList.remove(styles.invicible)
            }

            if (scrollRight < itemWidth / 2) {
                nextRef.current.classList.add(styles.invicible)
            } else {
                nextRef.current.classList.remove(styles.invicible)
            }
        }
    }

    return <div className={styles.catalogueCarouselWrapper} ref={baseRef}>
        <div style={{position: 'relative'}}>
            <ScrollContainer
                style={{marginBottom: 0, paddingBottom: 0}}
                innerRef={scrollRef}
                horizontal
                className={styles.subCategoriesWrapper}
                onScroll={ onScroll }
            >
                {
                    orderedMasterCategories.map((category, index) => <NavLink key={index} to={'/' + category.url}>
                        <div
                            onClick={() => onCategoryClick(category.firstSubCat, category.id)}
                            className={styles.category}
                            ref={itemRef}
                        >
                            <div
                                style={{background: ((currentSection.parentId == category.id && currentSection.id !== 0) || (currentSection.id === 0 && category.name === 'Популярное')) ? '#383838' : '#f2f2f2'}}
                                className={styles.categoryCircle}
                            >
                                <img
                                    src={category.icon}
                                    style={{
                                        width: 48,
                                        pointerEvents: 'none',
                                        filter: (currentSection.parentId == category.id && currentSection.id !== 0) || (currentSection.id === 0 && category.name === 'Популярное') ? categoryColors[(Number(category.id) % categoryColors.length)] : colorToFilter('#383838')
                                    }}
                                />
                            </div>
                            <div
                                title={category.name}
                                className={styles.categoryTitle}
                            >
                                <SText
                                    weight={500}
                                    size={11}
                                    lineHeight={5}
                                    color={Colors.darkGray}
                                >
                                    {category.name}
                                </SText>
                            </div>
                        </div>
                    </NavLink>)
                }
            </ScrollContainer>
            <>
                <div className={classNames([styles.backBtn, styles.invicible])} style={{position: 'absolute', top: '45%', left: '-45px'}} ref={prevRef}>
                    <div className="button" onClick={prev}>
                        <img src={arrow}/>
                    </div>
                </div>
                <div className={styles.nextBtn} style={{position: 'absolute', top: '45%', right: '-45px'}} ref={nextRef}>
                    <div className="button" onClick={next}>
                        <img src={arrow}/>
                    </div>
                </div>
            </>
        </div>
    </div>
}

export default CatalogueCarouselV2
