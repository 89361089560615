import React, {useContext, useEffect} from "react";
import styles from './OrderTradeOff.module.scss'
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import HelperContext from "../../redux/HelperContext";
import {roundPrice} from "../../components/utils/other";
import {Box, Modal} from "@mui/material";
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import Item from "./components/Item";
import closeBtn from "../../assets/images/closeModal.svg";

const OrderTradeOff = ({onClose}) => {
    const {remains, missedProducts, dismissTradeOff, mode, confirmTradeOff} = useContext(OrderProductXChangeContext)
    const navigate = useNavigate()
    const cart = useSelector(state => state.cart.order)
    const {totals} = useContext(HelperContext)
    const cartSum = totals.cart.actual
    const missedSum = missedProducts.reduce((sum, product) => {
        return sum + (Number(product?.sum) || 0)
    }, 0)
    const discount = mode === 'add' ?
        1 :
        1 - .2

    useEffect(() => {
        if (mode === 'none')
            navigate(-1)
    }, [mode])


    function onMainButtonPress() {
        cart.length ? confirmTradeOff() : navigate('/')
    }

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Box style={{display: 'flex'}}>
            <div className={styles.wrapper}>
                <div style={{marginBottom: 10}}><SText size={20} weight={900} lineHeight={25}
                                                       color={Colors.darkGray}>{'Замена товаров'}</SText></div>

                {cart.length
                    ? <div style={{marginBottom: 14}}>
                        <div style={{marginBottom: 9, opacity: remains.asSeconds() <= 35 ? 0 : 1, marginLeft: 6}}>
                            <SText size={14} lineHeight={15} weight={700}
                                   color={Colors.red}>{'К сожалению время сборки подходит к концу. Пожалуйста, подтвердите замену.'}</SText>
                        </div>
                        <div><SText size={14} lineHeight={15} weight={700}
                                    color={Colors.darkGray}>{'Невошедшие товары:'}</SText></div>
                    </div>
                    : <div style={{marginBottom: 14}}><SText size={14} weight={500} lineHeight={15}
                                                             color={Colors.darkGray}>
                        {'Некоторые товары не вошли в заказ, так как, ' +
                            'к сожалению, они закончились на складе в момент оформления заказа:'}
                    </SText></div>
                }

                <div className={styles.products}>
                    {missedProducts.filter(v => !!v).map(prod => <Item
                        product={{...prod, price: prod.sum, weight: prod.amount * prod.coef, thumb: prod.image}}
                        key={prod.id}/>)}
                </div>
                <div className={styles.grayLine}/>

                {cart.length
                    ? <div style={{marginBottom: 25}}>
                        <div style={{marginBottom: 14}}><SText size={16} lineHeight={15} weight={700}
                                                               color={Colors.darkGray}>{'Добавлено на замену:'}</SText>
                        </div>
                        <div className={styles.products}>
                            {cart.map(id => <TempWrapper id={id} key={id} controllable/>)}
                        </div>
                        <div className={styles.grayLine}/>
                        <div style={{marginBottom: 3}} className={styles.finalRow}>
                            <SText size={12} weight={500} lineHeight={15}
                                   color={Colors.darkGray}>{'Стоимость добавленных товаров (скидка 20%)'}</SText>
                            <SText size={12} weight={500} lineHeight={15}
                                   color={Colors.darkGray}>{`${roundPrice(cartSum * discount)} ₽`}</SText>
                        </div>
                        <div className={styles.finalRow}>
                            <SText size={12} weight={500} lineHeight={15}
                                   color={'#ff7834'}>{'Возврат за невошедшие товары'}</SText>
                            <SText size={12} weight={500} lineHeight={15}
                                   color={'#ff7834'}>{`-${roundPrice(missedSum)} ₽`}</SText>
                        </div>
                    </div>
                    : <div style={{marginBottom: 25, maxWidth: 342}}>
                        <div style={{marginBottom: 6}}><SText size={14} weight={700} lineHeight={16}
                                                              color={Colors.darkGray}>{'Мы приносим свом извинения за предоставленные неудобства и предлагаем подобрать товары на замену.'}</SText>
                        </div>
                        <div><SText size={14} weight={700} lineHeight={16}
                                    color={Colors.green}>{'На добавленные товары мы сделаем скидку 20%'}</SText></div>
                    </div>}

                <div className={styles.buttons}>
                    <div onClick={onMainButtonPress} className={styles.mainBtn}>
                        <div className={styles.mainBtnText}>
                            {cart.length ?
                                `Подтвердить замену (${roundPrice((discount * cartSum) - missedSum)} ₽)` :
                                'Перейти к замене'
                            }
                        </div>
                        <div className={styles.timer}>
                            <SText size={14} weight={700} lineHeight={21}
                                   color={remains.asSeconds() <= 35 ? Colors.red : Colors.darkGray}>{`${remains.minutes()}:${String(remains.seconds()).padStart(2, '0')}`}</SText>
                        </div>
                    </div>
                    <div onClick={dismissTradeOff} className={styles.skipBtn}><SText size={14} weight={700}
                                                                                     lineHeight={18}
                                                                                     color={'#fff'}>{'Пропустить'}</SText>
                    </div>
                </div>

            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeBtn}/></div>
        </Box>
    </Modal>
}

export const TempWrapper = (
    {
        id, controllable
    }
) => {
    const product = useSelector(state => state.catalogue.products[+id])
    if (!product) return null

    return <Item product={product} controllable={controllable}/>
}

export default OrderTradeOff