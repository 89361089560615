import React, {useState} from "react";
import styles from './AddressAndPayment.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {Box, Modal} from "@mui/material";
import {setActiveSlot} from "../../../redux/reducers/deliverySlots";
import closeModal from '../../../assets/images/closeModal.svg'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import {prettyDate} from "../../../components/utils/other";
import moment from "moment";
import closeBtn from "../../../assets/images/closeModal.svg";
import {useWindowDimensions} from "../../../components/utils/hooks";
import BottomModal from "../../../mobile/components/BottomModal";
import ScrollContainer from "react-indiana-drag-scroll";

const SelectableButton = ({
                              label = '', onSelect = () => {
    }, isSelected = false, style = {}
                          }) => {
    return <div style={{backgroundColor: isSelected ? Colors.green : ''}} className={styles.slotBtn}
                onClick={onSelect}>
        <SText size={14} weight={500} color={isSelected ? '#fff' : Colors.darkGray}>{label}</SText>
    </div>
}

export const SlotsModal = ({onClose}) => {
    const deliverySlots = useSelector(state => state.deliverySlots)
    const slots = deliverySlots.data, selectedSlot = deliverySlots.selectedSlot
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState(selectedSlot.date)

    if (!slots.timeSlots) {
        onClose()
        return null
    }

    const timeOptions = slots.timeSlots?.[selectedDate]?.times

    function onDateSelect(date) {
        setSelectedDate(date)
    }

    function getIsTimeSelected(time) {
        return selectedSlot.date === selectedDate && time === selectedSlot.time
    }


    function onTimeSelect(time) {
        dispatch(setActiveSlot({date: selectedDate, time: time}))
        onClose()
    }

    const dataSlots = Object.entries(slots.timeSlots)


    return <SlotsModalWrapper onClose={onClose}>
        <div className={styles.slotsModal}>
            <div style={{marginBottom: 21}}><SText size={20} lineHeight={25} weight={900}
                                                   color={Colors.darkGray}>{'Выберите дату'}</SText></div>
            <ScrollContainer horizontal className={styles.slotsGridDate}>
                    {
                        dataSlots.map((item) => {
                            return <SelectableButton
                                label={prettyDate(moment(item[0], 'YYYY-MM-DD'), true)}
                                isSelected={selectedDate === item[0]}
                                onSelect={() => onDateSelect(item[0])}/>
                        })
                    }
            </ScrollContainer>
            <div style={{marginBottom: 21}}><SText size={20} lineHeight={25} weight={900}
                                                   color={Colors.darkGray}>{'Выберите время'}</SText></div>
            <div className={styles.slotsGrid}>
                {
                    timeOptions.map(item => <SelectableButton
                        isSelected={getIsTimeSelected(item)}
                        onSelect={() => onTimeSelect(item)}
                        label={item}
                    />)
                }
            </div>
        </div>
        <div onClick={onClose} className={styles.closeModal}><img src={closeBtn}/></div>
    </SlotsModalWrapper>
}

const SlotsModalWrapper = ({children, onClose}) => {
    const width = useWindowDimensions()

    if (width > 1023)
        return <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <Box style={{display: 'flex'}}>
                {children}
            </Box>
        </Modal>
    else return <BottomModal onClose={onClose}>
        {children}
    </BottomModal>
}

export default SlotsModal