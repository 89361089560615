import React, { Component } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import styles from './Map.module.scss'
import pin from '../../assets/images/map/pin_location.svg'
import plus from '../../assets/images/map/plus.svg'
import minus from '../../assets/images/map/minus.svg'
import findMe from '../../assets/images/map/findMe.svg'
import sendRequest from "../../api";
import courier from '../../assets/images/map/courierOnMap.svg'
import finish from '../../assets/images/map/courierFinish.svg'

async function validateLocation(lat, lon) {
    const payload = {
        lat, lng: lon
    }
    const response = await sendRequest('validateLocation', payload)
    return response
}

export default class MapHelper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            CENTER: this.getCenter(this.props.GPS || ''),
            ZOOM: 12,
            showButtons: props.showButtons,
            GPS: this.props.GPS,
        }

        this.MAP = false;
        this.MAP_REF = false;
    }

    explode = (delimiter, string) => {
        var emptyArray = { 0: '' };

        if (delimiter === ''
            || delimiter === false
            || delimiter === null) {
            return false;
        }

        if (typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object') {
            return emptyArray;
        }

        if (delimiter === true) {
            delimiter = '1';
        }

        return string.toString().split(delimiter.toString());
    }

    getCenter = (GPS) => {
        if (this.props.GPS || this.props.order.coordinates) {
            return this.props?.order?.coordinates || this.props.GPS
        } else if (GPS !== '') {
            return this.explode(',', GPS);
        } else {
            return [55.754638, 37.621633];
        }
    }

    getYaKey() {
        let items = [
            '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
        ];

        return items[Math.floor(Math.random() * items.length)];
    }

    renderData() {
        return (
            <div className={ styles.pin }>
                <img src={ pin } alt="focus" />
            </div>
        );
    }

    onBoundsChange = () => {
        this.props.resetGps();
        let COMPONENT = this
        this.MAP.load(() => {
            if (this.MAP_REF) {
                if (this.MAP_REF.getCenter) {
                    let GPS = this.MAP_REF.getCenter();
                    this.MAP.geocode(GPS).then(async function (res) {
                        let obj = res.geoObjects.get(0);
                        if (obj) {
                            let SHOWADRESS = obj.properties.get('name');
                            let validate = await validateLocation(GPS[0], GPS[1])
                            COMPONENT.props.setDisabled(!validate.validate)
                            COMPONENT.props.setAdressAndGps(SHOWADRESS, GPS)
                            COMPONENT.setState({ GPS })
                            COMPONENT.props.setInputAddres(SHOWADRESS)
                        }
                    });
                }
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.inputAddress !== this.props.inputAddress) {
            this.getSuggestions(this.props.inputAddress)

        }
    }

    setSuggestedAdress = (adress) => {
        let COMPONENT = this;
        this.MAP.load(() => {
            this.MAP.geocode(adress).then(function (res) {
                let obj = res.geoObjects.get(0);
                let GPS = obj.geometry._coordinates;
                let ADRESS = obj.properties.get('metaDataProperty.GeocoderMetaData.text');
                COMPONENT.MAP_REF.setCenter(GPS);
                COMPONENT.getTheMkad(obj, ADRESS, COMPONENT, GPS);
            });
        });
    }

    getSuggestions = (STRING) => {
        if (STRING.length > 3) {
            let COMPONENT = this;

            this.MAP.load(() => {
                this.MAP.suggest(STRING, { results: 10, strictBounds: true })
                    .then(function (items) {
                        let VARIANTS = [];
                        if (items.length) {
                            for (let KEY in items) {
                                VARIANTS.push({ TITLE: items[KEY].displayName });
                            }
                        }
                        COMPONENT.props.setSuggestions(VARIANTS);
                    });
            });
        }
    }

    mapLoaded = (ymaps) => {
        this.MAP = ymaps;
        this.onBoundsChange();
        this.props.mapLoaded();
    }

    zoomIn = () => {
        let ZOOM = this.MAP_REF.getZoom();
        this.MAP_REF.setZoom(ZOOM + 1);
    }

    zoomOut = () => {
        let ZOOM = this.MAP_REF.getZoom();
        this.MAP_REF.setZoom(ZOOM - 1);
    }

    locateMe = () => {
        let COMPONENT = this;
        navigator.geolocation.getCurrentPosition(
            function (position) {
                COMPONENT.MAP_REF.setCenter([position.coords.latitude, position.coords.longitude]);
            },
            function (error) {
                console.log(error);
            },
            {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 1000
            }
        );
    }

    renderMapButtons = () => {
        if (typeof this.state.showButtons !== "undefined") {
            if (this.state.showButtons === "false") {
                return false;
            }
        }
        let BUTTON = '';
        if (navigator.geolocation) {
            BUTTON = (
                <a className={ styles.whiteCircle } style={ { marginTop: 55 } } onClick={ () => this.locateMe() }><img
                    src={ findMe } /></a>
            )
        }
        return (
            <div style={ { bottom: this.props.mobile ? 125 : 35 } } className={ styles.buttons }>
                { (!this.props.order && !this.props.mobile) && <div>
                    <a className={ styles.whiteCircle } onClick={ () => this.zoomIn() }><img src={ plus } alt="" /></a>
                    <a style={ { marginTop: 11 } } className={ styles.whiteCircle } onClick={ () => this.zoomOut() }><img
                        src={ minus } alt="" /></a>
                </div> }
                { BUTTON }
            </div>
        );
    }

    render() {
        return (
            <YMaps
                query={ {
                    apikey: this.getYaKey(),
                    load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl',
                    suggest_apikey: '2cd37ae8-d6b8-40b1-81a1-b5c27198fb18'
                } }
            >
                <Map
                    className={ this.props.order ? styles.courierMap : styles.absolute_map }
                    instanceRef={ map => this.MAP_REF = map }
                    defaultState={ {
                        center: this.state.CENTER,
                        zoom: this.state.ZOOM,
                        controls: []
                    } }
                    onLoad={ ymaps => this.mapLoaded(ymaps) }
                    onBoundsChange={ this.onBoundsChange }
                >
                    { this.props.order && <Placemark geometry={ {
                        type: 'Point',
                        coordinates: [this.props.order.COURRIER.lat, this.props.order.COURRIER.lon]
                    } }
                        options={ { iconLayout: 'default#image', iconImageHref: courier } } /> }
                    { this.props.order &&
                        <Placemark geometry={ { type: 'Point', coordinates: this.props.order.coordinates } }
                            options={ { iconLayout: 'default#image', iconImageHref: finish } } /> }
                    { !this.props.order && this.renderData() }
                    { this.renderMapButtons() }
                </Map>
            </YMaps>
        );
    }
}
