import React, {useContext, useState, useEffect, useMemo} from "react";
import sendRequest from "../../../../api";
import {SearchContext} from "../../../../components/utils/search";
import {ProductContainer, useWindowDimensions} from "../../Catalogue";
import {useDetectKeyboardOpen} from "../../../../components/utils/hooks";
import styles from './styles.module.css'
import notFounded from '../../../../assets/images/notFoundSearch.svg'
import SText from "../../../../components/SText";

const SearchResults = () => {
    const {results, onQueryChange, loading, query} = useContext(SearchContext)
    const [searchHints, setSearchHints] = useState([])
    const {width: windowWidth} = useWindowDimensions()
    const numColumns = Math.floor(windowWidth / 180)
    const width = 100/numColumns+'vw'

    useEffect(()=>{
        sendRequest('getSearchRecommendations').then(response=>{
            setSearchHints(response.sort((a,b)=>a.sort - b.sort))
        })
    }, [])

    const isKeyboardOpen = useDetectKeyboardOpen();

    const isIos = useMemo(() =>
    {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return [
                'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }

        return false;
    }, [])

    const height = useMemo(() => 
    {
        if (isKeyboardOpen) {
            if (isIos) {
                return 'calc(100% - ' + parseInt(isKeyboardOpen) + 'px + 120px)';
            } else {
                return 'calc(100% - ' + parseInt(isKeyboardOpen) + 'px + 60px)';
            }
        }

        return 'auto';
    })

    if (!results.length && query.length < 2) {
        return <div className={styles.list}><div style={{width: '100%', height: height, overflowY: 'auto'}}>
            {searchHints.map((hint, index) => {
                return (
                    <a onClick={() => onQueryChange(hint.name)} key={index} className={styles.hintLink}>
                        <SText size={16} weight={500}>{hint.name}</SText>
                    </a>
                )
            })}
        </div></div>
    }

    if (!results.length && !loading)
        return <div style={{display: 'grid', placeItems: 'center', paddingTop: 32}}>
            <div style={{textAlign: 'center'}}>
                <SText size={18} weight={700} lineHeight={20}>{'К сожалению, этот товар'}</SText> <br/>
                <SText size={18} weight={700} lineHeight={20}>{'не можем найти и привезти вам'}</SText>
                <div style={{marginTop: 25}}><img src={notFounded}/></div>
            </div>
        </div>

    if (loading) {
        return <div className={styles.list} style={{display: 'grid', placeItems: 'center', paddingTop: 32}}>
            {'Поиск...'}
        </div>
    }

    return <div className={styles.list}><div className={styles.resultsGrid}>
        {results.map(id=>{
            return <ProductContainer id={id} width={width} string={query}/>
        })}
    </div></div>
}

export default SearchResults
