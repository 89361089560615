import React, {useState, useMemo, useRef, useEffect} from "react";
import styles from './PrdocutCard.module.scss'
import rating from '../../assets/images/productCard/rating.svg'
import plus from '../../assets/images/productCard/plus.svg'
import minus from '../../assets/images/productCard/minus.svg'
import cn from 'classnames'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import {useDispatch, useSelector} from "react-redux";
import {BASE_DOMAIN} from "../../api";
import {getWeight} from "../../components/utils/other";
import {cartMinus, cartPlus} from "../../redux/reducers/cart";
import {NavLink, useNavigate} from "react-router-dom";
import {useQueryParams} from "../../components/utils/ModalSpectator";
import orangeRub from '../../assets/images/productCard/orangeRub.svg'
import blackRub from '../../assets/images/productCard/blackRub.svg'
import discount from '../../assets/images/badges/discount.svg'
import freeze from '../../assets/images/badges/freeze.svg'
import ecoRu from '../../assets/images/badges/eco-ru.svg'
import ecoEu from '../../assets/images/badges/eco-euro.svg'
import {useWindowDimensions} from "../../components/utils/hooks";

export function getBadgeLabel(product) {
    let result = ''

    function check(fieldName, value) {
        if (product[fieldName] == 1)
            result = value
    }

    check('weekly', 'Товар недели')
    check('top', "Топ продаж")
    check('eco', "Органика")
    check('ro', "Органика")
    check('no_gluten', "Без глютена")
    check('no_sugar', "Без сахара")
    check('halal', "Халяль")
    check('vegan', "Веган")
    check('veganHundred', "Веган 100%")
    check('living_crab', "Живые морепродукты")
    check('living_fish', "Живая рыба")
    check('knife', "Из под ножа")
    check('freeze', "Заморозка")
    check('fresh', "Свежее")
    check('new_year', "Новый год")
    check('no_lactose', "Без лактозы")
    check('furnace', "Из печи")

    return result
}

const Image = ({wholesale, setWholesale, image, title}) => {
    let src = image !== 'https://api.ecomarket.ru' ? image : 'https://api.ecomarket.ru/imgs/no-foto.png'

    return <div className={styles.imageWrapper}>
        <img src={src} alt={title.replace(/['"]+/g, '')}/>
        {/*<WholesaleToggle wholesale={wholesale} setWholesale={setWholesale}/>*/}
        {/*<Rating/>*/}
    </div>
}

const WholesaleToggle = ({wholesale, setWholesale}) => {

    return <div className={styles.toggles}>
        <div onClick={() => setWholesale(false)} style={{borderRadius: '2px 8px 2px 2px'}}
             className={cn(styles.toggle, !wholesale && styles.activeToggle)}>
            1 шт
        </div>
        <div onClick={() => setWholesale(true)} style={{borderRadius: '2px 2px 2px 8px'}}
             className={cn(styles.toggle, wholesale && styles.activeToggle)}>
            <div style={{textAlign: 'center'}}>
                <div>12 шт</div>
                <div className={styles.wholesaleDiscount}>-56%</div>
            </div>
        </div>
    </div>
}

const Rating = () => {

    return <div className={styles.rating}>
        <SText size={12} weight={700} lineHeight={15} color={'#c6c6c6'}>4,3</SText>
        <img src={rating}/>
    </div>
}

const Title = ({title, url}) => {
    const width = useWindowDimensions()
    const navigate = useNavigate()

    return <div onClick={(e) => {
/*        if (width > 1000) {*/
            e.stopPropagation()
            navigate('/' + url)
/*        }*/
        window.scrollTo(0, 0)
    }} className={styles.title}>
        <SText title={title} size={14} lineHeight={15} weight={500} color={Colors.darkGray}>{title}</SText>
    </div>
}

const WeightAndOldPrice = ({wholesale, weight, oldPrice, price, ed_izm, id}) => {
    const amount = useSelector(state => state.cart.items[id])
    return <div className={styles.weightAndOldPrice}>
        <SText style={{marginRight: wholesale ? 6 : 12}} size={12} weight={700} lineHeight={15}
               color={'#bfbfbf'}>{getWeight(weight, ed_izm)}</SText>
        {/*{wholesale && <SText style={{marginRight: 21}} size={12} weight={700} lineHeight={15}
                             color={'#bfbfbf'}>x12</SText>}*/}
        {/* {amount > 0 ? <div>
            <SText style={{marginRight: 9}} size={12} weight={700} lineHeight={15}
                   color={'#bfbfbf'}>{price} ₽</SText>
            {oldPrice ? <SText className={styles.oldPrice} size={12} weight={700} lineHeight={15}
                               color={'#bfbfbf'}>{oldPrice} ₽</SText> : null}
        </div> : null} */}
    </div>
}

const SlimWeightAndOldPrice = ({wholesale, weight, oldPrice, price, ed_izm, id}) => {
    const amount = useSelector(state => state.cart.items[id])
    return <div className={styles.weightAndOldPrice}>
        <SText style={{marginRight: wholesale ? 6 : 12}} size={12} weight={500} lineHeight={15}
               color={'#bfbfbf'}>{getWeight(weight, ed_izm)}</SText>
        {/*{wholesale && <SText style={{marginRight: 21}} size={12} weight={700} lineHeight={15}
                             color={'#bfbfbf'}>x12</SText>}*/}
        {/* <div>
            <SText style={{marginRight: 9}} size={12} weight={700} lineHeight={15}
                   color={'#000'}>{price} ₽</SText>
            {oldPrice ? <SText className={styles.oldPrice} size={12} weight={500} lineHeight={15}
                               color={'#bfbfbf'}>{oldPrice} ₽</SText> : null}
        </div> */}
    </div>
}

const Badge = ({product}) => {

    return <div
        style={{marginBottom: getBadgeLabel(product) === '' ? 22 : 7, display: 'flex', gap: 7, alignItems: 'center', padding: '0 2px'}}>
        <SText size={12} weight={700} lineHeight={15} color={Colors.green} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{getBadgeLabel(product)}</SText>
        <div style={{display: 'flex', gap: 4}}>
            {product.freeze === '1' && <img style={{height: 18}} src={freeze}/>}
            {product.ro === '1' && <img style={{height: 18}} src={ecoRu}/>}
            {product.eco === '1' && <img style={{height: 18}} src={ecoEu}/>}
        </div>
    </div>
}

const Button = ({oldPrice, price, product}) => {
    const amount = useSelector(state => state.cart.items[product.id])
    const dispatch = useDispatch()

    function onPlus(e) {
        e.stopPropagation()
        if (amount + 1 > product.availableones) {
            return
        }
        dispatch(cartPlus(product.id))
    }

    function onMinus(e) {
        e.stopPropagation()
        dispatch(cartMinus(product.id))
    }

    return !amount ?
        <div onClick={onPlus} className={styles.btn}>
            {!oldPrice ?
                <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    <SText size={15} weight={500} lineHeight={19} color={Colors.darkGray}>{price}</SText>
                    <img src={blackRub}/>
                </div> :
                <div style={{textAlign: 'center'}}>
                    <div style={{display: 'flex', gap: 6, alignItems: 'center', justifyContent: 'center'}}>
                        <SText className={styles.btnOldPrice} size={11} weight={500} lineHeight={14}
                               color={'#8c8c8c'}>{oldPrice}
                            ₽</SText>
                        <SText size={11} weight={500} color={'#FF7834'}
                               lineHeight={11}>{'-' + Math.floor((1 - (price / oldPrice)) * 100) + '%'}</SText>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'center'}}>
                        <SText style={{marginTop: -2}} size={15} lineHeight={19} weight={700}
                               color={Colors.orange}>{price}</SText>
                        <img src={orangeRub}/>
                    </div>
                </div>}
        </div> :
        <div className={styles.amountToggle}>
            <div onClick={onMinus} className={styles.minusBtn}><img src={minus}/></div>
            <div>
                <SText size={14} weight={500} lineHeight={17} color={Colors.darkGray}>
                    {`${Number((product.peramount * amount).toFixed(2))}`}<span className={styles.ed_izm}> {`${product.ed_izm === 'л' ? 'шт' : product.ed_izm}`}</span>
                </SText>
            </div>
            <div style={{opacity: amount + 1 > product.availableones ? 0.4 : 1}} onClick={onPlus}
                 className={styles.plusBtn}><img src={plus}/></div>
        </div>
}

const SlimButton = ({oldPrice, price, product}) => {
    const wrapperRef = useRef(null);
    const [viewController, showController] = useState(false)
    const amount = useSelector(state => state.cart.items[product.id])
    const dispatch = useDispatch()

    function onPlus(e) {
        e.stopPropagation()
        if (amount + 1 > product.availableones) {
            return
        }
        dispatch(cartPlus(product.id))
    }

    function onMinus(e) {
        e.stopPropagation()
        dispatch(cartMinus(product.id))
    }

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            showController(false);
        }
    }

    useEffect(() => {
        if (viewController) {
            window.addEventListener("click", handleClickOutside);
        }
        return () => {
            window.removeEventListener("click", handleClickOutside);
        }
    }, [viewController]);
    
    

    const controller = useMemo(() =>
    {
        if (!viewController) {
            return null
        }

        return (
            <div className={styles.absoluteController} ref={wrapperRef}>
                <div className={styles.amountToggle}>
                    <div onClick={onMinus} className={styles.minusBtn}><img src={minus}/></div>
                    <div>
                        <SText size={14} weight={600} lineHeight={17} color={Colors.darkGray}>
                            {`${Number((product.peramount * amount).toFixed(2))}`}<span className={styles.ed_izm}> {`${product.ed_izm === 'л' ? 'шт' : product.ed_izm}`}</span>
                        </SText>
                    </div>
                    <div style={{opacity: amount + 1 > product.availableones ? 0.4 : 1}} onClick={onPlus}
                        className={styles.plusBtn}><img src={plus}/></div>
                </div>
            </div>
        )

    }, [amount, viewController])

    return !amount ?
        <div onClick={(e) => {showController(true); onPlus(e);}} className={styles.btn}>
            <div style={{textAlign: 'center'}}>
                <img src={plus}/>
            </div>
        </div> :
        <div className={styles.btnHolder}>
            <div onClick={(e) => {showController(true); onPlus(e);}} className={styles.btn}>
                <SText size={14} weight={600} lineHeight={17} color={Colors.darkGray}>
                    {`${Number((product.peramount * amount).toFixed(2))}`}
                </SText>
            </div>
            {controller}
        </div>
}

const ProductCard = ({id, string = false, slim = false}) => {
    const product = useSelector(state => state.catalogue.products[Number(id)])
    const [wholesale, setWholesale] = useState(false)
    const {push} = useQueryParams()

    async function onClick() {
        push(['product', id])
        if (string) {
            let a = `https://search.ecomarket.ru/index.php?r=site/click&key=${string}&id=${id}`
            await fetch(a)
        }
    }


    if (!product) return null

    return <div key={id} className={slim ? styles.slimCardWrapper : styles.cardWrapper}>
        {product.weekly === '1' && <img className={styles.discountBadge} src={discount}/>}
        <div className={slim ? styles.pCardSlim : styles.pCard}>
            {
                slim ?
                (
                    <>
                        <div onClick={onClick}>
                            <Image title={product.title} image={BASE_DOMAIN + product.thumb} wholesale={wholesale}
                                setWholesale={setWholesale}/>
                        </div>
                        <div onClick={onClick} className={styles.pGrow}>
                            <Title url={product.url} title={product.title}/>
                            <SlimWeightAndOldPrice price={product.price} oldPrice={product.old_price} weight={product.weight}
                                            ed_izm={product.ed_izm} wholesale={wholesale} id={product.id}/>
                        </div>
                        <SlimButton product={product} price={product.price} oldPrice={product.old_price}/>
                    </>
                )
                :
                (
                    <>
                        <div onClick={onClick}>
                            <Image title={product.title} image={BASE_DOMAIN + product.thumb} wholesale={wholesale}
                                setWholesale={setWholesale}/>
                            <Title url={product.url} title={product.title}/>
                            <WeightAndOldPrice price={product.price} oldPrice={product.old_price} weight={product.weight}
                                            ed_izm={product.ed_izm} wholesale={wholesale} id={product.id}/>
                            <Badge product={product}/>
                        </div>
                        <Button product={product} price={product.price} oldPrice={product.old_price}/>
                    </>
                )
            }
        </div>
    </div>
}

export default ProductCard
