import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {fetchCatalogue} from "./reducers/catalogue";
import usePaymentMethods from "../screens/Cart/utils";
import {cartSet} from "./reducers/cart";
import {getDeliverySlots} from "./reducers/deliverySlots";
import sendRequest from "../api";

const initialState = {
    promoCodeData: {}
}

const initialContextValue = {
    ...initialState,
    paymentMethods: [],
    onMethodChange: ()=>{},
    selectedMethod: '',
    useBonuses: false,
    setUseBonuses: ()=>{},
    appendPromoCode: ()=>{},
    resetPromoCode: ()=>{},
    delivery: {
        options: [[0, 299], [3500, 0]],
        minimalOrder: 2000,
        promoCodeDiscount: 299,
    },
    totals: {
        cart: {
            old: 0,
            actual: 0
        },
        subTotals: {
            old: 0,
            actual: 0,
        },
        finalTotals: {
            old: 0,
            actual: 0,
        },
        deliveryPrice: 0,
        availableBonuses: 0,
    }
}

function or(a, b) {
    if (typeof a !== 'undefined' && a !== false)
        return a
    return b
}

const HelperContext = React.createContext(initialContextValue)

export const useHelperContext = () => {
    const cart = useSelector(state=>state.cart)
    const address = useSelector(state => state.user.address)
    const catalogue = useSelector(state=>state.catalogue)
    const slots = useSelector(state=>state.deliverySlots)
    const user = useSelector(state=>state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState(initialState)
    function appendState(data) {
        setState(prevState => ({
            ...prevState,
            ...data
        }))
    }

    const {paymentMethods, onMethodChange, selectedMethod} = usePaymentMethods()
    const [useBonuses, setUseBonuses] = useState(false)

    const deliveryData = slots.data || {}

    const delivery = {
        options: deliveryData.options || initialContextValue.delivery.options,
        minimalOrder: or(deliveryData.minimalCartSum, 2000),
        promoCodeDiscount: or(deliveryData.promoCodeDiscount, 299),
    }

    /** Сохранение корзины */
    function saveCart () {
        localStorage.setItem('cart', JSON.stringify({...cart}))
    }

    const [read, setRead] = useState(false)

    function readCart () {
        if (Object.keys(cart.items).length === 0) {
            const savedCartJSON = localStorage.getItem('cart')
            if (savedCartJSON) {
                dispatch(cartSet(JSON.parse(savedCartJSON)))
            }
        }
    }

    useEffect(()=>{
        readCart()
        setRead(true)
    }, [])


    useEffect(()=>{
        if (!read) {
            return
        }
        saveCart()
    }, [cart.items])

    useEffect(()=>{
        dispatch(getDeliverySlots({reset: true}))
    }, [address?.lat, user.apikey])


    useEffect(()=>{
        if (slots.data?.region) {
            dispatch(fetchCatalogue())
        }
    }, [slots.data?.region])

    /** Подсчет итогов */
    const totals = useMemo(()=>{
        const cartSum = {
            actual: 0,
            old: 0,
        }

        const percents = {
            blogger: 100,
            regular: 30, // todo back-end
        }


        for (let cartProduct in cart.items) {
            if (catalogue.products[cartProduct]) {
                cartSum.actual += (catalogue.products[cartProduct].price * cart.items[cartProduct])
                cartSum.old += ((catalogue.products[cartProduct].old_price || catalogue.products[cartProduct].price) * cart.items[cartProduct])
            }
        }

        const discount = {
            bonuses: 0,
            promocode: 0,
        }

        let isDiscountInPercents = (state.promoCodeData.type === '1' || state.promoCodeData.type === '6')

        if (state.promoCodeData?.name) {
            discount.promocode = isDiscountInPercents ?
                Math.floor(cartSum.actual * Number(state.promoCodeData.discount) / 100) :
                Number(state.promoCodeData.discount)
        }

        let percentage = (user.status == 4 || user.status == 6 || user.status == 9) ?
            percents.blogger :
            percents.regular

        let availableBonuses = Math.floor(Math.min(user.bonuses || 0, (Math.max(0, cartSum.actual - discount.promocode) / 100 ) * percentage))

        discount.bonuses = ((useBonuses ? 1 : 0) * availableBonuses)

        const subTotals = {
            actual: cartSum.actual - Math.min(cartSum.actual, discount.bonuses + discount.promocode),
            old: cartSum.old
        }

        let deliveryPrice

        for (let [cartPrice, delPrice] of delivery.options) {
            if (cartSum.actual >= Number(cartPrice))
                deliveryPrice = delPrice
        }

        if (deliveryData.steps?.length) {
            const stepOption = deliveryData.steps.find(stepOption=>{
                return stepOption.from <= cartSum.actual && stepOption.to > cartSum.actual
            })
            if (stepOption) {
                if (stepOption.stepCart == 0) {
                    deliveryPrice = 0
                } else {
                    let ratio = Math.floor((cartSum.actual - stepOption.from) / stepOption.stepCart)
                    let deliveryDiscount = ratio * stepOption.stepDelivery
                    deliveryPrice = deliveryPrice - deliveryDiscount
                }
            }
        }

        let deliveryPriceOld = deliveryPrice

        if (state.promoCodeData.free_del == 1 || state.promoCodeData.type == 2) {
            deliveryPrice = Math.max(deliveryPrice - delivery.promoCodeDiscount, 0)
        }

        if (user.status == 4) {
            deliveryPrice = 0
        }

        const finalTotals = {
            actual: subTotals.actual + deliveryPrice,
            old: subTotals.old + deliveryPriceOld,
        }

        return {
            cart: cartSum, // просто корзина
            subTotals, // корзина с доставкой
            finalTotals, // итог с учетом скидок по промо и экобаллам
            deliveryPrice, // актуальная стоимость доставки
            availableBonuses, // доступные экобаллы
            _extra: {
                discount,
                availableBonuses,
                ...state
            }
        }

    }, [cart.items, catalogue.products, useBonuses, state.promoCodeData, address, deliveryData])

    useEffect(()=>{
        //resetPromoCode()
    }, [cart.items])


    async function appendPromoCode (code) {
        const payload = {
            coupon: code,
            apikey: user.apikey,
            sum: totals.cart.actual,
            region: slots.data?.region || 77,
            polygon: false
        }
        const response = await sendRequest('checkCoupon_v2', payload)
        response?.promocode && appendState({promoCodeData: response.promocode})
        return response
    }

    function resetPromoCode () {
        appendState({promoCodeData: {}})
    }

    return {
        ...state,
        paymentMethods,
        onMethodChange,
        selectedMethod,
        useBonuses,
        setUseBonuses,
        appendPromoCode,
        resetPromoCode,
        delivery,
        totals
    }
}

export default HelperContext