import React, {useContext, useEffect, useRef, useState, useMemo} from "react";
import {useSelector} from "react-redux";
import Header from "../Catalogue/components/Header";
import ProductsCarousel from "./components/ProductsCarousel";
import Showcase from "../Catalogue/components/Showcase";
import BottomBar from "../Catalogue/components/BottomBar";
import OrderProductXChangeContext from "../../../components/utils/OrderProductXChange";
import {useNavigate, useSearchParams} from "react-router-dom";
import CatalogueCarousel from "../Catalogue/components/CatalogueCarousel";
import styles from "../../../components/BigList/styles.module.css";
import {DIMENSIONS} from "../../utils";
import search from "../../../assets/images/header/search.svg";
import SText from "../../../components/SText";
import DeliveryBanner from "../../../components/Banner/DeliveryBanner";
import {useQueryParams} from "../../../components/utils/ModalSpectator";
import {Helmet} from "react-helmet";

const ListHeader = () => {
    const {push} = useQueryParams()

    return <div>
        <Header/>
        <Showcase mobile={true}/>
        <div className={styles.inputBtnWrapper} style={{height: DIMENSIONS.SEARCH_BAR_HEIGHT, marginBottom: 25}}>
            <div onClick={() => {
                push(['search', ''])
                window.initWithSearch = true
            }} className={styles.inputBtn}>
                <img src={search}/>
                <SText size={14} weight={400} lineHeight={17} color={'#a6a6a6'}>{'Поиск по каталогу'}</SText>
            </div>
        </div>

    </div>
}

const CatalogueV2 = () => {
    const user = useSelector(state => state.user)
    const categories = useSelector(state => state.catalogue.catV6.masterOrder)
    const products = useSelector(state => state.catalogue.products)
    const {altProducts} = useContext(OrderProductXChangeContext)
    const recommended = useSelector(state => state.user.personalRecommendations)
    const navigate = useNavigate()
    const {push} = useQueryParams()
    const [showDeliveryBanner, closeDeliveryBanner] = useState(!user.apikey)

    function onCategoryPress(id) {
        navigate('Category', {id})
    }

    useEffect(() => {
        if (window?.newUserOrderId) {
            setTimeout(() => {
                push(['auth', window.newUserPhone])
            }, 200)
        } else if (window.orderId) {
            setTimeout(() => {
                push(['orderDetails', window.orderId])
            }, 200)
        }
    }, [window.orderId, window?.newUserOrderId])

    let data = categories

    if (altProducts.length) {
        const additionalRowData = {
            name: 'Рекомендуем к замене',
            items: altProducts.filter(id => !!products[id]),
        }
        data = [additionalRowData, ...data]
    }

    if (recommended.length) {
        const recItems = recommended.filter(id => !!products[id]);
        const totalRecItems = recItems.length;
        const showRecItems = recItems.splice(0,15);
        const additionalRowData = {
            slim: true,
            id: -100,
            name: 'Уже заказывали',
            items: showRecItems,
            total: totalRecItems,
            url: 'myproducts'
        }
        
        data = [...data]
        data.splice(1, 0, additionalRowData)
    }

    return (
        <div style={{paddingBottom: 50, position: 'relative'}}>
            <Helmet>
                <title>Интернет-магазин Экомаркет — доставка продуктов на дом в Москве и Московской области, купить
                    продукты питания с доставкой в онлайн-магазине</title>
                <meta name="description"
                      content="Доставка био, экопродуктов, всего за 90 минут по городу и области. Огромный ассортимент, более 1000 наименований.В наличии Халяльные и Кошерные продукты. Правильное и здоровое питание с Экомаркетом!"
                      data-react-helmet="true"/>
                <meta name="keywords" content="Доставка продуктов" data-react-helmet="true"/>
                <meta name="viewport"
                      content="width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no"/>
            </Helmet>
            {showDeliveryBanner && <DeliveryBanner show={closeDeliveryBanner} />}
            <ListHeader/>
            <div style={{position: 'sticky', top: 0, left: 0, zIndex: 2, background: '#fff', padding: '5px 0 0'}}>
                <CatalogueCarousel currentSection={-1} onSelect={(_, id) => onCategoryPress(id)}/>
            </div>

            {
                data.map((item, index) => {
                    if (item.items && item.name)
                        return <ProductsCarousel key={index} name={item.name} items={item.items} slim={item.slim} item={item} />
                    else
                        return <ProductsCarousel key={index} categoryId={item} slim={item.slim}/>;
                })
            }
            <BottomBar isOnTop={false}/>
        </div>
    );
}

export default CatalogueV2