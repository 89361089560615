import React, {useContext, useState} from "react";
import styles from './Products.module.scss'
import HelperContext from "../../../redux/HelperContext";
import {useSelector} from "react-redux";
import Colors from "../../../components/utils/Colors";
import arrow from '../../../assets/images/cart/goBack.svg'
import check from '../../../assets/images/check-mark.svg'
import SText from "../../../components/SText";
import {roundPrice} from "../../../components/utils/other";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import MapDesktop from "../../Map/MapDesktop";
import {useWindowDimensions} from "../../../components/utils/hooks";

const DeliveryPriceInfo = ({withChevron = true}) => {

    const navigate = useNavigate()
    const {totals, delivery} = useContext(HelperContext)
    const [freeDeliveryFrom, nextStepPrice] = delivery.options.find(([target, price])=>Number(target) > totals.cart.actual) || [0,0]

    const cartSum = totals.cart.actual
    const deliveryPrice = totals.deliveryPrice

    const [searchParams, setSearchParams] = useSearchParams()

    const address = useSelector(state => state.user.address)
    const slotsLoading = useSelector(state=>state.deliverySlots.loading)

    const [map, setMap] = useState(false)
    const width = useWindowDimensions()

    const diff = freeDeliveryFrom - cartSum
    const percentage = cartSum / freeDeliveryFrom

    const DOTS_COUNT = 32

    function renderDot(index) {
        const isActive = index < Math.floor(percentage * 32)
        return <div key={index} style={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            background: isActive ? Colors.green : '#c4c4c4'
        }}/>
    }

    const getFirstLineText = () => {
        const [FREE_DEL_FROM, nextStepPrice] = delivery.options.find(([target, price]) => Number(target) > totals.cart.actual) || [0, 0]

        /*        let auxText = ''*/
        let diff = FREE_DEL_FROM - cartSum
        if (diff < 0) {
            return '';
        }
        /*        if (diff > 0)
                    auxText = nextStepPrice === 0 ?
                        `До бесплатной ${roundPrice(diff)} ₽` :
                        `${roundPrice(diff)} ₽ до скидки на доставку`*/
        return nextStepPrice === 0 && diff / FREE_DEL_FROM <= 0.2 ?
            `До бесплатной ${roundPrice(diff)} ₽` : ''
    }

    if (!address?.name) return <div onClick={() => {
        if (width <= 1023)
            navigate('map')
        else
            setMap(true)
    }}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '10px 19px'
                                    }}
                                    className={styles.deliveryWrapper}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%'}}>
            <SText size={14} weight={700} lineHeight={14}
                   color={'#acacac'}>{'Для расчёта точной стоимости доставки укажите, пожалуйста, ваш адрес'}</SText>
            <img className={styles.arrow} src={arrow}/>
        </div>
        {map && <MapDesktop onClose={(e) => {
            e.stopPropagation()
            setMap(false)
        }}/>}
    </div>

    return <div onClick={() => setSearchParams('deliveryInfo')} className={styles.deliveryWrapper}>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: 9,
            height: (diff > 0 && deliveryPrice !== 0) ? '' : '100%'
        }}>
            {(diff > 0 && deliveryPrice !== 0) ?
                <SText size={14} weight={700}>{`Доставка ${roundPrice(deliveryPrice)} ₽`}</SText> :
                <div style={{alignItems: 'center', gap: 11, display: 'flex'}}>
                    <img style={{filter: colorToFilter(Colors.green)}} src={check}/>
                    <SText size={14} weight={700} color={Colors.green}>{`Бесплатная доставка`}</SText>
                </div>
            }
            <div style={{display: 'flex', alignItems: 'center', gap: 11}}>
                <SText size={12} weight={500} color={'#a6a5a5'}>
                    {getFirstLineText()}
                </SText>
                {withChevron && diff > 0 && <img className={styles.arrow} src={arrow}/>}
            </div>
        </div>
        {(diff > 0 && deliveryPrice !== 0) && <div className={styles.dotsRow}>
            {[...Array(DOTS_COUNT)].map((_, index) => renderDot(index))}
        </div>}
    </div>
}

export default DeliveryPriceInfo