import React, { useContext, useEffect, useRef, useState } from "react";
import styles from './PersonalData.module.scss'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import { BASE_DOMAIN } from "../../api";
import userZaglushka from "../../assets/images/header/lk.svg";
import { useDispatch, useSelector } from "react-redux";
import { useCoolDown, useTextMask } from "../../components/utils/hooks";
import {
    fetchUserData,
    getPaymentCards,
    removePaymentCard,
    updateAddress,
    updateUserData
} from "../../redux/reducers/user";
import address from '../../assets/images/userAccount/personalData/address.svg'
import changeAddress from '../../assets/images/userAccount/personalData/changeAddress.svg'
import MapDesktop from "../Map/MapDesktop";
import addCard from '../../assets/images/userAccount/personalData/addCard.svg'
import HelperContext from "../../redux/HelperContext";
import selectedCard from '../../assets/images/userAccount/personalData/selectedCard.svg'
import unselectedCard from '../../assets/images/userAccount/personalData/unselectedCard.svg'
import AddCard from "../AddCardScreen/AddCard.js";
import trash from '../../assets/images/trash.svg'

const phoneMask = ['+', '7', '-', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const PersonalData = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [phoneValue, setPhoneValue] = useState('')
    const ref = useRef(null)
    const [emailError, setEmailError] = useState(false)
    const [mapVisible, setMapVisible] = useState(false)
    const [addCardModal, setAddCardModal] = useState(false)

    const [data, setData] = useState({
        name: user.name,
        phone: user.phone,
        email: user.email
    })
    const [userInfo, setUserInfo] = useState({
        address: user.address
    })

    const setter = setUserInfo

    useEffect(() => {
        setUserInfo({
            address: user.address
        })
    }, [user])

    function compareUserInfo() {
        const isSame = (
            userInfo.address.number === user.address.number &&
            userInfo.address.entrance === user.address.entrance &&
            userInfo.address.floor === user.address.floor &&
            userInfo.address.comment === user.address.comment
        )
        if (isSame) return

        dispatch(updateAddress(userInfo.address))
    }

    const delayedCompareFn = useCoolDown(compareUserInfo, 1000)

    useEffect(() => {
        delayedCompareFn()
    }, [userInfo])

    function onAddressFieldChange(fieldName, value) {
        setter(prev => ({ ...prev, address: { ...prev.address, [fieldName]: value } }))
    }

    function validateAndSave() {
        const isValid = re.test(data.email)
        setEmailError(!isValid)

        if (data.name === user.name && data.phone === user.phone && data.email === user.email)
            return
        if (isValid) {
            dispatch(updateUserData({
                ...data
            }))
        }
    }

    useEffect(() => {
        setData({
            name: user.name,
            phone: user.phone,
            email: user.email
        })
    }, [user])

    useEffect(() => {
        if (data.email === user.email && data.name === user.name && data.phone === user.phone) return

        cooledDownSave()
    }, [data])

    const cooledDownSave = useCoolDown(validateAndSave, 500)

    useTextMask(ref.current, { mask: phoneMask, guide: false })

    useEffect(() => {
        if (ref.current)
            ref.current.selectionStart = ref.current.selectionEnd = 20
    }, [phoneValue])

    const avatarChosen = async (e) => {
        let file = e.target.files[0];

        if (typeof file !== "undefined") {
            if (file !== null) {
                let SEND_DATA = new FormData();

                SEND_DATA.append('action', 'uploadAvatar');
                SEND_DATA.append('apikey', user.apikey);
                SEND_DATA.append('PICTURE', file);
                fetch(
                    'https://api.ecomarket.ru/api.php',
                    {
                        credentials: 'include',
                        method: "POST",
                        body: SEND_DATA
                    }
                )
                    .then((response) => response.json())
                    .then(() => {
                        dispatch(fetchUserData())
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }

    return <div className={ styles.wrapper }>
        <div style={ { marginBottom: 37 } }><SText size={ 24 } lineHeight={ 30 } weight={ 900 }
            color={ Colors.darkGray }>{ 'Мои данные' }</SText></div>
        <div style={ { marginBottom: 16 } }>{ user.profilePicture ?
            <img style={ { width: 71, height: 71, borderRadius: '50%', objectFit: 'cover' } }
                src={ BASE_DOMAIN + user.profilePicture } /> :
            <img style={ { width: 71, height: 71, borderRadius: '50%' } } src={ userZaglushka } /> }</div>

        <div className={ styles.inputFile }>
            <input id={ 'input_file' } type="file" onChange={ e => avatarChosen(e) } />
            <label htmlFor="input_file">{ 'Редактировать фото' }</label>
        </div>

        <div style={ { marginBottom: 44 } }>
            <div className={ styles.inputRow }>
                <input type="text" placeholder={ 'Ваше имя' } onChange={ (e) => {
                    setData({ ...data, name: e.target.value })
                } } value={ data.name } />
            </div>
            <div className={ styles.inputRow }>
                <input placeholder={ 'Телефон' }
                    onFocusCapture={ () => {
                        if (phoneValue === '') setPhoneValue('+7')
                    } }
                    ref={ ref }
                    disabled={ true }
                    type={ 'cel-phone' }
                    inputMode={ 'tel' }
                    onFocus={ () => (phoneValue === '' && setPhoneValue('+7')) }
                    maxLength={ 18 }
                    onChange={ (e) => {
                        setData({ ...data, phone: e.target.value })
                    } }
                    value={ data.phone } />
                <input onChange={ (e) => {
                    setData({ ...data, email: e.target.value })
                } } type="text" value={ data.email } placeholder={ 'Ваш e-mail' } />
            </div>
        </div>

        <div>
            <div style={ { marginBottom: 30 } }><SText size={ 18 } lineHeight={ 23 } weight={ 700 }
                color={ Colors.darkGray }>{ 'Адрес' }</SText></div>
            <div style={ {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: 340,
                marginBottom: 20,
                cursor: 'pointer'
            } } onClick={ () => setMapVisible(true) }>
                <div style={ { display: 'flex', alignItems: 'center', gap: 12 } }>
                    <img src={ address } />
                    <div className={ styles.addressInput }><SText size={ 16 } weight={ 700 } lineHeight={ 20 }
                        color={ Colors.darkGray }>{ user.address.name || 'Уажите адрес' }</SText>
                    </div>
                </div>
                <img src={ changeAddress } />
            </div>
            <div className={ styles.grayLine } />
            <div className={ styles.addressDetails }>
                <div style={ {
                    maxWidth: 335,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    gap: 14,
                    marginBottom: 14,
                } }>
                    <input value={ userInfo.address.number }
                        onChange={ (value) => onAddressFieldChange('number', value.target.value) }
                        placeholder={ 'Кв/офис' } type="text" />
                    <input value={ userInfo.address.entrance }
                        onChange={ (value) => onAddressFieldChange('entrance', value.target.value) }
                        placeholder={ 'Подъезд' } type="text" />
                    <input value={ userInfo.address.floor }
                        onChange={ (value) => onAddressFieldChange('floor', value.target.value) } placeholder={ 'Этаж' }
                        type="text" />
                </div>
                <div>
                    <input value={ userInfo.address.comment }
                        onChange={ (value) => onAddressFieldChange('comment', value.target.value) }
                        placeholder={ 'Комментарий к заказу' } type="text" />
                </div>
            </div>
            <div style={ { marginBottom: 30 } }><SText size={ 18 } lineHeight={ 23 } weight={ 700 }
                color={ Colors.darkGray }>{ 'Карты оплаты' }</SText></div>
            <div onClick={ () => setAddCardModal(true) } style={ {
                display: 'flex',
                gap: 15,
                cursor: 'pointer',
                alignItems: 'center',
                marginBottom: 30,
                width: 'fit-content'
            } }>
                <img src={ addCard } />
                <SText size={ 14 } weight={ 500 } lineHeight={ 17 } color={ Colors.darkGray }>{ 'Новый способ оплаты' }</SText>
            </div>
            <PaymentCards />
        </div>
        { addCardModal &&
            <AddCard onSuccess={ () => dispatch(getPaymentCards()) } onClose={ () => setAddCardModal(false) } />
        }
        { mapVisible && <MapDesktop onClose={ () => setMapVisible(false) } /> }
    </div>
}

const Card = ({
    card, isSelected = false, onSelect = () => {
    }
}) => {
    const { Icon, ...rest } = card
    const dispatch = useDispatch()
    const onRemoveClick = (e) => {
        e.stopPropagation()
        dispatch(removePaymentCard(rest.cardId))
    }
    return <div onClick={ onSelect } className={ styles.paymentCard }>
        <div style={ { display: 'flex', alignItems: 'center' } }>
            <img style={ { marginRight: 22 } } src={ isSelected ? selectedCard : unselectedCard } />
            <img style={ { marginRight: 8, maxWidth: '20px' } } src={ Icon } />
            <SText size={ 14 } weight={ 500 } lineHeight={ 17 } color={ Colors.darkGray }>{ `**** ${rest.last}` }</SText>
        </div>
        <div onClick={ onRemoveClick } className={ styles.trashIcon }>
            <img src={ trash } />
        </div>
    </div>
}

export const PaymentCards = () => {
    const { paymentMethods, selectedMethod, onMethodChange } = useContext(HelperContext)
    const cards = paymentMethods.filter(method => method.last && method.cardId)
    return <div>
        {
            cards.map(card => <Card key={ card.type } card={ card } isSelected={ selectedMethod === card.type }
                onSelect={ () => onMethodChange(card.type) } />)
        }
    </div>
}

export default PersonalData