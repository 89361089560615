import React from 'react'
import sendRequest, {BASE_DOMAIN} from "./../api";
import styles from "./InnerProduct/InnerProduct.module.scss";
import SText from "../components/SText";
import Colors from "../components/utils/Colors";
import ProductCard from "./ProductCard/ProductCard";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import closeModal from "../assets/images/closeModal.svg";
import {useSelector} from "react-redux";

import {useEffect, useRef, useState, useMemo} from "react";
import {Description, getProduct, Image, NutritionalValue, PriceAndButtons, Title} from "./InnerProduct/InnerProduct";
import {Helmet} from "react-helmet";

import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';
import ScrollContainer from "react-indiana-drag-scroll";

const FullScreenProduct = ({id}) => {

    const [product, setProduct] = useState(false)
    const catalogue = useSelector(state => state.catalogue)
    const scrollRef = useRef(null)
    const scrollCarouselRef = useRef(null)

    const [data, setData] = useState(null)

    const getProductById = async () => {
        const payload = {
            id: id,
            region: 77,
        }
        return await sendRequest('getProductById_v2', payload)
    }

    useEffect(async () => {
        setProduct(false)
        setProduct(await getProductById(id))
    },[id])

    useEffect(async () => {
        if (!product) {
            setProduct(await getProductById(id))
        } else {
            setProduct(product)
        }
    }, [])

    async function loadProduct() {
        const response = await getProduct(id, 77, product)
        setData(response)
        scrollRef.current && scrollRef.current.scrollTo({top: 0, behavior: "smooth"})
    }

    let checkPFC = () => {
        return (data.bel_amount && data.ugl_amount && data.fats_amount)
    }

    useEffect(() => {
        loadProduct()
    }, [id])

    useEffect(() => {
        if (typeof window.yaCounter49140634 !== "undefined" && data) {
            window.yaCounter49140634.reachGoal('productView');
        }
    }, [data])

    const breadcrumbTree = useMemo(() =>
    {
        if (!data) {
            return []
        }

        let treeSteps = [];

        treeSteps.push({
            title: data.title,
            url: data.url
        })

        if (data.group.length) {
            let groupData = catalogue?.catV6?.map[data.group]
            if (groupData) {
                treeSteps.push({
                    title: groupData.name,
                    url: groupData.url
                })
            }

            if (groupData.parent > 0) {
                let parentGroupData = catalogue?.catV6?.map[groupData.parent]
                if (parentGroupData) {
                    treeSteps.push({
                        title: parentGroupData.name,
                        url: parentGroupData.url
                    })
                }
            }
        }

        treeSteps.push({
            title: 'Главная',
            url: ''
        })
        
        return treeSteps.reverse();

    },[data, catalogue])

    const related = useMemo(() =>
    {

        if (!data) {
            return []
        }

        if (data.related && data.related.length) {
            return data.related
        }

        if (data.group > 0 && catalogue.categories.slave.map[data.group]) {
            let ids = []
            for (let id of catalogue.categories.slave.map[data.group].products) {
                if (parseInt(id) !== parseInt(data.id)) {
                    ids.push(id);
                }
            }
            return ids;
        }

        return []
    }, [data, catalogue])

    if (!data || !product)
        return null

    return <div ref={scrollRef} className={styles.modal} style={{overflow: 'visible', maxHeight: 'inherit', height: 'inherit', margin: '0 auto'}}>

        <Helmet>
            <title>{product.title}</title>
        </Helmet>
        
        <Breadcrumbs breadcrumbTree={breadcrumbTree} />

        <div style={{display: 'flex', gap: 40, marginBottom: 45}}>
            <Image fromFullPage={true} images={[{full: data.big}, data.images[1]].filter(Boolean)} product={data}/>
            <div>
                <Title product={data}/>
                <PriceAndButtons product={data}/>
                <Description description={data.short_description}
                             ingredients={data.sostav}
                             bestBefore={data.storage}/>
            </div>
        </div>

        {related.length !== 0 && <div>
            <div style={{marginBottom: 28}}><SText size={15} weight={700} lineHeight={19}
                                                   color={Colors.darkGray}>{'С этим товаром берут'}</SText>
            </div>

            <ScrollContainer
                style={{marginBottom: 0, paddingBottom: 0}}
                innerRef={scrollCarouselRef}
                horizontal
                className={styles.showcaseCarousel}
            >
                {
                    (() => {
                        let renderedProducts = 0
                        let array = []
                        for (let id of related) {
                            if (renderedProducts < 5 && !!catalogue.products[id]) {
                                array.push(<ProductCard key={id} id={id}/>)
                                renderedProducts++
                            }
                        }
                        return array
                    })()
                }
            </ScrollContainer>

        </div>}

        {checkPFC() && <NutritionalValue klet={data.klet_amount} fats={data.fats_amount} ugl={data.ugl_amount}
                                         bel={data.bel_amount}
                                         calories={data.calories_amount}/>}

        <img className={styles.closeModal} src={closeModal}/>
    </div>
}

export default FullScreenProduct