import React, {useEffect, useState} from "react";
import styles from "./SaveNature.module.css"
import cn from "classnames";
import "slick-carousel/slick/slick.css";
import "../../components/Banner/style.css";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import SText from "../../components/SText";
import {useWindowDimensions} from "../../components/utils/hooks";
import angelina from '../../assets/images/saveNature/anna.png'
import anna from '../../assets/images/saveNature/angelina.png'
import olga from '../../assets/images/saveNature/olga.png'
import natasha from '../../assets/images/saveNature/natasha.png'
import svetlana from '../../assets/images/saveNature/svetlana.png'
import arrowFirst from '../../assets/images/saveNature/arrowFirst.svg'
import arrowSecond from '../../assets/images/saveNature/arrowSecond.svg'
import arrowMobile from '../../assets/images/saveNature/arrowMobile.svg'
import bag from '../../assets/images/saveNature/bag.svg'
import batteryLeft from '../../assets/images/saveNature/batteryLeft.svg'
import batteryRight from '../../assets/images/saveNature/batteryRight.svg'
import bottles from '../../assets/images/saveNature/bottles.svg'
import bottlesAndPens from '../../assets/images/saveNature/bottlesAndPens.svg'
import cloud from '../../assets/images/saveNature/cloud.svg'
import ecoLogo from '../../assets/images/saveNature/ecoLogo.svg'
import glass from '../../assets/images/saveNature/glass.svg'
import globe from '../../assets/images/saveNature/globe.svg'
import hedgehog from '../../assets/images/saveNature/hedgehog.svg'
import leaf from '../../assets/images/saveNature/leaf.svg'
import messageAngle from '../../assets/images/saveNature/messageAngle.svg'
import pens from '../../assets/images/saveNature/pens.svg'
import spoonAndFork from '../../assets/images/saveNature/spoonAndFork.svg'
import trash from '../../assets/images/saveNature/trash.svg'
import trashCar from '../../assets/images/saveNature/trashCar.svg'
import trashCarMobile from '../../assets/images/saveNature/trashCarMobile.svg'

const SaveNature = () => {

    useEffect(() => {
        const animItems = document.querySelectorAll('.' + styles.animItems)

        if (animItems.length > 0) {
            window.addEventListener('scroll', animOnScroll)

            function animOnScroll() {
                for (let index = 0; index < animItems.length; index++) {
                    const animItem = animItems[index]
                    const animItemHeight = animItem.offsetHeight
                    const animItemOffset = offset(animItem).top
                    const animStart = 1.5

                    let animItemPoint = window.innerHeight - animItemHeight / animStart;
                    if (animItemHeight > window.innerHeight) {
                        animItemPoint = window.innerHeight - window.innerHeight / animStart
                    }
                    if ((window.pageYOffset > animItemOffset - animItemPoint) && window.pageYOffset < (animItemOffset + animItemHeight)) {
                        animItem.classList.add(styles.active80left)
                    } else {
                        if (!animItem.classList.contains(styles.animNoHide))
                            animItem.classList.remove(styles.active80)
                    }
                }
            }

            function offset(el) {
                const rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
            }

            setTimeout(() => {
                animOnScroll()
            }, 1500)
        }
    }, [])

    const width = useWindowDimensions()
    const [visible, setVisible] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    return <div style={{overflowX: 'hidden'}}>


        {width > 800
            ? <div className={styles.header}>
                <div>
                    <NavLink to={'/'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={900} lineHeight={18}
                                                              color={'#fff'}>ECOMARKET.RU</SText></div>
                    </NavLink>
                    <NavLink to={'/'}>
                        <div><SText size={14} weight={700} lineHeight={18} color={'#fff'}>На главную</SText>
                        </div>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={'/about'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>О
                            нас</SText></div>
                    </NavLink>
                    <NavLink to={'/delivery'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>Доставка</SText></div>
                    </NavLink>
                    <NavLink to={'/pay'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>Оплата</SText></div>
                    </NavLink>
                    <a href="tel:74951599009">
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>8
                            (495) 445-05-05</SText></div>
                    </a>
                </div>
            </div>
            : <div className={styles.burger}>
                <div style={{marginLeft: 15}}>
                    <SText onClick={() => {
                        if (searchParams.get('fromApp')) return
                        navigate('/')
                    }} size={18} weight={900} lineHeight={23}
                           color={'#fff'}>ECOMARKET.RU</SText>

                </div>
                {!searchParams.get('fromApp') && <div style={{marginRight: 30}}>
                    <div className="hamburger-menu">
                        <input id="menu__toggle" type="checkbox" onClick={() => setVisible(prev => !prev)}/>
                        <label className="menu__btn" htmlFor={'menu__toggle'}>
                            <span/>
                        </label>
                        {visible ? <div className={styles.burgerMenu}>
                            <NavLink to={'/'}>
                                <div><SText size={18} weight={900} lineHeight={23} color={'#fff'}>На
                                    главную</SText>
                                </div>
                            </NavLink>
                            <NavLink to={'/about'}>
                                <div><SText size={18} weight={400} lineHeight={22} color={'#fff'}>О нас</SText>
                                </div>
                            </NavLink>
                            <NavLink to={'/delivery'}>
                                <div><SText size={18} weight={400} lineHeight={22}
                                            color={'#fff'}>Доставка</SText>
                                </div>
                            </NavLink>
                            <NavLink to={'/pay'}>
                                <div><SText size={18} weight={400} lineHeight={22} color={'#fff'}>Оплата</SText>
                                </div>
                            </NavLink>
                            <a href="tel:74951599009">
                                <div style={{borderBottom: '2px solid #fff'}}><SText size={18} weight={700}
                                                                                     lineHeight={23} color={'#fff'}>8
                                    (495) 445-05-05</SText></div>
                            </a>
                        </div> : null}
                    </div>
                </div>}
            </div>}


        <div className={styles.wrapper}>


            <div className={styles.globe}>
                <div className={styles.ecoLogo}><img src={ecoLogo}/></div>
                <div style={{
                    marginBottom: width > 650 ? 40 : 35,
                    textAlign: 'center',
                    maxWidth: width > 650 ? 'none' : 290
                }}><SText
                    size={width > 650 ? 70 : 40}
                    weight={900}
                    lineHeight={width > 650 ? 76 : 44}
                    color={'#fff'}>БЕРЕЖËМ
                    ПРИРОДУ</SText></div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 310}}><SText
                    size={width > 650 ? 30 : 20} weight={900}
                    lineHeight={width > 650 ? 36 : 26}
                    color={'#72e958'}>Помогайте
                    экологии,<br/>
                    будьте активными, получайте бонусы!</SText></div>

                <div className={cn(styles.globeImg, styles.animItems, styles.animNoHide)}><img
                    src={globe}/></div>
                <div className={cn(styles.cloud1, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
                <div className={cn(styles.cloud2, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
                <div className={cn(styles.cloud3, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
            </div>


            <div className={styles.trash}>
                <div style={{marginBottom: width > 650 ? 80 : 40, textAlign: 'center'}}>
                    <SText size={width > 650 ? 76 : 40} weight={900} lineHeight={width > 650 ? 76 : 44}
                           color={'#005983'}>А вы знали?</SText>
                </div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 290}}>
                    <SText size={width > 650 ? 30 : 20} weight={700} lineHeight={width > 650 ? 36 : 26}
                           color={'#000'}>Что больше трети всех отходов,
                        которые образуются <br/> в наших домах, можно использовать
                        вторично?</SText>
                </div>

                <div className={cn(styles.cloud4, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
                <div className={cn(styles.trashImg, styles.animItems, styles.animNoHide)}><img
                    src={trash}/></div>
                <div className={cn(styles.bagImg, styles.animItems, styles.animNoHide)}><img
                    src={bag}/></div>
            </div>


            <div className={styles.bottle}>
                <div style={{textAlign: 'center', marginBottom: 45, maxWidth: width > 650 ? 'none' : 270}}><SText
                    size={width > 650 ? 70 : 32}
                    weight={900}
                    lineHeight={width > 650 ? 76 : 38}
                    color={'#fff'} div>НАШИ КУРЬЕРЫ
                    МОГУТ <br/> ЗАБРАТЬ У ВАС</SText></div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 290}}><SText
                    size={width > 650 ? 30 : 22}
                    lineHeight={width > 650 ? 36 : 26} weight={700}
                    color={'#c2e9ff'}
                    div>Пластиковые крышки от бутылок, фломастеры, <br/>
                    ручки, использованную пластиковую тару <br/>
                    от продуктов Экомаркет </SText></div>

                <div className={cn(styles.bottlesImg, styles.animItems, styles.animNoHide)}><img
                    src={bottles}/></div>
                <div className={cn(styles.arrow1Img, styles.animItems, styles.animNoHide)}><img
                    src={arrowFirst}/></div>
                <div className={cn(styles.pensImg, styles.animItems, styles.animNoHide)}><img
                    src={pens}/></div>
                <div className={cn(styles.arrow2Img, styles.animItems, styles.animNoHide)}><img
                    src={arrowSecond}/></div>

                <div className={cn(styles.bottlesAndPensImg, styles.animItems, styles.animNoHide)}><img
                    src={bottlesAndPens}/></div>
                <div className={cn(styles.arrowMobileImg, styles.animItems, styles.animNoHide)}><img
                    src={arrowMobile}/></div>
            </div>


            <div className={styles.trashCar}>
                <div style={{
                    marginBottom: width > 650 ? 45 : 150,
                    textAlign: 'center',
                    maxWidth: width > 650 ? 'none' : 270
                }}><SText
                    size={width > 650 ? 70 : 40} weight={900} lineHeight={width > 650 ? 76 : 44}
                    color={'#f5c15f'}>А
                    ЧТО ЖЕ ДАЛЬШЕ?</SText></div>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 40 : 30,
                    maxWidth: width > 650 ? 'none' : 310
                }}><SText size={width > 650 ? 30 : 18}
                          weight={700}
                          lineHeight={width > 650 ? 36 : 22}
                          color={'#fff'} div>Попадая к нам на склад,
                    через
                    некоторое {width > 650 ? <br/> : null} время их забирает специальный мусоровоз.</SText></div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 310}}><SText
                    size={width > 650 ? 30 : 18}
                    weight={700}
                    lineHeight={width > 650 ? 36 : 22}
                    color={'#fff'}
                    div>Далее
                    собранное вторичное сырье {width > 650 ? <br/> : null} и отправляется на заводы по
                    переработке, {width > 650 ? <br/> : null} чтобы стать новыми
                    пластиковыми вещами {width > 650 ? <br/> : null} и снова быть полезными нам с вами.</SText></div>

                <div className={cn(styles.trashCarImg, styles.animItems, styles.animNoHide)}><img
                    src={trashCar}/></div>
                <div className={cn(styles.spoonImg, styles.animItems, styles.animNoHide)}><img
                    src={spoonAndFork}/></div>
                <div className={cn(styles.glassImg, styles.animItems, styles.animNoHide)}><img
                    src={glass}/></div>

                <div className={cn(styles.trashCarMobileImg, styles.animItems, styles.animNoHide)}><img
                    src={trashCarMobile}/></div>
            </div>


            <div className={styles.battery}>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 55 : 35,
                    maxWidth: width > 650 ? 'none' : 275
                }}><SText
                    size={width > 650 ? 70 : 40} weight={900}
                    lineHeight={width > 650 ? 76 : 44}
                    color={'#26920e'}>А ЕЩЕ МЫ ЗАБИРАЕМ</SText>
                </div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 275}}><SText
                    size={width > 650 ? 30 : 24} weight={700}
                    lineHeight={width > 650 ? 36 : 28} color={'#000'}>Использованные
                    батарейки,<br/>
                    ведь их необходимо безопасно утилизировать</SText></div>

                <div className={cn(styles.batteryLeftImg, styles.animItems, styles.animNoHide)}><img
                    src={batteryLeft}/></div>
                <div className={cn(styles.batteryRightImg, styles.animItems, styles.animNoHide)}><img
                    src={batteryRight}/></div>
            </div>


            <div className={styles.hedgehogs}>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 40 : 32,
                    position: 'relative',
                    maxWidth: width > 650 ? 'none' : 270
                }}>
                    <SText size={width > 650 ? 60 : 36} weight={900} lineHeight={width > 650 ? 76 : 40}
                           color={'#fff'} div>СПАСАЕМ ЁЖИКОВ И ПОЛУЧАЕМ <br/>
                        ПРИЯТНЫЕ БОНУСЫ</SText>
                    <div className={styles.leaf}><img src={leaf}/></div>
                </div>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 80 : 125,
                    maxWidth: width > 650 ? 'none' : 295
                }}><SText
                    size={width > 650 ? 30 : 20} weight={700}
                    lineHeight={width > 650 ? 36 : 28}
                    color={'#000'} div>1 батарейка = 10
                    экобаллов <br/> 1 крышка = 5 экобаллов <br/> 1 ручка/фломастер = 5 экобаллов</SText></div>
                <div style={{textAlign: 'center'}}><SText size={width > 650 ? 36 : 24} weight={900}
                                                          lineHeight={width > 650 ? 36 : 28}
                                                          color={'#c2e9ff'}>Давайте беречь природу {width <= 650 ?
                    <br/> : null}
                    вместе!</SText></div>

                <div className={cn(styles.hedgehogsImg, styles.animItems, styles.animNoHide)}><img
                    src={hedgehog} alt=""/></div>
            </div>


        </div>


        {width > 650
            ? null /*<div className={styles.commentWrapper}>
                <div className={styles.comment}>
                    <Slider className={'saveNatureSlider'} {...settings}>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={olga}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>Ольга</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>Покупаю в этом
                                    магазине
                                    давно. Всегда все вкусное и свежее. Оч быстрая доставка!
                                    Отдельно хочу отметить, что в экомаркете есть возможность сдать пластиковые крышечки
                                    и
                                    батарейки курьеру и получить скидку на будущие покупки! Чем больше сдашь, тем больше
                                    скидка. Спасибо
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={natasha}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>Наталья</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>Обожаю Ecomarket!
                                    Заказываю уже второй год. Всегда быстрая доставка, свежайшие и
                                    вкусные, главное натуральные продукты! Есть продукты, которых в обычном магазине
                                    нет.
                                    Ещё принимают батарейки, фломастеры и крышки, и за это начисляют баллы. Спасибо Вам
                                    большое за Ваш сервис!
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={svetlana}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>Светлана</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>С лета пользуюсь
                                    доставкой ! Всегда свежие и вкусные овощи и фрукты , большой
                                    ассортимент товаров. Очень нравится система бонусов , теперь всей семьей собираем
                                    крышки
                                    и батарейки на переработку!
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={angelina}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>Ангелина</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>Очень нравится
                                    Экомаркет тем, что делая заказ в 12 ночи, уже к 9 утра получаю домой
                                    готовый завтрак со свежими сырниками и блинчиками) Также у магазина супер концепция
                                    с
                                    крышечками и батарейками, которая не оставила меня равнодушной))
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={anna}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>Анна</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>Доброго времени
                                    суток!
                                    Я не так часто заказываю в экомаркете. Обычно это овощи и
                                    морепродукты (рыба, креветки). Но одно мне нравится точно - это сбор крышечек,
                                    батареек,
                                    за которые начисляют баллы на следующий заказ :)
                                </SText>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>*/
            : <div className={styles.commentMobileWrapper}>

                <div className={styles.commentMobile}>
                    <div className={styles.messageImg}><img src={olga}/></div>
                    <div className={styles.message}>
                        <div style={{marginBottom: 15}}><SText size={22} weight={900} lineHeight={20}
                                                               color={'#005983'}>Ольга</SText></div>
                        <div><SText size={14} weight={400} lineHeight={16} color={'#000'}>Покупаю в этом
                            магазине
                            давно. Всегда все вкусное и свежее. Оч быстрая доставка! Отдельно хочу отметить, что в
                            экомаркете есть возможность сдать пластиковые крышечки и батарейки курьеру и получить скидку
                            на
                            будущие покупки! Чем больше сдашь, тем больше скидка. Спасибо
                        </SText></div>
                        <div className={styles.angle}>
                            <img src={messageAngle}/>
                        </div>
                    </div>
                </div>

                <div style={{alignSelf: 'flex-end'}} className={styles.commentMobile}>
                    <div className={styles.message}>
                        <div style={{marginBottom: 15}}><SText size={22} weight={900} lineHeight={20}
                                                               color={'#005983'}>Светлана</SText></div>
                        <div><SText size={14} weight={400} lineHeight={16} color={'#000'}>С лета пользуюсь
                            доставкой
                            ! Всегда свежие и вкусные овощи и фрукты , большой ассортимент товаров. Очень нравится
                            система
                            бонусов , теперь всей семьей собираем крышки и батарейки на переработку!
                        </SText></div>
                        <div className={styles.angleRight}>
                            <img src={messageAngle}/>
                        </div>
                    </div>
                    <div className={styles.messageImg} style={{marginLeft: 20, marginRight: 0}}><img
                        src={svetlana}/></div>

                </div>

                <div className={styles.commentMobile}>
                    <div className={styles.messageImg}><img src={angelina}/></div>
                    <div className={styles.message}>
                        <div style={{marginBottom: 15}}><SText size={22} weight={900} lineHeight={20}
                                                               color={'#005983'}>Ангелина</SText></div>
                        <div><SText size={14} weight={400} lineHeight={16} color={'#000'}>Очень нравится
                            Экомаркет
                            тем, что делая заказ в 12 ночи, уже к 9 утра получаю домой готовый завтрак со свежими
                            сырниками
                            и блинчиками) Также у магазина супер концепция с крышечками и батарейками, которая не
                            оставила
                            меня равнодушной))
                        </SText></div>
                        <div className={styles.angle}>
                            <img src={messageAngle}/>
                        </div>
                    </div>
                </div>

            </div>}


    </div>
}

export default SaveNature