import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrders} from "../../redux/reducers/user";
import Colors from "../../components/utils/Colors";
import sendRequest, {BASE_DOMAIN} from "../../api";
import styles from './OrdersHistory.module.scss'
import SText from "../../components/SText";
import {shortAddress} from "../../components/utils/other";
import {useSearchParams} from "react-router-dom";
import plus from '../../assets/images/userAccount/plus.svg'
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import {useLastOrder} from "../../components/utils/hooks";

export function getStatusLabelProps(status) {
    switch (status) {
        case 'created':
            return {
                label: 'Принят',
                color: Colors.green,
                code: 0,
            }
        case 'handed_over_for_picking':
            return {
                label: 'Собран',
                color: Colors.green,
                code: 1,
            }
        case 'on_the_way':
            return {
                label: 'В пути',
                color: Colors.green,
                code: 2,
            }
        case 'delivered':
            return {
                label: 'Доставлен',
                color: Colors.green,
                code: 3,
            }
        case 'cancelled':
        default:
            return {
                label: 'Отменён',
                color: '#BCBCBC',
                code: -1,
            }
    }
}

export function getPrettyNumber(orderNumber) {
    const parts = orderNumber.split('-')
    const isOldType = parts.length === 3
    return 'Заказ ' + (isOldType ? parts.slice(1).join('-') : parts.join('-'))
}

const ProductThumb = ({id, index}) => {
    const product = useSelector(state => state.catalogue.products?.[id])
    if (!product) return null
    if (index > 8) return null

    const url = BASE_DOMAIN + product.thumb
    return <img src={url} style={{width: 44, height: 44, marginRight: 11, borderRadius: 3, objectFit: 'cover'}}/>
}

export const HistoryItem = ({order, setChatId}) => {
    const statusProps = getStatusLabelProps(order.newStatus)
    const [searchParams, setSearchParams] = useSearchParams()

    return <div onClick={() => setSearchParams('orderDetails=' + order.ID)} className={styles.orderWrapper}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4}}>
            <SText size={14} lineHeight={18} weight={700}
                   color={Colors.darkGray}>{getPrettyNumber(order.NUMBER)}</SText>
            <div style={{background: statusProps.color}} className={styles.budge}><SText size={14} lineHeight={17}
                                                                                         color={'#fff'}
                                                                                         weight={500}>{statusProps.label}</SText>
            </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4}}>
            <SText size={14} lineHeight={17} weight={500}
                   color={Colors.darkGray}>{order.PRETTY_DATE + ', ' + order.TIME}</SText>
            <div style={{background: Colors.red, opacity: Number(statusProps.code !== -1 && order.PAID === '0')}}
                 className={styles.budge}><SText size={14} lineHeight={17}
                                                 color={'#fff'}
                                                 weight={500}>{'Не оплачен'}</SText></div>
        </div>
        <div className={styles.address}>
            {shortAddress(order.ADRESS)}
        </div>
        <div style={{overflow: 'hidden', maxHeight: 44}}>
            {Array.isArray(order?.productIds) ?
                order.productIds.map((product, index) => <ProductThumb key={index} id={product}
                                                                       index={index}/>) : Object.values(order?.productIds).map((product, index) =>
                    <ProductThumb id={product} index={index}/>)
            }
        </div>
    </div>
}

const OrdersHistory = () => {
    const dispatch = useDispatch()
    const orders = useSelector(state => state.user.orders)
    const [loadChunk, setLoadChunk] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const {setAddMode} = useContext(OrderProductXChangeContext)
    const [canAdd, setCanAdd] = useState(false)
    const lastOrder = useLastOrder()
    const user = useSelector(state => state.user)

    async function loadCanAdd() {

        const payload_ = {
            id: lastOrder.ID,
            apikey: user.apikey
        }
        const response_ = await sendRequest('getMissedProductsOrder', payload_)

        const canAdd = response_?.isOpenToAdd || false

        setCanAdd(canAdd)
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        const fetchData = async () => {
            if (lastOrder)
                await loadCanAdd()
        }
        fetchData()
        return () => document.removeEventListener('scroll', scrollHandler)
    }, [])

    const scrollHandler = async (e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) === 0) {
            setLoadChunk(prev => prev + 1)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (loadChunk !== 0)
                await loadNextChunk()
        }
        fetchData()
    }, [loadChunk])

    async function loadNextChunk() {
        dispatch(fetchOrders())
    }

    return <div className={styles.wrapper}>
        <div>
            {
                orders.items.map(order => <HistoryItem key={order.ID} order={order}/>)
            }
        </div>
        <div>
            {canAdd &&
                <div className={styles.addProductCard}>
                    <div style={{marginBottom: 18}}><SText size={16} color={Colors.darkGray} weight={900}
                                                           lineHeight={19}>{'Мы отбираем лучшие продукты для вас...'}</SText>
                    </div>
                    <div onClick={() => {
                        setAddMode()
                        setSearchParams('addProducts')
                    }} style={{display: 'flex', alignItems: 'center', gap: 11, cursor: 'pointer'}}>
                        <div className={styles.circle}><img src={plus}/></div>
                        <SText size={14} lineHeight={16} color={Colors.darkGray} weight={500}>Добавить к заказу</SText>
                    </div>
                </div>
            }
        </div>
    </div>
}

export default OrdersHistory