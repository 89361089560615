import SText from "../../../../components/SText";
import styles from './styles.module.scss'
import {Products} from "../../../../screens/UserAccount/OrdersHistoryModal";
import {roundPrice} from "../../../../components/utils/other";
import {getTotals} from "../../../../screens/UserAccount/utils";
import {useState} from "react";
import greenTick from "../../../../assets/images/check-mark.svg";
import {colorToFilter} from "../../../../components/utils/ColorFilter";
import Colors from "../../../../components/utils/Colors";
import warning from "../../../../assets/images/warning.svg";
import AddCard from "../../../../screens/AddCardScreen/AddCard";

const Details = ({order, onPay, orderId}) => {
    const totals = getTotals(order)
    const [pay, setPay] = useState(false)

    return <div>
        <div className={styles.deliveryDetails}>
            <SText div size={14} weight={400} style={{marginBottom: 2}}>{'Время доставки:'}</SText>
            <SText div size={14} weight={400} style={{marginBottom: 12}}>{order.HUMAN_DATE+' '+order.TIME}</SText>

            <SText div size={14} weight={400} style={{marginBottom: 2}}>{'Адрес доставки:'}</SText>
            <SText div size={14} weight={400} style={{marginBottom: 12}}>{order.ADRESS}</SText>

            <SText div size={14} weight={400} style={{marginBottom: 2}}>{'Оплата:'}</SText>
            <SText div size={14} weight={400} style={{marginBottom: 12}}>{order.PAYMENT_TITLE}</SText>

            {order.comment.trim() ? <SText div size={14} weight={400} color={'#a0a0a0'}
                                           style={{marginBottom: 12}}>{'Комментарий: ' + order.comment}</SText> : null}

        </div>
        <div className={styles.productsWrapper}>
            <Products products={order.PRODUCTS}/>
        </div>
        <Totals order={order}/>
        {pay && <AddCard orderId={orderId} onSuccess={onPay} onClose={() => setPay(false)}/>}

        {(order.PAID == 0 && order.newStatus !== 'cancelled') &&
            <div className={styles.payButtons}>
                <div onClick={() => setPay(true)} className={styles.payBtn}>
                    <SText size={16} weight={500} color={'#fff'}
                           lineHeight={16}>{'Оплатить ' + roundPrice(totals.total) + ' ₽'}</SText>
                </div>
            </div>
        }
    </div>
}

const TotalRow = ({title, value=0, isFinal=false}) => {
    if (!value && !isFinal) return null

    return <div className={styles.totalRow} style={{marginTop: isFinal ? 20 : 6}}>
        <SText size={isFinal ? 15 : 14} weight={isFinal ? 700 : 400}>{title}</SText>
        <SText size={isFinal ? 15 : 14} weight={isFinal ? 700 : 500}>{roundPrice(value)+' р'}</SText>
    </div>
}


const Totals = ({order, onPay}) => {
    const totals = getTotals(order)
    const [paymentModalVisible, setPaymentModalVisible] = useState(false)

    return <div className={styles.deliveryDetails} style={{marginTop: 2}}>
        {/*<PaymentMethodModal visibilityState={[paymentModalVisible, setPaymentModalVisible]}
                            type={'afterPayment'} onPay={onPay}/>*/}
        <TotalRow title={'Чаевые курьеру'} value={totals.tips}/>
        <TotalRow title={'Стоимость товаров'} value={totals.productsSum}/>
        <TotalRow title={'Изменения в весе'} value={totals.weightDiff}/>
        <TotalRow title={'Сумма доставки'} value={totals.delivery}/>
        <TotalRow title={'Вы применили промокод'} value={totals.discount}/>
        <TotalRow title={'Оплачено экобаллами'} value={totals.bonuses}/>
        <TotalRow title={'Итог'} value={totals.total} isFinal/>
        {order.PAID == 1 && <div style={{paddingBottom: 70}} className={styles.paymentStatusLabel}>
            <img src={greenTick} style={{filter: colorToFilter(Colors.green)}}/>
            <SText color={Colors.green} size={14} weight={500}>{'Заказ оплачен'}</SText>
        </div>}
        {(order.PAID == 0 && order.newStatus !== 'cancelled' && order.PAYMENT != 1) &&
            <div className={styles.paymentStatusLabel}>
                <img src={warning}/>
                <SText color={Colors.red} size={14} weight={500}>{'Заказ не оплачен'}</SText>
            </div>}
        {/*{order.PAID == 1 && <View style={styles.paymentStatusLabel}>
            <CheckIcon {...icon(14, Colors.green, {marginRight: 6})}/>
            <SText color={Colors.green} size={14}>{'Заказ оплачен'}</SText>
        </View>}
        {(order.PAID == 0 && order.newStatus !== 'cancelled') && <>
            <View style={styles.paymentStatusLabel}>
                <WarningIcon {...icon(14, Colors.red, {marginRight: 6})}/>
                <SText color={Colors.red} size={14}>{'Заказ не оплачен'}</SText>
            </View>
            <RoundedButton containerStyle={{borderRadius: 8, marginTop: 16}}
                           label={'Оплатить заказ'}
                           onPress={()=>setPaymentModalVisible(true)}
            />
        </>}*/}
    </div>
}

export default Details
