import React from "react"
import styles from './About.module.scss'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";

const About = () => {

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'О нас'}</SText></div>
        </div>
        <div className={styles.container}>
            <div style={{marginBottom: 30}}><SText size={36} lineHeight={36} weight={500}>{'О нас'}</SText></div>
            <div style={{marginBottom: 10}}><SText>{'Немного об Экомаркете.'}</SText></div>
            <div style={{marginBottom: 16}}>
                <SText>{'Не надо больше тратить своё время в поисках натуральных продуктов. Экомаркет доставит их вам всего за 90 минут.'}</SText>
            </div>
            <div style={{marginBottom: 20, marginLeft: 40}}><SText>{'Выберите время доставки'}</SText></div>
            <div style={{marginBottom: 20, marginLeft: 40}}><SText>{'Получите свой заказ с доставкой до порога'}</SText>
            </div>
            <div style={{marginBottom: 20, marginLeft: 40}}>
                <SText>{'Команда Экомаркет сэкономит вам время и силы, которое вы сможете потратить на более полезное дело, чем поиск продуктов.'}</SText>
            </div>
            <div style={{marginBottom: 25}}><SText size={16} weight={500} color={Colors.green}
                                                   lineHeight={16}>{'Ecomarket.ru - продукты, какими они должны быть!'}</SText>
            </div>
            <div><SText size={16} weight={500} lineHeight={20}>{'Кто наши поставщики?'}</SText></div>
            <div style={{marginBottom: 25}}>
                <SText>{'Наши поставщики это фермеры и небольшие производства, которые выращивают и производят натуральные продукты. Многие имеют био сертификаты.\n'}</SText>
            </div>
            <div><SText size={16} weight={400} lineHeight={20}>{'Мы разбираемся в продуктах\n'}</SText></div>
            <div>
                <SText>{'У нас 3 уровня контроля качества и мини лаборатория по проверке содержания вредных нитратов и добавок принимаемой продукции.'}</SText>
            </div>
            <div style={{marginBottom: 25}}>
                <SText>{'Наши комплектовщики аккуратно собирают заказ, вручную только лучшее для вас.'}</SText></div>
            <div><SText size={16} weight={500} lineHeight={20}>{'Сроки годности'}</SText></div>
            <div style={{marginBottom: 25}}>
                <SText>{'В супермаркетах некоторые покупатели часто покупают продукты с истёкшим сроком годности. Мы не допускаем такого и отправляем клиенту только свежее. У нас два раза в день поставка молочной продукции, фруктов, овощей, зелени, мяса и рыбы. Категорически запрещается отправка на последнем дне срока годности.'}</SText>
            </div>
            <div><SText size={16} weight={500} lineHeight={20}>{'Температурный режим'}</SText></div>
            <div>
                <SText>{'Замороженные продукты , которые требуют температурного режима при транспортировке, упаковываются в специальные термосумки с хладоэлементами. Так мы доставляем продукты в необходимом температурном режиме.'}</SText>
            </div>
        </div>
    </div>
}

export default About