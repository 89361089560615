import React, {useContext, useEffect, useRef, useState, useMemo} from "react";
import {useSearchParams} from "react-router-dom";
import styles from './styles.module.css'
import {text} from "../../../../components/SText";
import ArrowLeft from '../../../../assets/images/arrow-left.svg'
import {SearchContext} from "../../../../components/utils/search";

const SearchBar = ({setSearchfocused}) => {
    const {query, onQueryChange} = useContext(SearchContext)
    const [isFocused, setFocused] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const _query = searchParams.get('search')
        if (_query) {
            onQueryChange(_query)
        }
    }, [])

    useEffect(() => {
        return () => {
            setFocused(false)
            setSearchfocused(false)
        };
      }, []);

    useEffect(() => {
        if (isFocused) {
            setSearchfocused(true)
        } else {
            setTimeout(() => {
                setSearchfocused(false)
            }, 200)
            
        }
    }, [isFocused])

    useEffect(() => {
        setSearchParams('search=' + query)
    }, [query])

    useEffect(() => {
        if (window.initWithSearch) {
            setTimeout(() => {
                delete window.initWithSearch
            }, 300)
        }
    }, [])

    function onBackPress() {
        onQueryChange('')
        setSearchfocused(false)
        if (typeof window.initWithSearch !== "undefined") {
            delete window.initWithSearch
        }
    }

    return <div className={styles.searchBarContainer}>
        {(!!query.length || isFocused) && <div className={styles.searchBarBackBtn} onClick={onBackPress}>
            <img src={ArrowLeft}/>
        </div>}
        <input value={query}
               onFocus={() => setFocused(true)}
               onBlur={() => setFocused(false)}
               type={'search'}
               enterKeyHint={'search'}
               style={text(16, 500)}
               placeholder={'Поиск по каталогу'}
               onChange={e => onQueryChange(e.target.value)}
               autoFocus={window.initWithSearch == true ? true : false}
        />
    </div>
}

export default SearchBar

