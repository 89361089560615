import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import CashIcon from '../../../assets/images/userAccount/personalData/cash.svg'
import NewCardIcon from '../../../assets/images/userAccount/personalData/new-card.svg'
import ApplePayIcon from '../../../assets/images/userAccount/personalData/apple-pay.svg'
import VisaIcon from '../../../assets/images/userAccount/personalData/visa.svg'
import MastercardIcon from '../../../assets/images/userAccount/personalData/master-card.svg'
import MaestroIcon from '../../../assets/images/userAccount/personalData/maestro.svg'
import MirIcon from '../../../assets/images/userAccount/personalData/mir.svg'
import SbpIcon from '../../../assets/images/userAccount/personalData/sbp.svg'

const defaultMethods = [
    {
        label: 'Оплата наличными курьеру',
        type: 'cash',
        value: 1,
        Icon: CashIcon,
    },
    {
        label: 'Оплата по СБП',
        type: 'card',
        value: 13,
        Icon: SbpIcon,
    },
    {
        label: 'Оплата новой картой',
        comment: 'Visa, Master Card, Maestro, МИР',
        type: 'new_card',
        value: 3,
        Icon: NewCardIcon
    },
    {
        label: 'Оплата ApplePay',
        type: 'apple_pay',
        Icon: ApplePayIcon,
        disabled: true,
    },
]

function getCardIcon(cardType) {
    switch (cardType) {
        case 'MIR':
            return MirIcon
        case 'Maestro':
            return MaestroIcon
        case 'MasterCard':
            return MastercardIcon
        case 'Visa':
            return VisaIcon
        default:
            return NewCardIcon
    }
}

function mapCardsToMethods(cards) {
    if (!cards || !cards?.length) return []

    return cards.map((card) => {
        return {
            Icon: getCardIcon(card.TYPE),
            label: `Карта **** **** **** ${card.LAST}`,
            last: card.LAST,
            value: 3,
            comment: null,
            type: `card_${card.ID}`,
            cardId: card.ID
        }
    })
}

const usePaymentMethods = () => {
    const paymentCards = useSelector(state => state.user.paymentCards)
    const [selectedMethod, setSelectedMethod] = useState(null)

    const methods = useMemo(() => {
        return [
            ...mapCardsToMethods(paymentCards),
            ...defaultMethods.filter(item => !item.disabled)
        ]
    }, [paymentCards])

    useEffect(() => {
        let value = window.localStorage.getItem('payment_method')
        if (value)
            setSelectedMethod(value)
        else
            setSelectedMethod(methods?.[0]?.type)
    }, [methods])

    function onMethodChange(value) {
        setSelectedMethod(value)
        window.localStorage.setItem('payment_method', value.toString())
    }

    return {
        paymentMethods: methods,
        onMethodChange,
        selectedMethod
    }

}

export default usePaymentMethods