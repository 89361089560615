import React, {useEffect, useState} from 'react'
import styles from './Bonuses.module.scss'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import ecoBalls from '../../assets/images/userAccount/bonuses/bigEco.svg'
import {useDispatch, useSelector} from "react-redux";
import {getBonusesHistory} from "../../redux/reducers/user";
import moment from "moment";
import {declOfNum} from "../../components/utils/other";
import cn from "classnames";
import {Box, Modal} from "@mui/material";
import hedgehog from '../../assets/images/userAccount/bonuses/hedhehog.png'
import closeModal from '../../assets/images/closeModal.svg'
import {useWindowDimensions} from "../../components/utils/hooks";
import share from '../../assets/images/userAccount/bonuses/share.svg'
import copy from '../../assets/images/userAccount/bonuses/copy.svg'
import termo from '../../assets/images/userAccount/bonuses/thermal-bag.svg'
import {
    TelegramIcon,
    TelegramShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton, WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {ClickAwayListener} from "@mui/base";
import {NavLink} from "react-router-dom";

export function getClosestBurnings(history) {
    let amount = 2

    return history.slice(0, amount).map(item => {
        const endDate = moment(item.DATE_END, 'DD.MM.YYYY')
        const isAlreadyBurned = endDate.isBefore(moment())
        if (isAlreadyBurned) return false

        const warnThreshold = 3 // days
        const daysLeft = endDate.diff(moment(), 'days')
        const color = daysLeft >= warnThreshold ? Colors.darkGray : Colors.red

        return {
            text:
                `${item.AMOUNT} ${declOfNum(item.AMOUNT, ['экобалл', 'экобалла', 'экобаллов'])} ` +
                `${declOfNum(item.AMOUNT, ['сгорит', 'сгорят', 'сгорят'])} ` +
                `через ${daysLeft} ${declOfNum(daysLeft, ['день', 'дня', 'дней'])}`,
            color
        }

    }).filter(Boolean)
}

const Bonuses = () => {

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const percents = 30

    const [extraVisible, setExtraVisible] = useState(false)
    const [bonusesHistory, setBonusesHistory] = useState(false)

    useEffect(() => {
        dispatch(getBonusesHistory())
    }, [])

    return <div className={styles.bonusesWrapper}>
        <div style={{marginLeft: -5, marginBottom: 25}}><SText size={24} lineHeight={30} weight={900}
                                                               color={Colors.darkGray}>{'Бонусы'}</SText></div>
        <div style={{display: "flex", alignItems: 'flex-end', gap: 34, marginBottom: 22}}>
            <div className={styles.ecoRectangle}>
                <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                      color={'#fff'}>{'Ваши экобаллы'}</SText></div>
                <div style={{display: 'flex', alignItems: 'center', gap: 9, marginLeft: 3}}>
                    <img src={ecoBalls}/>
                    <div style={{marginTop: -1}}><SText size={30} weight={700} lineHeight={20}
                                                        color={'#fff'}>{user.bonuses}</SText></div>
                </div>
            </div>
            <div className={styles.ecoFire}>
                {getClosestBurnings(user.bonusesHistory).map(item => (
                    <div>
                        <div style={{background: item.color}}/>
                        <SText color={item.color} size={14} weight={500} lineHeight={18}>{item.text}</SText>
                    </div>))}
            </div>
        </div>

        <div style={{marginBottom: 13}}><SText color={Colors.darkGray} size={14} weight={700}
                                               lineHeight={18}>{'1 экобалл = 1 рубль'}</SText>
        </div>
        <div style={{marginBottom: 18}} className={styles.text}>
            Экобаллами можно оплатить 30% заказа. <br/>
            Экобаллы сгорают по истечении 30 дней.
        </div>
        <Code promo={user.personalPromo}/>
        {!user.isPolled && <Anketa/>}
        <Slider/>
        <div style={{marginBottom: 32, cursor: 'pointer'}}>
            <SText onClick={() => setExtraVisible(true)} size={16} lineHeight={20} weight={500}
                   color={Colors.darkGray}>{'Дополнительные экобаллы'}</SText>
            {extraVisible && <ExtraBonuses onClose={() => setExtraVisible(false)}/>}
        </div>
        <div style={{cursor: 'pointer'}}>
            <SText onClick={() => setBonusesHistory(true)} size={16} lineHeight={20} weight={500}
                   color={Colors.darkGray}>{'История начисления экобаллов'}</SText>
            {bonusesHistory &&
                <BonusesHistory onClose={() => setBonusesHistory(false)} bonusesHistory={user.bonusesHistory}/>}
        </div>
    </div>
}

export const getHowMuchBonusesOnSum = (sum) => {
    let CALC_PERCENT = 0;
    if (sum <= 2500) {
        CALC_PERCENT = 0.5;
    } else if (sum <= 3500) {
        CALC_PERCENT = 1;
    } else if (sum <= 4500) {
        CALC_PERCENT = 1.5;
    } else if (sum <= 5500) {
        CALC_PERCENT = 2;
    } else if (sum <= 7000) {
        CALC_PERCENT = 2.5;
    } else if (sum <= 8500) {
        CALC_PERCENT = 3;
    } else if (sum <= 10000) {
        CALC_PERCENT = 4;
    } else if (sum <= 12000) {
        CALC_PERCENT = 5;
    } else if (sum <= 14000) {
        CALC_PERCENT = 6;
    } else if (sum <= 16000) {
        CALC_PERCENT = 7;
    } else if (sum <= 18000) {
        CALC_PERCENT = 8;
    } else if (sum <= 20000) {
        CALC_PERCENT = 9;
    } else {
        CALC_PERCENT = 10;
    }

    return Math.round(sum / 100 * CALC_PERCENT);
}

export const Slider = () => {
    const [value, setValue] = useState(4000)

    return <div className={styles.sliderWrapper}>

        <div style={{marginBottom: 18}}><SText size={20} lineHeight={20} weight={900}
                                               color={Colors.darkGray}>{'Экобаллы за ваши покупки'}</SText></div>
        <div style={{marginBottom: 11}} className={styles.text}>
            Чем больше сумма, тем больше экобаллов! <br/>
            Например,
        </div>
        <div style={{marginBottom: 6}}><SText size={20} lineHeight={25} weight={700}
                                              color={Colors.green}>{getHowMuchBonusesOnSum(value) + ' ' + declOfNum(getHowMuchBonusesOnSum(value), ['экобалл', 'экобалла', 'экобаллов'])}</SText>
        </div>
        <div style={{marginBottom: 6}}><SText size={12} weight={500} lineHeight={15}
                                              color={Colors.darkGray}>{'если сумма вашей покупки'}</SText>
        </div>
        <div style={{marginBottom: 30}}><SText size={16} lineHeight={20} weight={700}
                                               color={Colors.darkGray}>{value + ' руб'}</SText></div>

        <div className={styles.bonuses_range}>
            <div className={cn(styles.range_prices_holder, styles.top)}>
                <span>2000</span>
                <span></span>
                <span>6000</span>
                <span></span>
                <span>10000</span>
                <span></span>
                <span>14000</span>
                <span></span>
                <span>18000</span>
                <span></span>
                <span>22000</span>
                <span></span>
            </div>
            <input type="range" min="2000" max="25000" step="500" value={value}
                   onChange={(e) => setValue(e.target.value)}/>
            <div className={styles.range_dots_holder}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={cn(styles.range_prices_holder, styles.bottom)}>
                <span></span>
                <span>4000</span>
                <span></span>
                <span>8000</span>
                <span></span>
                <span>12000</span>
                <span></span>
                <span>16000</span>
                <span></span>
                <span>20000</span>
                <span></span>
                <span>24000</span>
            </div>
        </div>
    </div>
}

export const Anketa = () => {

    return <div className={styles.anketaWrapper}>
        <SText size={20} weight={900} lineHeight={22} color={Colors.darkGray}>{'1200 экобаллов за 1 минуту!'}</SText>
        <NavLink to={'/closer'}>
            <div className={styles.anketaBtn}>
                <SText size={14} weight={700} lineHeight={18} color={'#fff'}>{'Скорее к анкете'}</SText>
            </div>
        </NavLink>
    </div>
}

export const Code = ({promo}) => {
    const width = useWindowDimensions()
    const [shareClicked, setShareClicked] = useState(false)

    return <div className={styles.codeWrapper}>
        <div style={{marginBottom: 15}}>
            <SText size={20} weight={900} lineHeight={21} color={Colors.darkGray}>Пригласите друга и получите</SText>
            <SText size={20} weight={900} lineHeight={21} div color={Colors.green}> 500 экобаллов <SText size={20}
                                                                                                         weight={900}
                                                                                                         lineHeight={21}
                                                                                                         color={Colors.darkGray}>на
                свой счёт!</SText></SText>

        </div>
        <div style={{marginBottom: 3}}>
            <SText size={14} weight={400} lineHeight={20} color={'#9c9c9c'}>{'Ваш промокод:'}</SText>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
            <input type="text" disabled value={promo.code}/>
            {width < 1024 &&
                <div onClick={() => navigator.clipboard.writeText(promo.code)} className={styles.copyInputBtn}><img
                    src={copy}/></div>}
            {(width < 1024 && shareClicked) && <ClickAwayListener onClickAway={() => setShareClicked(false)}>
                <div className={styles.shareContainer}>
                    <VKShareButton url={'https://ecomarket.ru/'} title={promo.message}>
                        <VKIcon size={44} round={true}/>
                    </VKShareButton>
                    <TelegramShareButton url={'https://ecomarket.ru/'} title={promo.message}>
                        <TelegramIcon size={44} round={true}/>
                    </TelegramShareButton>
                    <ViberShareButton url={'https://ecomarket.ru/'} title={promo.message}>
                        <ViberIcon size={44} round={true}/>
                    </ViberShareButton>
                    <WhatsappShareButton url={'https://ecomarket.ru/'} title={promo.message}>
                        <WhatsappIcon size={44} round={true}/>
                    </WhatsappShareButton></div>
            </ClickAwayListener>}
            {width >= 1024 ?
                <div onClick={() => navigator.clipboard.writeText(promo.message)} className={styles.copyBtn}>
                    <SText size={14} weight={700} lineHeight={18} color={'#fff'}>{'Копировать'}</SText>
                </div> :
                <div onClick={() => setShareClicked(true)} className={styles.shareBtn}><img src={share}/>
                </div>}
        </div>
        <div style={{marginTop: 3, marginBottom: 16}}>
            <SText size={14} weight={400} lineHeight={20} color={'#9c9c9c'}>{'Отправьте его товарищу'}</SText>
        </div>
        <div style={{marginBottom: 5}}>
            <SText size={16} lineHeight={20} weight={700} color={Colors.darkGray}>{'Ваш товарищ получит:'}</SText>
        </div>
        <div style={{marginBottom: 8}}><SText size={12} weight={700} lineHeight={16}
                                              color={Colors.darkGray}>{'(используя ваш промокод)'}</SText></div>
        <div className={styles.text}>
            500 экобаллов на свой первый заказ
        </div>
    </div>
}

const ExtraBonuses = ({onClose}) => {

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Box style={{overflowY: 'visible'}} className={styles.extraBonusesModal}>
            <div onClick={onClose} className={styles.closeModalHed}><img src={closeModal}/></div>
            <div style={{marginBottom: 21}}>
                <SText size={20} weight={700} lineHeight={25}
                       color={Colors.darkGray}>{'Дополнительные экобаллы'}</SText>
            </div>
            <div style={{marginBottom: 20}}><img src={hedgehog}/></div>
            <div><a
                href="whatsapp://send?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D0%BA%D1%83%D1%80%D1%8C%D0%B5%D1%80%20%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D0%BB%20%D1%83%20%D0%BD%D0%B0%D1%81%20%D1%82%D0%B5%D1%80%D0%BC%D0%BE%D0%BF%D0%B0%D0%BA%D0%B5%D1%82.%20%D0%9F%D1%80%D0%BE%D1%88%D1%83%20%D0%B7%D0%B0%D0%B1%D1%80%D0%B0%D1%82%D1%8C%20%D0%B5%D0%B3%D0%BE%20%D0%BF%D1%80%D0%B8%20%D1%81%D0%BB%D0%B5%D0%B4%D1%83%D1%8E%D1%89%D0%B5%D0%B9%20%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B5%20%D0%B8%20%D0%BD%D0%B0%D1%87%D0%B8%D1%81%D0%BB%D0%B8%D1%82%D1%8C%20%D0%BC%D0%BD%D0%B5%20200%20%D1%8D%D0%BA%D0%BE%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2&phone=+74951599009"><img
                src={termo}/></a></div>
        </Box>
    </Modal>
}

const BonusesHistory = ({onClose, bonusesHistory}) => {

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Box style={{display: 'flex', gap: 10}}>
            <div className={styles.extraBonusesModal}>
                <div>
                    <div style={{marginBottom: 21}}>
                        <SText size={20} weight={700} lineHeight={25}
                               color={Colors.darkGray}>{'История начисления экобаллов'}</SText>
                    </div>
                    {
                        bonusesHistory.map(bonus => {
                            return <div style={{marginBottom: 15}}>
                                <div style={{marginBottom: 10}}><SText size={18} weight={700} lineHeight={23}
                                                                       color={Colors.darkGray}>{bonus.AMOUNT + ' ' + declOfNum(bonus.AMOUNT, ['экобалл', 'экобалла', 'экобаллов'])}</SText>
                                </div>
                                <div>
                                    <SText size={14} weight={500} lineHeight={17}
                                           color={Colors.darkGray}>{bonus.HUMAN_DATE + ' ' + bonus.WAY}</SText>
                                </div>
                                <div><SText size={14} weight={500} lineHeight={17}
                                            color={Colors.darkGray}>{'Активны до ' + bonus.DATE_END}</SText></div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeModal}/></div>
        </Box>
    </Modal>
}

export default Bonuses