import React, {useEffect, useState} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {store} from "./redux/store";
import {
    fetchOrders,
    fetchUserData,
    getAddress,
    getAuthToken,
    getPaymentCards,
    refreshOrders
} from "./redux/reducers/user";
import {fetchCatalogue} from "./redux/reducers/catalogue";
import {
    BrowserRouter,
    Routes, Route, useLocation,
} from "react-router-dom";
import Header, {HeaderInfo} from "./components/HeaderComponents/Header";
import InnerProduct from "./screens/InnerProduct/InnerProduct";
import useSearch, {SearchContext} from "./components/utils/search";
import {thresholds} from "./constants";
import {fetchConfig} from "./redux/reducers/remoteConfig";
import {startChatService, stopChatService} from "./redux/reducers/chats";
import Bonuses from "./screens/UserAccount/Bonuses";
import Account from "./screens/UserAccount/Account";
import 'moment/locale/ru'
import moment from "moment";
import PersonalData from "./screens/UserAccount/PersonalData";
import HelperContext, {useHelperContext} from "./redux/HelperContext";
import OrdersHistory from "./screens/UserAccount/OrdersHistory";
import Cart from "./screens/Cart/Cart";
import BonusesM from './mobile/screens/UserAccount/components/Bonuses'
import PersonalDataM from './mobile/screens/UserAccount/components/PersonalData'
import OrdersHistoryM from './mobile/screens/UserAccount/components/OrdersHistory'
import Map from "./mobile/screens/Map";
import ModalSpectator from "./components/utils/ModalSpectator";
import Footer from "./screens/Footer/Footer";
import InitialRouteProcessor from "./components/utils/InitialRouteProcessor";
import OrderProductXChangeContext, {useOrderProductXChange} from "./components/utils/OrderProductXChange";
import Preloader from "./screens/Preloader/Preloader";
import scrollTop from './assets/images/header/arrowDown.svg'
import {colorToFilter} from "./components/utils/ColorFilter";
import SaveNature from "./screens/SaveNature/SaveNature";
import About from "./screens/HeaderAndFooterRefs/About/About";
import Delivery from "./screens/HeaderAndFooterRefs/Delivery/Delivery";
import Pay from "./screens/HeaderAndFooterRefs/Pay/Pay";
import Offer from "./screens/HeaderAndFooterRefs/Offer/Offer";
import Contacts from "./screens/HeaderAndFooterRefs/Contacts/Contacts";
import Anketa from "./screens/Anketa/Anketa";
import Search from "./screens/Search/Search";
import NYCheckList from "./screens/NYCheckList/NYCheckList";
import NotFound from "./screens/NotFound/NotFound";
import UrlProcessor from "./UrlProcessor";
import CompanyUrlProcessor from "./CompanyUrlProcessor"
import CatalogueV2 from "./mobile/screens/CatalogueV2";
import {getDeliverySlots} from "./redux/reducers/deliverySlots";
import NewSimpleCart from "./screens/Cart/NewSimpleCart";
import CatalogueV3 from "./screens/Catalogue/CatalogueV3";

export function isMobile() {
    return window.innerWidth <= thresholds.mobile
}

moment.locale('ru')

const ScrollTop = () => {

    const [isScrolled, setScrolled] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    function onScroll() {
        const offset = window.scrollY
        setScrolled(offset > 300)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    if (!isScrolled) return null

    return <div style={{
        position: 'fixed',
        zIndex: 2,
        bottom: 20,
        right: 20,
        background: '#f2f2f2aa',
        backdropFilter: 'blur(20px)',
        WebkitBackdropFilter: 'blur(20px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        borderRadius: '50%',
        cursor: "pointer"
    }} onClick={scrollToTop}>
        <img style={{transform: 'rotate(180deg)', filter: colorToFilter('#383838')}} src={scrollTop}/>
    </div>
}

function App() {
    const helperContextData = useHelperContext()
    const searchData = useSearch()
    const xChangeData = useOrderProductXChange()
    const dispatch = useDispatch()
    const loadedCatalogue = useSelector(state => state.catalogue.loaded)
    const loadedConfig = useSelector(state => state.config.loaded)
    const user = useSelector(state => state.user)
    const loadedUser = user.loaded
    const loaded = loadedCatalogue && loadedUser && loadedConfig
    const [canHidePreloader, setCanHidePreloader] = useState(false)
    const {pathname} = useLocation()
    const [timer, setTimer] = useState(false)

    useEffect(() => {
        window.tradeOffDismissed = false
        dispatch(getAuthToken())
        dispatch(fetchCatalogue())
        return () => {
            dispatch(stopChatService())
        }
    }, [])

    useEffect(() => {
        dispatch(fetchUserData())
        dispatch(fetchOrders(0))
        dispatch(getAddress())
        dispatch(getPaymentCards())
        dispatch(fetchConfig())
        user.apikey && dispatch(startChatService())
    }, [user.apikey])

    useEffect(() => {
        setTimeout(() => {
            setCanHidePreloader(true)
        }, 1500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchCatalogue())
            dispatch(refreshOrders())
            dispatch(fetchConfig())
            dispatch(getDeliverySlots())
            setTimer(prev => !prev)
        }, 1000000)
    }, [timer])

    if (!canHidePreloader || !loaded) return <Preloader/>

    if (isMobile())
        return <>
            <HelperContext.Provider value={helperContextData}>
                <SearchContext.Provider value={searchData}>
                    <OrderProductXChangeContext.Provider value={xChangeData}>
                        <ModalSpectator/>
                        <Routes>
                            <Route path={'/'} element={<CatalogueV2/>}/>
                            <Route path={'/search'} element={<Search/>}/>
                            <Route path={'/nychecklist'} element={<NYCheckList/>}/>
                            <Route path={'/:url'} element={<UrlProcessor mobile/>}/>
                            <Route path={'/company/:url'} element={<CompanyUrlProcessor mobile/>}/>
                            <Route path={'/closer'} element={<Anketa/>}/>
                            <Route path={'/account/bonuses'} element={<BonusesM/>}/>
                            <Route path={'/account/personal-data'} element={<PersonalDataM/>}/>
                            <Route path={'/account/orders-history'} element={<OrdersHistoryM/>}/>
                            <Route path={'/map'} element={<Map/>}/>
                            <Route path={'/save-nature'} element={<SaveNature/>}/>
                            <Route path={'/about'} element={<About/>}/>
                            <Route path={'/delivery'} element={<Delivery/>}/>
                            <Route path={'/pay'} element={<Pay/>}/>
                            <Route path={'/offer'} element={<Offer/>}/>
                            <Route path={'/contacts'} element={<Contacts/>}/>
                            <Route path={'*'} element={<NotFound/>}/>
                        </Routes>
                        {(pathname !== '/cart' && pathname !== '/save-nature') && <Footer/>}
                    </OrderProductXChangeContext.Provider>
                </SearchContext.Provider>
            </HelperContext.Provider>
        </>


    return (
        <HelperContext.Provider value={helperContextData}>
            <SearchContext.Provider value={searchData}>
                <OrderProductXChangeContext.Provider value={xChangeData}>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        minHeight: '100vh'
                    }}>
                        <div>
                            <InitialRouteProcessor/>
                            <ModalSpectator/>
                            {pathname !== '/save-nature' && pathname !== '/cart' && <HeaderInfo/>}
                            {pathname !== '/save-nature' && pathname !== '/cart' && <Header/>}
                            <Routes>
                                <Route path={'/'} element={<CatalogueV3/>}/>
                                <Route path={'/product/:productId'} element={<InnerProduct/>}/>
                                <Route path={'/cart'} element={<NewSimpleCart/>}/>
                                <Route path={'/save-nature'} element={<SaveNature/>}/>
                                <Route path={'/about'} element={<About/>}/>
                                <Route path={'/delivery'} element={<Delivery/>}/>
                                <Route path={'/pay'} element={<Pay/>}/>
                                <Route path={'/offer'} element={<Offer/>}/>
                                <Route path={'/contacts'} element={<Contacts/>}/>
                                <Route path={'/closer'} element={<Anketa/>}/>
                                <Route path={'/account/'} element={<Account/>}>
                                    <Route path={'bonuses'} element={<Bonuses/>}/>
                                    <Route path={'orders-history'} element={<OrdersHistory/>}/>
                                    <Route path={'personal-data'} element={<PersonalData/>}/>
                                </Route>
                                <Route path={'/search'} element={<Search/>}/>
                                <Route path={'/nychecklist'} element={<NYCheckList/>}/>
                                <Route path={'/:url'} element={<UrlProcessor/>}/>
                                <Route path={'/company/:url'} element={<CompanyUrlProcessor/>}/>
                                <Route path={'*'} element={<NotFound/>}/>
                            </Routes>
                        </div>
                        {(pathname !== '/cart' && pathname !== '/save-nature') && <Footer/>}
                        <ScrollTop/>
                    </div>
                </OrderProductXChangeContext.Provider>
            </SearchContext.Provider>
        </HelperContext.Provider>
    )
}

const WrapperApp = () => {

    return <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
}

export default WrapperApp;
