import React, {useEffect, useState, useMemo} from 'react'
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import NotFound from "./screens/NotFound/NotFound";
import FullScreenProduct from "./screens/FullScreenProduct";
import sendRequest from "./api";
import CategoryScreen from "./mobile/screens/CatalogueV2/screens/CategoryScreen";
import FullScreenProductMobile from "./mobile/screens/FullScreenProductMobile/FullScreenProductMobile";
import CatalogueV3 from "./screens/Catalogue/CatalogueV3";
import CategoryScreenDesktop from "./screens/Catalogue/screens/CategoryScreen";
import PagePreloader from "./components/PagePreloader";

const UrlProcessor = ({mobile = false}) => {
    const {urlMap, productUrlMap} = useSelector(state => state.catalogue)
    const user = useSelector(state => state.user)
    const {url} = useParams()

    const [content, setContent] = useState(<PagePreloader />)

    async function getContent() {

        if (urlMap[url]) {
            return mobile ? <CategoryScreen/> : <CategoryScreenDesktop/>
        }

        if (productUrlMap[url]) {
            const id = productUrlMap[url]
            return mobile ? <FullScreenProductMobile id={id}/> : <FullScreenProduct id={id}/>
        }

        const validProduct = await sendRequest('getProductByUrl_v2', {url, region: 77, token: user.authToken})

        if (validProduct && validProduct !== 'NO DATA') {
            return mobile ? <FullScreenProductMobile id={validProduct.id}/> : <FullScreenProduct id={validProduct.id}/>
        }

        const url_parts = url.split("/");
        if (url_parts.length > 1) {
            const validCompanyPage = await sendRequest('getPageData', {url: url_parts[url_parts.length - 1]})
            console.log(validCompanyPage)
        }

        return <NotFound/>

    }

    useMemo(() => {
        setContent(<PagePreloader />)
    }, [url])
    
    useEffect(() => {
        getContent().then(setContent)
    }, [url, urlMap, productUrlMap])

    return content
}

export default UrlProcessor
