import React, {useState} from "react";
import styles from './Map.module.scss'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import cn from "classnames";
import clear from '../../assets/images/map/clearInput.svg'
import free from '../../assets/images/map/freeDelivery.svg'
import car from '../../assets/images/map/deliveryCar.svg'
import DeliveryPriceInfo from "../Cart/components/DeliveryPriceInfo";
import {useContext} from "react";
import HelperContext from "../../redux/HelperContext";
import {useSelector} from "react-redux";
import {Row} from "../Cart/components/DeliveryInfo";

const Address = ({
                     address,
                     setAddress,
                     suggestions,
                     mapHelper,
                     setSuggestions,
                     disabled,
                     setSuggestionsVisible,
                     suggestionsVisible,
                     onSubmitAddress
                 }) => {

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'calc(100% - 68px)'
    }}>

        <div>

            <div style={{position: 'relative'}}>
                {address && <div onClick={() => {
                    setSuggestions([])
                    setAddress('')
                }} className={styles.clearInput}>
                    <img src={clear}/>
                </div>}
                <div className={styles.input}>
                    <input onBlur={() => setTimeout(() => {
                        setSuggestionsVisible(false)
                    }, 200)} onFocus={() => setSuggestionsVisible(true)}
                           placeholder={'Укажите адрес доставки'} value={address}
                           onChange={e => setAddress(e.target.value)} type="text"/>

                </div>
                {(suggestionsVisible && suggestions.length > 0) && <div className={styles.suggestions}>
                    {
                        suggestions.map(item => <div className={styles.suggestWrapper}>
                            <div onClick={() => {
                                mapHelper.setSuggestedAdress(item.TITLE)
                                setSuggestionsVisible(false)
                                setAddress(item.TITLE)
                            }} className={styles.suggest}><SText size={16}
                                                                 weight={400}
                                                                 lineHeight={16}
                                                                 color={Colors.darkGray}>{item.TITLE}</SText>
                            </div>
                        </div>)
                    }
                </div>}
            </div>

            {/*<div><SText size={16} weight={400} lineHeight={20} color={'#c6c6c6'}>{'Ваши адреса:'}</SText></div>*/}

        </div>

        <button onClick={() => {
            onSubmitAddress()
        }} disabled={disabled} style={{opacity: disabled ? 0.5 : 1}} className={styles.btn}>
            <SText size={16} weight={700} lineHeight={16} color={'#fff'}>{'Выбрать этот адрес'}</SText>
        </button>

    </div>
}


const Terms = () => {
    const {delivery} = useContext(HelperContext)
    const slots = useSelector(state => state.deliverySlots)
    const options = slots.data.options || []

    return <div>

        <DeliveryPriceInfo/>

        <div style={{marginBottom: 34}}>
            <SText size={12} lineHeight={15} weight={400}
                   color={'#9c9c9c'}>{`Минимальная сумма заказа ${delivery.minimalOrder} руб`}</SText>
        </div>

        {options.length !== 0 && <>
            <div style={{marginBottom: 24}}>
                <SText size={16} weight={500} lineHeight={20} color={'#383838'}>{'Стоимость доставки'}</SText>
            </div>

            <div style={{marginBottom: 33}}>

                {options.map(([cart, price]) => (
                    <Row cart={cart} key={cart} price={price} min={delivery.minimalOrder}/>
                ))}

            </div>
        </>}

        <hr className={styles.grayLine}/>

        <div style={{marginBottom: 40}}>
            <div style={{marginBottom: 14}}><SText size={16} weight={500} lineHeight={20}
                                                   color={'#383838'}>{'Информация о доставке'}</SText></div>
            <div className={styles.text}>
                Принимаем и отгружаем заказы 24/7. <br/>
                Доставляем от 60 минут. <br/>
                Минимальная сума заказа {delivery.minimalOrder}. <br/>
            </div>
        </div>
        <div style={{marginBottom: 40}}>
            <div style={{marginBottom: 14}}><SText size={16} weight={500} lineHeight={20}
                                                   color={'#383838'}>{'Сохранность свежести продуктов\n'}</SText></div>
            <div className={styles.text}>
                Бережно пакуем и укладываем и везём, соблюдая температурный режим и принцип соседства товаров.
            </div>
        </div>
        {/*        <div style={{marginBottom: 15}}>
            <div style={{marginBottom: 14}}><SText size={16} weight={500} lineHeight={20}
                                                   color={'#383838'}>{'Получение заказа\n'}</SText></div>
            <div><SText size={14} weight={400} lineHeight={15}
                        color={'#383838'}>{'Если вы не сможете принять заказ в обозначенное время и хотите выбрать другое время доставки, либо у вас есть дополнения к заказу, то пожалуйста, свяжитесь с контакт-центром.Если у вас есть пожелания по работе сервиса, пожалуйста, также обратитесь в контакт-центр.'}</SText>
            </div>
        </div>*/}

    </div>
}

const MapLeftContent = ({address, setAddress, suggestions, mapHelper, setSuggestions, disabled, onSubmitAddress}) => {

    const [page, setPage] = useState(1)
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)


    return <div className={styles.leftContentWrapper}>

        <div className={styles.toggle}>
            <div onClick={() => setPage(1)}
                 className={cn(styles.nonActiveToggle, page === 1 ? styles.activeToggle : false)}><SText
                size={14}
                weight={page === 1 ? 700 : 400}
                lineHeight={20}
                color={page === 1 ? Colors.green : Colors.darkGray}>{'Адрес доставки'}</SText>
            </div>
            <div onClick={() => setPage(2)}
                 className={cn(styles.nonActiveToggle, page === 2 ? styles.activeToggle : false)}><SText
                size={14}
                weight={page === 2 ? 700 : 400}
                lineHeight={20}
                color={page === 2 ? Colors.green : Colors.darkGray}>{'Условия доставки'}</SText>
            </div>
        </div>
        {page === 1 ? <Address onSubmitAddress={onSubmitAddress} suggestionsVisible={suggestionsVisible}
                               setSuggestionsVisible={setSuggestionsVisible}
                               address={address}
                               disabled={disabled} setAddress={setAddress} suggestions={suggestions}
                               setSuggestions={setSuggestions}
                               mapHelper={mapHelper}/> : <Terms/>}

    </div>
}

export default MapLeftContent