import React, { useEffect, useState } from 'react';
import sendRequest from "../../api";
import { Box, Modal } from "@mui/material";
import styles from './AddCard.module.scss'
import { useSelector } from "react-redux";
import closeBtn from "../../assets/images/closeModal.svg";
import { useWindowDimensions } from "../../components/utils/hooks";
import arrow from '../../assets/images/cart/goBack.svg'
import SText from "../../components/SText";
import { useNavigate } from "react-router-dom";

const AddCard = ({ onClose, onSuccess = () => { }, orderId, sbp = false }) => {
    const [iframe, setIframe] = useState(false)
    const USER = useSelector(state => state.user)
    const width = useWindowDimensions()
    const navigate = useNavigate()
    useEffect(() => {
        getAddCardUrl();
        window.addEventListener("message", handleFrameTasks);
        return () => window.removeEventListener("message", handleFrameTasks);
    }, [])

    useEffect(() => {
        return () => onSuccess()
    }, [])

    const getAddCardUrl = async () => {
        let TOKEN = 'UNREGISTERED';
        if (USER) {
            TOKEN = USER.apikey;
        }

        if (orderId) {
            if (sbp) {
                return setIframe(`https://ecomarket.ru/sbp/${orderId}`)
            }
            return setIframe(`https://ecomarket.ru/qr_id/${orderId}`)
        }

        try {
            let SEND_DATA = {
                apikey: TOKEN,
                sbp: sbp,
                security: ''
            };
            let data = await sendRequest('getAddCardUrl', SEND_DATA);
            if (data !== false) {
                setIframe(data.URL)
            }
        } catch (error) {
            console.log(error);
            console.log('BAD PRODUCTS TIMER');
        }
    }

    const handleFrameTasks = async (e) => {
        try {
            let DATA = JSON.parse(e.data);
            if (DATA.Success) {
                await onSuccess()
            }
        } catch (error) {
        }
    }

    return <Modal
        open={ true }
        onClose={ onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }
    >
        <Box style={ { display: 'flex' } }>
            <div className={ styles.addCardModal }>
                { width <= 1023 && <div onClick={ () => navigate(-1) } className={ styles.header }>
                    <img src={ arrow } />
                    <SText size={ 16 } weight={ 700 } lineHeight={ 16 }>{ 'Добавление карты' }</SText>
                </div> }
                <iframe name="iframe" src={ iframe }
                    style={ {
                        display: 'block',
                        height: '100%',
                        width: '100%',
                        marginBottom: '20px',
                        border: 'none'
                    } }>
                    Ваш браузер не поддерживает плавающие фреймы!
                </iframe>
            </div>
            { width > 1023 && <div onClick={ onClose } className={ styles.closeModal }><img src={ closeBtn } /></div> }
        </Box>
    </Modal>
}

export default AddCard
