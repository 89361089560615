import React, { useRef, useMemo } from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../styles.module.scss'
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import greenArrow from '../../../../assets/images/catalogueV2/greenArrow.svg'
import rightArrow from '../../../../assets/images/catalogueV2/rightArrow.svg'
import ProductCard from "../../../../screens/ProductCard/ProductCard";
import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';
import ScrollContainer from "react-indiana-drag-scroll";

const ProductsCarousel = ({ categoryId, items, name, item, slim = false }) => {
    const category = useSelector(state => state.catalogue.catV6.map[categoryId])
    const navigate = useNavigate()
    const products = category?.carouselProducts || items
    const title = category?.name || name
    const product = useSelector(state => state.catalogue.products)
    const scrollRef = useRef(null)

    const customCategory = useMemo(() => {
        if (!category && item.id) {
            return item;
        }

        return false;
    }, [category, item])

    function onMorePress() {
        window.onChildClick = false
        window.scrollTo(0, 0)
        navigate(customCategory ? `/${customCategory.url}` : `/${category.url}`)
    }

    return <div key={ categoryId } className={ styles.catalogueWrapper }>
        <div className={ styles.carouselHeader }>
            <SText weight={ 900 } size={ 22 } style={ { textTransform: 'uppercase', flex: 1 } }>{ title }</SText>
            {
                (customCategory || categoryId !== undefined) && <div onClick={ onMorePress } className={ styles.moreBtn }>
                    <SText weight={ 700 } size={ 14 } lineHeight={ 18 } color={ Colors.green }>
                        { customCategory ? `+${customCategory.total} ещё` : `+${category.total} ещё` }
                    </SText>
                    <img src={ greenArrow } />
                </div>
            }
        </div>
        <div>
            <ScrollContainer
                style={ { marginBottom: 0, paddingBottom: 0 } }
                innerRef={ scrollRef }
                horizontal
                className={ slim ? styles.slimShowcaseCarousel : styles.showcaseCarousel }
            >
                {
                    products.map((item) => {
                        if (!product[Number(item)]) return null
                        return <ProductCard key={ item } id={ item } slim={ slim } />
                    })
                }
                <div className={ styles.arrowHolder }>
                    <div onClick={ onMorePress } className={ styles.rightArrow }>
                        <img src={ rightArrow } />
                    </div>
                </div>
            </ScrollContainer>
        </div>
    </div>
}

export default ProductsCarousel