import React from 'react'
import styles from './Pay.module.scss'
import SText from "../../../components/SText";
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import Colors from "../../../components/utils/Colors";

const Pay = () => {

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'О нас'}</SText></div>
        </div>
        <div className={styles.container}>
            <div style={{marginBottom: 30}}><SText size={36} lineHeight={36} weight={500}>{'Оплата'}</SText></div>
            <div style={{marginBottom: 10, marginLeft: 40}}>
                <SText>{'Банковской картой при оформлении заказа в интернет-магазине. При добавлении карты, чтобы проверить корректность введенных данных, 11 рублей холдируется (замораживается) у вас на счету, а затем автоматически возвращается.'}</SText>
            </div>
            <div style={{marginBottom: 10, marginLeft: 40}}><SText>{'Оплата курьеру наличными.'}</SText></div>
            <div style={{marginBottom: 20, marginLeft: 40}}>
                <SText>{'Оплата курьеру картой с помощью QR- кода на накладной или по ссылке.'}</SText></div>
            <div style={{marginBottom: 20}}><SText size={18} weight={700}
                                                   lineHeight={18}>{'Фактическая стоимость заказа в онлайн-магазине может отличаться от предварительной в случае:'}</SText>
            </div>
            <div style={{marginBottom: 10, marginLeft: 40}}><SText>{'Замены или отсутствия некоторых товаров'}</SText>
            </div>
            <div style={{marginBottom: 10, marginLeft: 40}}>
                <SText>{'Присутствия в заказе весовых товаров. На сайте указана типичная цена товаров, продаваемых на вес, которая может быть меньше или больше фактической'}</SText>
            </div>
            <div style={{marginBottom: 50, marginLeft: 40}}>
                <SText>{'При приеме заказа, пожалуйста, проверьте его комплектность по накладным документам.'}</SText>
            </div>
            <div style={{marginBottom: 15}}><SText size={16} weight={500}>{'ЭКОБАЛЛЫ'}</SText></div>
            <div>
                <SText>{'Для частичной оплаты заказа (до 30%) вы можете использовать экобаллы. 1 экобалл = 1 рубль.'}</SText>
            </div>
        </div>
    </div>
}

export default Pay