import React, {useEffect, useRef, useState, useMemo} from "react";
import styles from '../ProductModal/styles.module.css'
import {useSelector} from "react-redux";
import {getProduct} from "../../../screens/InnerProduct/InnerProduct";
import ProductImages from "../ProductModal/components/ProductImages";
import SText from "../../../components/SText";
import {getWeight} from "../../../components/utils/other";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Colors from "../../../components/utils/Colors";
import {getBadgeLabel} from "../../../screens/ProductCard/ProductCard";
import Description from "../ProductModal/components/Description";
import Recommended from "../ProductModal/components/Recommended";
import AmountController from "../ProductModal/components/AmountController";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";

const FullScreenProductMobile = ({id}) => {
    const product = useSelector(state => state.catalogue.products[Number(id)])
    const catalogue = useSelector(state => state.catalogue)
    const scrollRef = useRef(null)

    const [data, setData] = useState(null)

    async function loadProduct() {
        const response = await getProduct(id, 77, product)
        setData(response)
        scrollRef.current && scrollRef.current.scrollTo({top: 0, behavior: "smooth"})
    }

    useEffect(() => {
        loadProduct()
    }, [id])

    useEffect(() => {
        if (typeof window.yaCounter49140634 !== "undefined" && data) {
            window.yaCounter49140634.reachGoal('productView');
        }
    }, [data])

    const breadcrumbTree = useMemo(() =>
    {
        if (!data) {
            return []
        }

        let treeSteps = [];

        treeSteps.push({
            title: data.title,
            url: data.url
        })

        if (data.group.length) {
            let groupData = catalogue?.catV6?.map[data.group]
            if (groupData) {
                treeSteps.push({
                    title: groupData.name,
                    url: groupData.url
                })
            }

            if (groupData.parent > 0) {
                let parentGroupData = catalogue?.catV6?.map[groupData.parent]
                if (parentGroupData) {
                    treeSteps.push({
                        title: parentGroupData.name,
                        url: parentGroupData.url
                    })
                }
            }
        }

        treeSteps.push({
            title: 'Главная',
            url: ''
        })
        
        return treeSteps.reverse();

    },[data, catalogue])

    const related = useMemo(() =>
    {

        if (!data) {
            return []
        }

        if (data.related && data.related.length) {
            return data.related
        }

        if (data.group > 0 && catalogue.categories.slave.map[data.group]) {
            let ids = []
            for (let id of catalogue.categories.slave.map[data.group].products) {
                if (parseInt(id) !== parseInt(data.id)) {
                    ids.push(id);
                }
            }
            return ids;
        }

        return []
    }, [data, catalogue])

    if (!data)
        return null

    return <div style={{position: 'relative', paddingTop: 46}} ref={scrollRef}>
        <Helmet>
            <title>{data.title}</title>
        </Helmet>
        <div style={{position: 'fixed', zIndex: 3, top: 0, left: 0, background: '#fff', width: '100%', padding: '20px 0 20px 20px'}}>
            <NavLink onClick={()=>window.scrollTo(0,0)} to={'/'}><SText size={26} weight={900}>{'Ecomarket'}</SText></NavLink>
        </div>
        <div style={{marginTop: "50px", padding: "0px 16px"}}>
            <Breadcrumbs breadcrumbTree={breadcrumbTree} />
        </div>
        <ProductImages product={data}/>
        <div className={styles.titleContainer}>
            <h1 className={styles.title} title={data.title}>
                {data.title}
            </h1>
            <div style={{display: 'flex', marginBottom: 12}}>
                <SText weight={700} color={'#bfbfbf'} style={{marginRight: 12}}>
                    {getWeight(data.weight, data.ed_izm)}
                </SText>
                <SText size={14} weight={700} color={Colors.green}>
                    {getBadgeLabel(data)}
                </SText>
            </div>
        </div>
        <div className={'fake-shadow'}/>
        <Description product={data}/>

        <Recommended ids={related}/>
        <div style={{height: 90}}/>
        <div className={styles.amountControllerContainer}>
            <AmountController product={data}/>
        </div>
    </div>
}

export default FullScreenProductMobile