import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import HelperContext from "../../redux/HelperContext";
import OrderProductXChange from "../../components/utils/OrderProductXChange";
import {useDispatch, useSelector} from "react-redux";
import {getTimeBeforeAssembly, useLastOrder} from "../../components/utils/hooks";
import moment from "moment";
import {refreshOrders} from "../../redux/reducers/user";
import Colors from "../../components/utils/Colors";
import styles from './HeaderCartButton.module.scss'
import CheckIcon from '../../assets/images/userAccount/statusBlock/accepted.svg'
import DeliveryIcon from '../../assets/images/userAccount/statusBlock/delivered.svg'
import BoxIcon from '../../assets/images/userAccount/statusBlock/assembled.svg'
import TruckIcon from '../../assets/images/header/cartButton/truck.svg'
import ReplaceIcon from '../../assets/images/header/cartButton/cart-plus.svg'
import CartIcon from '../../assets/images/header/cartButton/cart.svg'
import {useNavigate, useSearchParams} from "react-router-dom";
import {declOfNum, prettyDate, roundPrice} from "../../components/utils/other";
import SText from "../../components/SText";
import {colorToFilter} from "../../components/utils/ColorFilter";
import FloatingCart from "./components/FloatingCart/FloatingCart";

const HeaderCartButton = () => {
    const navigate = useNavigate()
    const {totals, delivery} = useContext(HelperContext)
    const {mode, remains: xChangeRemains} = useContext(OrderProductXChange)
    const dispatch = useDispatch()
    const cartSum = totals.cart.actual
    const cart = useSelector(state => state.cart)
    const lastOrder = useLastOrder()
    const [time, setTime] = useState(moment().format('mm'))
    const [timer, setTimer] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [visibleCart, setVisibleCart] = useState(false)
    const location = window.location.pathname
    useEffect(() => { //
        if (timer) clearInterval(timer)
        if (!lastOrder) return

        setTimer(
            setInterval(() => {
                setTime((moment().format('mm')))
                dispatch(refreshOrders())
            }, 30000)
        )
    }, [lastOrder?.ID])

    function getMiddleButtonProps() {
        const DEL_PRICE = totals.deliveryPrice
        const MINIMAL_ORDER = delivery.minimalOrder
        const FREE_DEL_FROM = delivery.discountedFrom

        const remains = lastOrder?.arrival_time ? Math.ceil(Number(lastOrder?.arrival_time) / 60) + 5 : false

        if (mode === 'replace') {
            const hasCart = cart.order.length !== 0
            return {
                contentColor: '#fff',
                backgroundColor: hasCart ? Colors.green : Colors.red,
                mainText: hasCart ? `Замена товаров ${cart.order.length ? `(+${cart.order.length})` : ''}` : 'Перейти к замене товаров',
                auxText: `Осталось времени ${xChangeRemains.minutes()}:${String(xChangeRemains.seconds()).padStart(2, '0')}`,
                Icon: hasCart ? ReplaceIcon : false,
                iconSize: 32,
                onPress: () => setSearchParams('orderTradeOff')
            }
        }

        if (mode === 'add') {
            return {
                contentColor: '#fff',
                backgroundColor: Colors.green,
                mainText: cart.order.length ? 'Добавлено товаров +' + cart.order.length : 'Добавление товаров',
                auxText: `Осталось времени ${xChangeRemains.minutes()}:${String(xChangeRemains.seconds()).padStart(2, '0')}`,
                Icon: ReplaceIcon,
                iconSize: 32,
                onPress: () => setSearchParams('addProducts')
            }
        }

        if (!lastOrder && cartSum === 0) {
            return {
                contentColor: Colors.darkGray,
                backgroundColor: '#fff',
                mainTextStyle: {
                    size: 12,
                    weight: 500
                },
                auxTextStyle: {
                    size: 12,
                    weight: 500
                },
                Icon: TruckIcon,
                mainText: <div style={{textAlign: 'center'}}>{`Мин. сумма заказа ${MINIMAL_ORDER} ₽`}</div>,
                auxText: <span style={{display: 'flex', alignItems: 'center', gap: 3}}>{`Доставим за ${DEL_PRICE} ₽`}</span>,
                onPress: () => setSearchParams('deliveryInfo')
            }
        }

        if (cartSum > 0) {
            let auxText = ''
            if (cartSum >= MINIMAL_ORDER) {
                if (totals.deliveryPrice === 0) {
                    auxText = 'Доставим бесплатно'
                } else {
                    let diff = FREE_DEL_FROM - cartSum
                    if (diff <= 500 && diff > 0)
                        auxText = delivery.discountedPrice === 0 ?
                            `${roundPrice(diff)} ₽ до бесплатной доставки` :
                            `${roundPrice(diff)} ₽ до скидки на доставку`
                    else
                        auxText =  <div style={{textAlign: 'center'}}>{`Доставим за ${totals.deliveryPrice} ₽`}</div>
                }
            } else {
                auxText = <div style={{textAlign: 'center'}}>{`Мин. сумма заказа ${MINIMAL_ORDER} ₽`}</div>
            }

            return {
                contentColor: cartSum >= MINIMAL_ORDER ? '#fff' : Colors.darkGray,
                backgroundColor: cartSum >= MINIMAL_ORDER ? Colors.green : Colors.yellow,
                Icon: CartIcon,
                mainText: <div style={{textAlign: 'center'}}>{roundPrice(cartSum) + ' ₽'}</div>,
                auxText: auxText,
                onPress: () => navigate('cart')
            }
        }

        if (lastOrder) {
            const minutes = getTimeBeforeAssembly(lastOrder)

            let props = {
                contentColor: '#fff',
                backgroundColor: Colors.green,
                Icon: lastOrder.newStatus === 'on_the_way' ? DeliveryIcon : BoxIcon,
                mainText: lastOrder.newStatus === 'on_the_way' ? 'Курьер уже в пути!' : 'Собираем ваш заказ',
                auxText: remains ?
                    `Осталось примерно ${remains} ${declOfNum(remains, ['минута', 'минуты', 'минут'])}` :
                    (
                        minutes ?
                            `Осталось ~${minutes} ${declOfNum(minutes, ['минута', 'минуты', 'минут'])}` :
                            lastOrder.newStatus === 'on_the_way' ? 'Скоро будет у вас' : 'Скоро передадим в доставку'
                    ),
                onPress: () => setSearchParams('orderDetails=' + lastOrder.ID)
            }

            if (minutes > 120) {
                props = {
                    ...props,
                    Icon: CheckIcon,
                    mainText: 'Ваш заказ принят',
                    auxText: `Доставим ${prettyDate(moment(lastOrder.DATE, 'YYYY-MM-DD')).toLowerCase()} ${lastOrder.TIME}`,
                    iconSize: 32
                }
            }

            return props
        }
    }

    const middleButtonProps = useMemo(() => {
        return getMiddleButtonProps()
    }, [cartSum, lastOrder, delivery, time])

    const Icon = middleButtonProps.Icon

    if (!Boolean(middleButtonProps)) return <div/>

    return <div onMouseOver={()=>setVisibleCart(true)} onMouseLeave={()=>setVisibleCart(false)} className={styles.floatingContentWrapper}>
        <div className={styles.wrapper} style={{background: middleButtonProps.backgroundColor}}
             onClick={middleButtonProps.onPress}>
            <div><img src={Icon} style={{filter: colorToFilter(middleButtonProps.contentColor)}}/></div>
            <div>
                <div><SText color={middleButtonProps.contentColor} size={middleButtonProps.mainTextStyle?.size || 16}
                            weight={middleButtonProps.mainTextStyle?.weight || 500}
                            lineHeight={21}>{middleButtonProps.mainText}</SText></div>
                <div><SText color={middleButtonProps.contentColor} size={middleButtonProps.auxText?.size || 12}
                            weight={middleButtonProps.mainTextStyle?.weight || 500}
                            lineHeight={21}>{middleButtonProps.auxText}</SText></div>
            </div>
        </div>

        {(visibleCart && cartSum > 0 && location !== '/cart' && mode === 'none') && <FloatingCart onMouseLeave={()=>setVisibleCart(false)}/>}
    </div>
}

export default HeaderCartButton