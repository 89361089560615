import React, {useEffect, useState} from "react";
import styles from './Chat.module.scss'
import {useSelector} from "react-redux";
import {Box, Modal} from "@mui/material";
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import moment from "moment";
import {colorToFilter} from "../../components/utils/ColorFilter";
import deleteImg from "../../assets/images/closeModal.svg";
import plus from "../../assets/images/chat/addPhoto.svg";
import send from "../../assets/images/chat/sendMessage.svg";
import sendRequest, {BASE_URL} from "../../api";
import user from "../../assets/images/userAccount/courierZaglushka.png";
import emptyChat from '../../assets/images/chat/emptyChat.png'
import close from "../../assets/images/close.svg";
import TextareaAutosize from 'react-textarea-autosize';

const CourierChat = ({onClose, orderId = ''}) => {
    const user = useSelector(state => state.user)
    const [text, onInputTextChanged] = useState('')
    const [isSending, setSending] = useState(false)
    const [attachedImage, setAttachedImage] = useState(null)
    const [messages, setMessages] = useState(null)
    const [orderData, setOrderData] = useState(null)

    const getOrderData = async (id) => {
        const payload = {
            apikey: user.apikey,
            id: id
        }
        return await sendRequest('getOrderData', payload)
    }

    useEffect(async () => {
        const data = await getOrderData(orderId)
        setOrderData(data.order)
    }, [])

    async function loadMessages() {
        const payload = {
            apikey: user.apikey,
            orderId,
            limit: 200,
            offset: 0
        }
        const response = await sendRequest('getUserOrderMessages', payload)
        setMessages(response.messages)
    }

    async function onSend() {
        if (isSending || (text.trim() === '' && !attachedImage)) return

        setSending(true)

        const SendData = new FormData()
        SendData.append('action', 'sendUserOrderMessages')
        SendData.append('apikey', user.apikey)
        SendData.append('message', text)
        SendData.append('orderId', orderId)
        SendData.append('photo', !!attachedImage ? attachedImage : false)

        const request = {
            credentials: 'include',
            method: "POST",
            body: SendData,
            headers: {
                'Accept': 'application/json',
                ...(SendData?.getHeaders ? SendData.getHeaders() : {})
            }
        }

        fetch(BASE_URL, request).then(response => response.json()).then((response) => {
            if (response?.status?.result === 'SUCCESS') {
                setSending(false)
                loadMessages()
            }
        })

        onInputTextChanged('')
        setAttachedImage(null)
    }

    useEffect(() => {
        loadMessages()
        const timer = setInterval(() => {
            loadMessages()
        }, 5000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    if (!messages || !orderData) return null

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box className={styles.modal}>

            <div className={styles.header}>
                <SText size={16} lineHeight={19} weight={700} color={Colors.darkGray}>{'Чат с курьером'}</SText>
                <div onClick={onClose} className={styles.closeBtn}><img src={close}/></div>
            </div>

            {messages.length === 0 ? <div className={styles.emptyChat}><img src={emptyChat}/></div> :
                <div className={styles.messagesContainer}>
                    {
                        messages.map((item, index, array) => {

                            let dateVisible = false
                            if (array.length === index + 1)
                                dateVisible = true
                            else if (index !== 0) {
                                if (!moment(item.datetime.split(' ')[0]).isSame(array[index + 1].datetime.split(' ')[0], 'day')) {
                                    dateVisible = true
                                }
                            }

                            return <div>
                                {dateVisible &&
                                    <div className={styles.messageDate}><SText size={14} weight={500} lineHeight={14}
                                                                               color={'#adadad'}>{moment(item.datetime).format('LL')}</SText>
                                    </div>}
                                <Message photo={orderData.COURRIER.image} name={orderData.COURRIER.name}
                                         isCourier={item.direction === '0'} item={item}/>
                            </div>
                        })
                    }
                </div>}

            <div className={styles.footer}>
                {attachedImage && <div className={styles.loadedImg}>
                    <SText>{attachedImage.name}</SText>
                    <img style={{filter: colorToFilter(Colors.darkGray)}} onClick={() => setAttachedImage(null)}
                         src={deleteImg}/>
                </div>}
                <div className={styles.footerContent}>
                    <div className={styles.inputFile}>
                        <label htmlFor="input_file1"><img src={plus}/></label>
                        <input id={'input_file1'} type="file" onChange={e => {
                            setAttachedImage(e.target.files[0])
                        }}/>
                    </div>
                    <TextareaAutosize
                        value={text}
                        onKeyPress={e => {
                            if (e.keyCode === 13) {
                                onSend()
                            }
                        }}
                        minRows={1}
                        maxRows={3}
                        onChange={e => {
                            onInputTextChanged(e.target.value)
                        }}
                        placeholder={'Напишите ваше сообщение'}/>
                    <div onClick={onSend}>
                        <img src={send}/>
                    </div>
                </div>
            </div>
        </Box>
    </Modal>
}

const Message = ({item, isCourier, name, photo}) => {

    return <div style={{alignItems: isCourier ? 'flex-start' : 'flex-end'}} className={styles.messageWrapper}>
        {isCourier && <div className={styles.managerinfo}>
            {photo ? <img src={photo}/> :
                <img src={user}/>}
            <SText size={12} weight={500} lineHeight={15} color={'#adadad'}>{name}</SText>
        </div>}
        <div style={{background: isCourier ? '#f2f2f2' : '#abf69e'}} className={styles.message}>
            {item.attachment && <a target={'_blank'} href={item.attachment} rel="noreferrer">
                <div className={styles.messageImg}><img src={item.attachment}/></div>
            </a>}
            <SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{item.content}</SText>
            <div className={styles.messageTime}>
                <SText size={12} weight={500} lineHeight={15}
                       color={isCourier ? '#adadad' : '#888'}>{moment(item.datetime).format('LT')}</SText>
            </div>
        </div>
    </div>
}
export default CourierChat