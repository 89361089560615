import React, {useState} from 'react';
import styles from './Contacts.module.scss'
import SText from "../../../components/SText";
import wa from '../../../assets/images/green_whatsapp.png'
import sendRequest from "../../../api";
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import Colors from "../../../components/utils/Colors";

const Contacts = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [mail, setMail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [success, setSuccess] = useState(false)

    const validateEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validate = (FIELD) => {
        let phoneNumber = phone?.toString().replace(/\D+/g, '');
        if ((name.length > 0) || (phoneNumber.length > 0) || (mail.length > 0) || (companyName.length > 0)) {
            switch (FIELD) {
                case 'NAME':
                    if (name.length < 2) {
                        return 'invalid';
                    }
                    break;
                case 'PHONE':
                    if (phoneNumber.length !== 11) {
                        return 'invalid';
                    }
                    break;
                case 'EMAIL':
                    if (!validateEmail(mail)) {
                        return 'invalid';
                    }
                    break;
                case 'COMPANY':
                    if (companyName.length < 4) {
                        return 'invalid';
                    }
                    break;
            }
        }
    }

    const sendRequests = async () => {
        let phoneNumber = phone.toString().replace(/\D+/g, '');
        if ((name.length > 1) && (phoneNumber.length === 11) && (validateEmail(mail)) && (companyName.length > 3)) {
            try {
                let SEND_DATA = {
                    token: false,
                    phone: phone,
                    email: mail,
                    company: companyName,
                    name: name,
                };

                let data = await sendRequest('recordB2Brequest', SEND_DATA);
                if (data !== false) {
                    /*                try {
                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push({
                                            'event': 'generate_lead',
                                            'form_name': 'B2B',
                                        });
                                    } catch {}*/

                    setMail('')
                    setPhone('')
                    setName('')
                    setCompanyName('')
                    setSuccess(true)
                }
            } catch (error) {
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <NavLink to={'/'}>
                    <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
                </NavLink>
                <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'Контакты'}</SText></div>
            </div>
            <div className={styles.container}>
                <div style={{marginBottom: 30}}><SText size={36} lineHeight={36} weight={500}>{'Контакты'}</SText></div>
                <div style={{marginBottom: 20}}><SText size={16} weight={500}>{'Служба поддержки'}</SText></div>
                <div className={styles.numberLine}>
                    <SText>{'Москва и регионы:'}</SText>
                    <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <img src={wa}/>
                        <a href="tel:+74951599009"><SText>{'+7 (495) 159-90-09'}</SText></a>
                    </div>
                </div>
                <div><SText>{'Служба поддержки: support@ecomarket.ru'}</SText></div>
                <div><SText>{'Отдел закупок: vendors@ecomarket.ru'}</SText></div>
                <div style={{marginBottom: 30}}><SText>{'Отдел маркетинга: hasueva@ecomarket.ru'}</SText></div>
                <div className={styles.numberLine}>
                    <SText>{'Отдел корпоративных продаж B2B :'}</SText>
                    <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <img src={wa}/>
                        <a href="tel:+74951599009"><SText>{'+7 (495) 159-90-09'}</SText></a>
                    </div>
                </div>
                <div style={{marginBottom: 30}}><SText>{'Почта: b2b@ecomarket.ru'}</SText></div>


                <div className={styles.form}>
                    <div className={styles.formTitle}>{' Оставьте заявку, и менеджер отдела В2В'} <br/>
                        {'свяжется с вами в течение 1 часа'}</div>
                    <div className={styles.inputGrid}>
                        <input style={{
                            color: validate('NAME') === 'invalid' ? '#f03517' : '#383838',
                            border: validate('NAME') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                        }} value={name}
                               onChange={(e) => setName(e.target.value)}
                               className={styles.invalid} type="text"
                               placeholder={'Имя'}/>
                        <input value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                               style={{
                                   color: validate('COMPANY') === 'invalid' ? '#f03517' : '#383838',
                                   border: validate('COMPANY') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                               }} type="text"
                               placeholder={'Название компании'}/>
                        <input value={phone} onChange={(e) => setPhone(e.target.value)}
                               style={{
                                   color: validate('PHONE') === 'invalid' ? '#f03517' : '#383838',
                                   border: validate('PHONE') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                               }} type="text"
                               placeholder={'Номер телефона'}/>
                        <input value={mail} onChange={e => setMail(e.target.value)}
                               style={{
                                   color: validate('EMAIL') === 'invalid' ? '#f03517' : '#383838',
                                   border: validate('EMAIL') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                               }} type="text"
                               placeholder={'E-mail'}/>
                    </div>
                    <div className={styles.submitBtn}>
                        <button onClick={sendRequests}>{'Отправить'}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;