import React, {useEffect, useState} from "react";
import styles from '../styles.module.scss'
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../../components/utils/ColorFilter";
import back from "../../../../assets/images/cart/goBack.svg";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import {useDispatch, useSelector} from "react-redux";
import warning from '../../../../assets/images/warning.svg'
import {HistoryItem} from "../../../../screens/UserAccount/OrdersHistory";
import {fetchOrders} from "../../../../redux/reducers/user";

const OrdersHistory = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const orders = useSelector(state => state.user.orders)
    const [loadChunk, setLoadChunk] = useState(0)
    const [chatId, setChatId] = useState(null)

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return () => document.removeEventListener('scroll', scrollHandler)
    }, [])

    const scrollHandler = async (e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) === 0) {
            setLoadChunk(prev => prev + 1)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (loadChunk !== 0)
                await loadNextChunk()
        }
        fetchData()
    }, [loadChunk])

    async function loadNextChunk() {
        dispatch(fetchOrders())
    }

    return <div className={styles.container}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'История заказов'}</SText></div>
        </div>

        {user.orders.unpaid > 0 &&
            <div style={{marginBottom: 20}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 12, gap: 10}}><img src={warning}/>
                    <SText
                        size={14} weight={700} lineHeight={18}
                        color={Colors.red}>{'У вас ' + user.orders.unpaid + ' неоплаченных заказа'}</SText></div>
                <div className={styles.unpaidOrders}>{'Перейдите в детали заказа'}
                    <br/> {'и нажмите кнопку “оплатить”. Спасибо!'}</div>
            </div>}

        <div>
            {
                orders.items.map(order => <HistoryItem setChatId={setChatId} key={order.ID} order={order}/>)
            }
        </div>

    </div>
}

export default OrdersHistory