import {useContext} from "react";
import OrderProductXChangeContext from "../../../../components/utils/OrderProductXChange";
import {useQueryParams} from "../../../../components/utils/ModalSpectator";
import SupportIcon from '../../../../assets/images/userAccount/help.svg'
import ChatIcon from '../../../../assets/images/userAccount/chatBtn.svg'
import PlusIcon from '../../../../assets/images/userAccount/plus.svg'
import EllipsisIcon from '../../../../assets/images/ellipsis.svg'
import SText from "../../../../components/SText";
import styles from './styles.module.scss'
import Colors from "../../../../components/utils/Colors";
import {colorToFilter} from "../../../../components/utils/ColorFilter";

const OrderControls = ({
                           orderStatus, currentScene, onSetScene = () => {
    }, canAdd, canChat = false, orderId, order
                       }) => {
    const {setAddMode} = useContext(OrderProductXChangeContext)

    const {push} = useQueryParams()


    function onSupport() {
        push(['supportChat', ''])
    }

    function onCourierChatPress() {
        push(['chat', orderId])
    }

    const isDetailsActive = currentScene === 'details'

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 30,
        margin: '16px auto',
        width: '100%',
        paddingBottom: 50,
        maxWidth: 220
    }}>
        <ControlButton Icon={SupportIcon} text={'Служба\nподдержки'} onPress={onSupport}/>
        <ControlButton Icon={EllipsisIcon}
                       active={isDetailsActive}
                       onPress={() => onSetScene(isDetailsActive ? 'banners' : 'details')}
                       text={'Детали\nзаказа'}/>
        {canChat ?
            <ControlButton Icon={ChatIcon} disabled={!canChat} text={'Чат\nс курьером'} onPress={onCourierChatPress}/> :
            canAdd ? <ControlButton Icon={PlusIcon} disabled={!canAdd} text={'Добавить\nк заказу'}
                                    onPress={setAddMode}/> : null}
    </div>
}

const ControlButton = ({
                           text, Icon, onPress = () => {
    }, disabled = false, active = false
                       }) => {
    return <div className={styles.buttonWrapper} style={{opacity: disabled ? .4 : 1}}>
        <div className={styles.button}
             style={{background: active ? Colors.darkGray : undefined}} onClick={disabled ? () => {
        } : onPress}>
            <img src={Icon} style={{filter: colorToFilter(active ? '#fff' : Colors.darkGray)}}/>
        </div>
        <SText div className={styles.buttonText} size={12} weight={500} color={'#909090'}>
            {text}
        </SText>
    </div>
}


export default OrderControls
