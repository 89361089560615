import React, {useContext, useEffect} from "react";
import styles from '../styles.module.scss'
import {useSelector} from "react-redux";
import {TempWrapper} from "../../../../screens/OrderTradeOff/OrderTradeOff";
import {Box, Modal} from "@mui/material";
import {useNavigate} from "react-router-dom";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import OrderProductXChangeContext from "../../../../components/utils/OrderProductXChange";
import HelperContext from "../../../../redux/HelperContext";
import {roundPrice} from "../../../../components/utils/other";
import BottomModal from "../../../components/BottomModal";

const AddProductModal = ({onClose}) => {
    const {remains, missedProducts, dismissTradeOff, mode, confirmTradeOff} = useContext(OrderProductXChangeContext)
    const navigate = useNavigate()
    const cart = useSelector(state => state.cart.order)
    const {totals} = useContext(HelperContext)
    const cartSum = totals.cart.actual
    const missedSum = missedProducts.reduce((sum, product) => {
        return sum + (Number(product?.sum) || 0)
    }, 0)
    const discount = mode === 'add' ?
        1 :
        1 - .2

    useEffect(() => {
        if (mode === 'none')
            navigate(-1)
    }, [mode])


    function onMainButtonPress() {
        cart.length ? confirmTradeOff() : navigate('/')
    }

    return <BottomModal onClose={onClose}>
        <div className={styles.modal}>
            <div style={{marginBottom: 28}}><SText size={20} weight={900} lineHeight={25}
                                                   color={Colors.darkGray}>{'Добавление товаров'}</SText></div>
            <div style={{marginBottom: 15}}><SText size={16} weight={700} lineHeight={16}
                                                   color={Colors.darkGray}>{cart.length ?
                `Добавлено к заказу:` :
                'Вы пока что ничего не добавили к заказу'}</SText></div>
            <div style={{marginBottom: 30}}>
                {cart.map(id => <TempWrapper id={id} key={id} controllable/>)}
            </div>

            <div className={styles.buttons}>
                <div onClick={onMainButtonPress} className={styles.mainBtn}>
                    <div className={styles.mainBtnText}>
                        {cart.length ?
                            `Подтвердить добавление (${roundPrice((discount * cartSum) - missedSum)} ₽)` :
                            'Перейти к выбору'
                        }
                    </div>
                    <div className={styles.timer}>
                        <SText size={14} weight={700} lineHeight={21}
                               color={remains.asSeconds() <= 35 ? Colors.red : Colors.darkGray}>{`${remains.minutes()}:${String(remains.seconds()).padStart(2, '0')}`}</SText>
                    </div>
                </div>
                <div onClick={dismissTradeOff} className={styles.skipBtn}><SText size={13} weight={700}
                                                                                 lineHeight={18}
                                                                                 color={'#fff'}>{'Отменить'}</SText>
                </div>
            </div>
        </div>
    </BottomModal>
}

export default AddProductModal