import styles from "./styles.module.css";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import {CircularProgressbar} from "react-circular-progressbar";
import React from "react";


const Circle = ({percentage, value, units, title, bigger=false}) => {

    return <div>
            <div className={bigger ? styles.bigCircle : styles.smallCircle}>
                <CircularProgressbar value={percentage}/>
                <div className={styles.textInside}>
                    <SText size={bigger ? 20 : 14}  weight={bigger ? 500 : 400} color={'#bdbdbd'}>{percentage}%</SText>
                    {bigger && <div>{`Суточной`} <br/> {'нормы'}</div>}
                </div>
            </div>
            <div style={{marginTop: 7}}>
                <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                          color={Colors.darkGray}
                                                          fontType={1}>{title}</SText></div>
                <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                          color={Colors.darkGray}>{value + ' ' + units}</SText></div>
            </div>
        </div>
}


const NutritionalValue = ({calories, bel, ugl, fats, klet}) => {

    if (!bel || !ugl || !fats) return null

    let correctCal = Math.ceil(parseFloat(calories) / 2200 * 100) || 1
    let correctBel = Math.ceil(parseFloat(bel) / 80 * 100) || 1
    let correctFats = Math.ceil(parseFloat(fats) / 60 * 100) || 1
    let correctUgl = Math.ceil(parseFloat(ugl) / 80 * 100) || 1
    let correctKlet = Math.ceil(klet / 28 * 100) || 1

    return <div className={styles.nutritionalValueCard}>
        <div><SText size={16} weight={700} lineHeight={20}
                    color={Colors.darkGray}>{'Энергетическая и пищевая ценность'}</SText></div>
        <div style={{marginBottom: 21}}><SText size={12} lineHeight={15} weight={400}
                                               color={'#bdbdbd'}>{'на 100 г продукта'}</SText></div>
        <div className={styles.circles}>

            <Circle
                bigger
                title={'Калорийность'}
                value={calories}
                percentage={correctCal}
                units={''}
            />
            <div style={{flex: 1}}>
                <div style={{display: 'flex', marginBottom: 20, justifyContent: 'space-evenly'}}>
                    <Circle
                        title={'Белки'}
                        value={bel}
                        percentage={correctBel}
                        units={'г'}
                    />
                    <Circle
                        title={'Жиры'}
                        value={fats}
                        percentage={correctFats}
                        units={'г'}
                    />
                </div>
                <div style={{display: 'flex', marginBottom: 20, justifyContent: 'space-evenly'}}>
                    <Circle
                        title={'Углеводы'}
                        value={ugl}
                        percentage={correctUgl}
                        units={'г'}
                    />
                    <Circle
                        title={'Клетчатка'}
                        value={klet}
                        percentage={correctKlet}
                        units={'г'}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default NutritionalValue
