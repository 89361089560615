import React, { useContext, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CatalogueCarouselV2 from "../Catalogue/components/CatalogueCarouselV2";
import DeliveryBanner from "../../components/Banner/DeliveryBanner";
import { useQueryParams } from "../../components/utils/ModalSpectator";
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import Showcase from "../../components/Banner/Showcase";
import cn from "classnames";
import specificStyles from "./Catalogue.module.scss";
import styles from "../../components/BigList/styles.module.css";
import SubCategoryCarousel from "./components/SubCategoryCarousel";
import { DIMENSIONS } from "../../mobile/utils";
import ProductsCarousel from "../../mobile/screens/CatalogueV2/components/ProductsCarousel";
import { Helmet } from "react-helmet";

const CatalogueV3 = () => {
    const user = useSelector(state => state.user)
    const categories = useSelector(state => state.catalogue.catV6.masterOrder)
    const products = useSelector(state => state.catalogue.products)
    const { altProducts } = useContext(OrderProductXChangeContext)
    const recommended = useSelector(state => state.user.personalRecommendations)
    const navigate = useNavigate()
    const { push } = useQueryParams()
    const [showDeliveryBanner, closeDeliveryBanner] = useState(!user.apikey)

    function onCategoryPress(id) {
        navigate('Category', { id })
    }

    useEffect(() => {
        if (window?.newUserOrderId) {
            setTimeout(() => {
                push(['auth', window.newUserPhone])
            }, 200)
        } else if (window.orderId) {
            setTimeout(() => {
                push(['orderDetails', window.orderId])
            }, 200)
        }
    }, [window?.orderId, window?.newUserOrderId])

    let data = categories

    if (altProducts.length) {
        const additionalRowData = {
            name: 'Рекомендуем к замене',
            items: altProducts.filter(id => !!products[id]),
        }
        data = [additionalRowData, ...data]
    }

    if (recommended.length && false) {
        const additionalRowData = {
            name: 'Вы часто берете',
            items: recommended.filter(id => !!products[id]),
        }
        data = [additionalRowData, ...data]
    }

    return (
        <>
            <Helmet>
                <title>Интернет-магазин Экомаркет — доставка продуктов на дом в Москве и Московской области, купить
                    продукты питания с доставкой в онлайн-магазине</title>
                <meta name="description"
                    content="Доставка био, экопродуктов, всего за 90 минут по городу и области. Огромный ассортимент, более 1000 наименований.В наличии Халяльные и Кошерные продукты. Правильное и здоровое питание с Экомаркетом!"
                    data-react-helmet="true" />
                <meta name="keywords" content="Доставка продуктов" data-react-helmet="true" />
                <meta name="viewport"
                    content="width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no" />
            </Helmet>
            <div style={ { backgroundColor: 'white' } }>
                <Showcase />
            </div>
            { showDeliveryBanner && <DeliveryBanner show={ closeDeliveryBanner } /> }
            <div style={ { position: 'sticky', top: 100, left: 0, zIndex: 2, background: '#fff', padding: '0 0 5px' } }>
                <CatalogueCarouselV2 currentSection={ -1 } setCurrentSection={ (id) => onCategoryPress(id) } />
            </div>
            {
                data.map((item, index) => {
                    if (item.items && item.name)
                        return <ProductsCarousel key={ index } name={ item.name } items={ item.items } />
                    else
                        return <ProductsCarousel key={ index } categoryId={ item } />;
                })
            }
        </>
    );
}

export default CatalogueV3