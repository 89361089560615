import React, {useEffect, useRef, useState, useMemo} from "react";
import styles from './InnerProduct.module.scss'
import closeModal from "../../assets/images/closeModal.svg";
import nextPhoto from '../../assets/images/productCard/nextPhoto.svg'
import minus from '../../assets/images/productCard/minus.svg'
import plus from '../../assets/images/productCard/plus.svg'
import ruble from '../../assets/images/productCard/rubleBig.svg'
import cn from "classnames";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"; 
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import ProductCard, {getBadgeLabel} from "../ProductCard/ProductCard";
import {useDispatch, useSelector} from "react-redux";
import sendRequest, {BASE_DOMAIN} from "../../api";
import {getWeight, numberWithSpaces, roundPrice} from "../../components/utils/other";
import {cartMinus, cartPlus} from "../../redux/reducers/cart";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Box, Modal} from "@mui/material";
import closeBtn from "../../assets/images/closeModal.svg";
import {NavLink} from "react-router-dom";

import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';
import ScrollContainer from "react-indiana-drag-scroll";

const ImageItem = ({image, title}) => {

    return image.full ?
        <img style={{objectFit: 'cover'}} src={BASE_DOMAIN + image.full} alt={title.replace(/['"]+/g, '')}/> :
        <img src={'https://api.ecomarket.ru/imgs/no-foto.png'} style={{objectFit: 'cover'}}
             alt={title.replace(/['"]+/g, '')}/>
}

const Image = ({images, fromFullPage = false, product}) => {
    const [current, setCurrent] = useState(0)
    const photosAmount = images.length

    return <div style={{top: fromFullPage ? 139 : 0}} className={styles.image}>
        <ImageItem title={product.title} image={images[current]}/>

        {photosAmount > 1 && <div className={styles.photoController}>
            <div onClick={() => {
                if (current - 1 >= 0)
                    setCurrent(prev => prev - 1)
            }} style={{opacity: ((current - 1) >= 0) ? 1 : 0.5}} className={cn(styles.prev, styles.nextPhotoCircle)}>
                <img
                    src={nextPhoto}/></div>
            <div onClick={() => {
                if (current + 1 < photosAmount)
                    setCurrent(prev => prev + 1)
            }} style={{opacity: ((current + 1) < photosAmount) ? 1 : 0.5}} className={styles.nextPhotoCircle}><img
                src={nextPhoto}/>
            </div>
        </div>}
    </div>
}

const Title = ({product}) => {

    return <div style={{marginBottom: 25}}>
        <NavLink to={'/' + product.url} style={{marginBottom: 25}}>
            <h1 className={styles.title} title={product.title}>{product.title}</h1>
        </NavLink>
        <div style={{display: 'flex', gap: 25}}>
            <SText size={14} weight={700} lineHeight={18}
                   color={'#bfbfbf'}>{getWeight(product.weight, product.ed_izm)}</SText>
            <SText size={14} lineHeight={15} weight={700} color={Colors.green}>{getBadgeLabel(product)}</SText>
        </div>
    </div>
}

const PriceAndButtons = ({product}) => {
    const [wholesaleClicked, setWholesaleClicked] = useState(false)
    const amount = useSelector(state => state.cart.items[Number(product.id)])
    const dispatch = useDispatch()

    function onPlus() {
        if (amount + 1 > product.availableones) {
            return
        }
        dispatch(cartPlus(product.id))
    }

    function onMinus() {
        dispatch(cartMinus(product.id))
    }

    const isDiscount = !!product.old_price

    const discount = Math.round(100 * (1 - parseFloat(product.price) / parseFloat(product.old_price)))


    return <div className={styles.priceAndBtns}>
        <div>
            <div>
                {
                    isDiscount ?
                        <div className={styles.oldPrice}>
                            <SText style={{textDecoration: 'line-through'}} size={16} weight={500} lineHeight={16}
                                   color={'#bdbdbd'}>{roundPrice(product.old_price) + ' ₽'}</SText>
                            <div className={styles.oldPricePercent}>
                                <SText size={16} weight={700} lineHeight={16}
                                       color={Colors.orange}>{`-${discount}%`}</SText>
                            </div>
                        </div> :
                        null
                }
            </div>
            <div style={{display: 'flex', gap: 17, alignItems: 'flex-end', minWidth: 200}}>
                <span style={{display: 'flex', alignItems: 'center', gap: 7}}>
                <SText size={28} weight={700} lineHeight={28}
                       color={Colors.darkGray}>{`${numberWithSpaces(product.price)} `}</SText>
                <img src={ruble}/></span>
                <SText size={14} weight={500} lineHeight={17}
                       color={Colors.darkGray}>{'за ' + getWeight(product.weight)}</SText>
            </div>
        </div>
        <div>

            {/*            <div className={styles.toggle}>
                <div onClick={() => setWholesaleClicked(false)}
                     className={cn(wholesaleClicked ? null : styles.selectedToggle, styles.toggleBtn)}><SText size={14}
                                                                                                              lineHeight={20}
                                                                                                              weight={500}
                                                                                                              color={wholesaleClicked ? '#bfbfbf' : Colors.darkGray}>{'1 шт'}</SText>
                </div>
                <div onClick={() => setWholesaleClicked(true)}
                     className={cn(!wholesaleClicked ? null : styles.selectedToggle, styles.toggleBtn)}
                     style={{display: 'flex', gap: 7}}><SText size={14} lineHeight={20} weight={500}
                                                              color={!wholesaleClicked ? '#bfbfbf' : Colors.darkGray}>{'6 шт'}</SText>
                    <SText size={14} lineHeight={20} weight={500} color={Colors.orange}>{'56%'}</SText></div>
            </div>*/}

            {(product.availableones == 0 ?
                <div className={styles.amountBtn} style={{
                    backgroundColor: 'transparent',
                    borderColor: Colors.orange,
                    borderWidth: 1,
                    borderStyle: 'solid'
                }}>
                    <SText color={Colors.orange} weight={500} size={16}>{'Нет в наличии'}</SText>
                </div> :
                !amount ? <div onClick={onPlus} className={styles.amountBtn}>
                    <SText size={16} weight={500} lineHeight={20} color={'#fff'}>{'Добавить в корзину'}</SText>
                </div> : <div className={styles.pressedAmountBtn}>
                    <div className={styles.minusBtn} onClick={onMinus}><img src={minus}/></div>
                    <SText size={16} weight={700} lineHeight={20} color={Colors.darkGray}>{amount}</SText>
                    <div className={styles.plusBtn} style={{opacity: amount + 1 > product.availableones ? 0.4 : 1}} onClick={onPlus}><img src={plus}/></div>
                </div>)}

        </div>
    </div>
}

const Description = ({description, ingredients, bestBefore}) => {
    let desc = description.replaceAll('\n', '<br/>')

    return <div>
        <div style={{marginBottom: 15}}>
            <SText size={15}
                   weight={700}
                   lineHeight={19}
                   color={Colors.darkGray}
            >
                {'Описание товара'}
            </SText>
        </div>
        <div style={{marginBottom: 20}}
             dangerouslySetInnerHTML={{__html: `<div style="font-size:14px;font-weight: 500;line-height: 21px;color: #383838;font-family: 'DINPro-Medium', sans-serif">${desc}</div>`}}></div>
        <div style={{marginBottom: 15}}>
            <SText size={15}
                   weight={700}
                   lineHeight={19}
                   color={Colors.darkGray}
            >
                {'Условия хранения'}
            </SText>
        </div>

        <div style={{marginBottom: 20}}>
            <SText size={14}
                   weight={500}
                   lineHeight={21}
                   color={Colors.darkGray}
            >
                {bestBefore.length ? bestBefore : 'Не указаны.'}
            </SText>
        </div>

        <div style={{marginBottom: 15}}>
            <SText size={15}
                   weight={700}
                   lineHeight={19}
                   color={Colors.darkGray}
            >
                {'Состав'}
            </SText>
        </div>

        <div>
            <SText size={14}
                   weight={500}
                   lineHeight={21}
                   color={Colors.darkGray}
                   style={{whiteSpace: 'pre-line'}}
            >
                {ingredients.length ? ingredients : 'Не указан.'}
            </SText>
        </div>

    </div>
}

const NutritionalValue = ({calories, bel, ugl, fats, klet}) => {
    let correctCal = Math.ceil(parseFloat(calories) / 2200 * 100) || 1
    let correctBel = Math.ceil(parseFloat(bel) / 80 * 100) || 1
    let correctFats = Math.ceil(parseFloat(fats) / 60 * 100) || 1
    let correctUgl = Math.ceil(parseFloat(ugl) / 80 * 100) || 1
    let correctKlet = Math.ceil(klet / 28 * 100) || 1

    return <div className={styles.nutritionalValueCard}>
        <div><SText size={16} weight={700} lineHeight={20}
                    color={Colors.darkGray}>{'Энергетическая и пищевая ценность'}</SText></div>
        <div style={{marginBottom: 21}}><SText size={12} lineHeight={15} weight={400}
                                               color={'#bdbdbd'}>{'на 100 г продукта'}</SText></div>
        <div className={styles.circles}>

            <div>
                <div className={styles.bigCircle}>
                    <CircularProgressbar
                        value={correctCal}
                    />
                    <div className={styles.textInside}>
                        <SText size={20} lineHeight={25} weight={500} color={'#bdbdbd'}>{correctCal}%</SText>
                        <div>{`Суточной`} <br/> {'нормы'}</div>
                    </div>
                </div>
                <div style={{marginTop: 7}}>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                              color={Colors.darkGray}
                                                              fontType={1}>{'Калорийность'}</SText></div>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                              color={Colors.darkGray}>{calories}</SText></div>
                </div>
            </div>

            <div>
                <div className={styles.smallCircle}>
                    <CircularProgressbar
                        value={correctBel}
                    />
                    <div className={styles.textInside}>
                        <SText size={14} lineHeight={17} weight={400} color={'#bdbdbd'}>{correctBel}%</SText>
                    </div>
                </div>
                <div style={{marginTop: 7}}>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                              color={Colors.darkGray}
                                                              fontType={1}>{'Белки'}</SText></div>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                              color={Colors.darkGray}>{bel + ' г'}</SText></div>
                </div>
            </div>

            <div>
                <div className={styles.smallCircle}>
                    <CircularProgressbar
                        value={correctUgl}
                    />
                    <div className={styles.textInside}>
                        <SText size={14} lineHeight={17} weight={400} color={'#bdbdbd'}>{correctUgl}%</SText>
                    </div>
                </div>
                <div style={{marginTop: 7}}>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                              color={Colors.darkGray}
                                                              fontType={1}>{'Углеводы'}</SText></div>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                              color={Colors.darkGray}>{ugl + ' г'}</SText></div>
                </div>
            </div>

            <div>
                <div className={styles.smallCircle}>
                    <CircularProgressbar
                        value={correctFats}
                    />
                    <div className={styles.textInside}>
                        <SText size={14} lineHeight={17} weight={400} color={'#bdbdbd'}>{correctFats}%</SText>
                    </div>
                </div>
                <div style={{marginTop: 7}}>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                              color={Colors.darkGray}
                                                              fontType={1}>{'Жиры'}</SText></div>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                              color={Colors.darkGray}>{fats + ' г'}</SText></div>
                </div>
            </div>

            <div>
                <div className={styles.smallCircle}>
                    <CircularProgressbar
                        value={correctKlet}
                    />
                    <div className={styles.textInside}>
                        <SText size={14} lineHeight={17} weight={400} color={'#bdbdbd'}>{correctKlet}%</SText>
                    </div>
                </div>
                <div style={{marginTop: 7}}>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                              color={Colors.darkGray}
                                                              fontType={1}>{'Клетчатка'}</SText></div>
                    <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                              color={Colors.darkGray}>{klet + ' г'}</SText></div>
                </div>
            </div>

        </div>
    </div>
}

async function getProduct(id, region, extras) {
    const payload = {
        id: id,
        region: region
    }
    const response = await sendRequest('getProductById_v2', payload)
    if (!response) throw 'No product was returned'
    return {...response, ...extras}
}

const InnerProduct = ({onClose, id}) => {
    const product = useSelector(state => state.catalogue.products[Number(id)])
    const catalogue = useSelector(state => state.catalogue)
    const scrollRef = useRef(null)
    const scrollCarouselRef = useRef(null)

    const [data, setData] = useState(null)

    async function loadProduct() {
        const response = await getProduct(id, 77, product)
        setData(response)
        scrollRef.current && scrollRef.current.scrollTo({top: 0, behavior: "smooth"})
    }

    let checkPFC = () => {
        return (data.bel_amount && data.ugl_amount && data.fats_amount)
    }

    useEffect(() => {
        loadProduct()
    }, [id])

    useEffect(() => {
        if (typeof window.yaCounter49140634 !== "undefined" && data) {
            window.yaCounter49140634.reachGoal('productView');
        }
    }, [data])

    const breadcrumbTree = useMemo(() =>
    {
        if (!data) {
            return []
        }

        let treeSteps = [];

        treeSteps.push({
            title: data.title,
            url: data.url
        })

        if (data.group.length) {
            let groupData = catalogue?.catV6?.map[data.group]
            if (groupData) {
                treeSteps.push({
                    title: groupData.name,
                    url: groupData.url
                })
            }

            if (groupData.parent > 0) {
                let parentGroupData = catalogue?.catV6?.map[groupData.parent]
                if (parentGroupData) {
                    treeSteps.push({
                        title: parentGroupData.name,
                        url: parentGroupData.url
                    })
                }
            }
        }

        treeSteps.push({
            title: 'Главная',
            url: ''
        })
        
        return treeSteps.reverse();

    },[data, catalogue])

    const related = useMemo(() =>
    {

        if (!data) {
            return []
        }

        if (data.related && data.related.length) {
            return data.related
        }

        if (data.group > 0 && catalogue.categories.slave.map[data.group]) {
            let ids = []
            for (let id of catalogue.categories.slave.map[data.group].products) {
                if (parseInt(id) !== parseInt(data.id)) {
                    ids.push(id);
                }
            }
            return ids;
        }

        return []
    }, [data, catalogue])

    if (!data)
        return null


    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Box style={{display: 'flex'}}>
            <div ref={scrollRef} className={styles.modal}>
                <Breadcrumbs breadcrumbTree={breadcrumbTree} />

                <div style={{display: 'flex', gap: 40, marginBottom: 45}}>
                    <Image images={[{full: data.big}, data.images[1]].filter(Boolean)} product={data}/>
                    <div>
                        <Title product={data}/>
                        <PriceAndButtons product={data}/>
                        <Description description={data.short_description} ingredients={data.sostav} bestBefore={data.storage}/>
                    </div>
                </div>

                {related.length !== 0 && <div>
                    <div style={{marginBottom: 28}}>
                        <SText size={15} weight={700} lineHeight={19} color={Colors.darkGray}>
                            {'С этим товаром берут'}
                        </SText>
                    </div>
                    <ScrollContainer
                        style={{marginBottom: 0, paddingBottom: 0}}
                        innerRef={scrollRef}
                        horizontal
                        className={styles.showcaseCarousel}
                    >
                        {
                            (() => {
                                let renderedProducts = 0
                                let array = []
                                for (let id of related) {
                                    if (renderedProducts < 5 && !!catalogue.products[id]) {
                                        array.push(<ProductCard id={id}/>)
                                        renderedProducts++
                                    }
                                }
                                return array
                            })()
                        }
                    </ScrollContainer>
                </div>}

                {checkPFC() && <NutritionalValue klet={data.klet_amount} fats={data.fats_amount} ugl={data.ugl_amount}
                                                 bel={data.bel_amount}
                                                 calories={data.calories_amount}/>}

                <img className={styles.closeModal} src={closeModal}/>
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeBtn}/></div>
        </Box>
    </Modal>
}

export default InnerProduct


export {NutritionalValue, PriceAndButtons, Description, Title, ImageItem, Image, getProduct}
