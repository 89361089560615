import React, {useEffect, useState} from "react";
import styles from './styles.module.scss'
import MapHelper from "../../../screens/Map/MapHelper";
import {useDispatch, useSelector} from "react-redux";
import {updateAddress} from "../../../redux/reducers/user";
import SText from "../../../components/SText";
import {useNavigate} from "react-router-dom";
import Colors from "../../../components/utils/Colors";
import pencil from '../../../assets/images/map/addressPencil.svg'
import arrow from '../../../assets/images/cart/goBack.svg'
import {colorToFilter} from "../../../components/utils/ColorFilter";
import BottomModal from "../../components/BottomModal";
import Div100vh from "react-div-100vh";

const Map = () => {
    const address = useSelector(state => state.user.address)
    const [ad, setAd] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [mapHelper, setMapHelper] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [mapVisible, setMapVisible] = useState(false)
    const dispatch = useDispatch()
    const [gps, setGps] = useState([])
    const navigate = useNavigate()
    const [addressModal, setAddressModal] = useState(false)

    function onSubmitAddress() {
        dispatch(updateAddress({
            name: ad,
            name_short: ad,
            gps: [gps[0], gps[1]],
        }))
        navigate(-1)
    }

    useEffect(() => {
        setTimeout(() => {
            setMapVisible(true)
        }, 500)
    }, [])

    return <Div100vh className={styles.wrapper}>
        <MapHelper
            setAdressAndGps={(address, gps) => {
                setGps(gps)
            }}
            resetGps={() => {
            }}
            setDisabled={setDisabled}
            ref={(e) => setMapHelper(e)}
            inputAddress={ad}
            setInputAddres={e => setAd(e)}
            setSuggestions={(suggestions) => {
                setSuggestions(suggestions)
            }}
            mapLoaded={() => {
                setMapVisible(true)
            }}
            showButtons={'true'}
            GPS={address.gps && address.gps[0] ? address.gps : [55.754638, 37.621633]}
            mobile
        />
        {mapVisible && <div>
            <div onClick={() => navigate(-1)} className={styles.goBack}><img
                style={{filter: colorToFilter(Colors.darkGray)}} src={arrow}/></div>
            <div onClick={() => setAddressModal(true)} className={styles.address}><SText size={22} lineHeight={28}
                                                                                         weight={900}
                                                                                         color={Colors.darkGray}>{ad}</SText>
                <img style={{margin: '3px 0 0 10px'}} src={pencil}/></div>
            <button onClick={onSubmitAddress} disabled={disabled} style={{opacity: disabled ? .7 : 1}}
                    className={styles.submitBtn}><SText size={16} weight={700} lineHeight={16}
                                                        color={'#fff'}>{'Выбрать этот адрес'}</SText></button>
            {addressModal &&
                <AddressModal address={ad} setAddress={setAd} mapHelper={mapHelper} setSuggestions={setSuggestions}
                              suggestions={suggestions} onClose={() => setAddressModal(false)}/>}</div>}
    </Div100vh>
}

const AddressModal = ({
                          onClose,
                          address,
                          setSuggestions,
                          setAddress,
                          suggestions,
                          mapHelper
                      }) => {
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)
    const [value, setValue] = useState(address)

    useEffect(()=>{
        mapHelper.getSuggestions(value)
    }, [value])

    return <BottomModal onClose={onClose}>
        <div className={styles.container}>
            <div style={{position: 'relative'}}>
                {value && <div onClick={() => {
                    setSuggestions([])
                    setValue('')
                }} className={styles.clearInput}>
                    <span>+</span>
                </div>}
                <div className={styles.input}>
                    <input onBlur={() => setTimeout(() => {
                        setSuggestionsVisible(false)
                    }, 200)} onFocus={() => setSuggestionsVisible(true)}
                           placeholder={'Укажите адрес доставки'} value={value}
                           onChange={e => {
                               setValue(e.target.value)
                           }} type="text"/>
                </div>
                {(suggestionsVisible && suggestions.length > 0) && <div className={styles.suggestions}>
                    {
                        suggestions.map(item => <div className={styles.suggestWrapper}>
                            <div onClick={() => {
                                mapHelper.setSuggestedAdress(item.TITLE)
                                setSuggestionsVisible(false)
                                setAddress(item.TITLE)
                                setValue(item.TITLE)
                                onClose()
                            }} className={styles.suggest}><SText size={16}
                                                                 weight={400}
                                                                 lineHeight={16}
                                                                 color={Colors.darkGray}>{item.TITLE}</SText>
                            </div>
                        </div>)
                    }
                </div>}
            </div>
        </div>
    </BottomModal>
}

export default Map
