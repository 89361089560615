import React, {useEffect, useRef, useState, useMemo} from "react";
import SText from "../../../components/SText";
import BottomModal from "../../components/BottomModal";
import {useSelector} from "react-redux";
import ProductImages from "./components/ProductImages";
import styles from './styles.module.css'
import {getWeight} from "../../../components/utils/other";
import Colors from "../../../components/utils/Colors";
import {getBadgeLabel} from "../../../screens/ProductCard/ProductCard";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"; 
import Recommended from "./components/Recommended";
import sendRequest from "../../../api";
import Description from "./components/Description";
import AmountController from "./components/AmountController";

async function getProduct(id, region, extras) {
    const payload = {
        id: id,
        region: region
    }
    const response = await sendRequest('getProductById_v2', payload)
    if (!response) throw 'No product was returned'
    return {...response, ...extras}
}


const ProductModal = ({onClose=()=>{}, id}) => {
    const extras = useSelector(state=>state.catalogue.products?.[id])
    const scrollRef = useRef(null)

    const [product, setProduct] = useState(null)
    const catalogue = useSelector(state => state.catalogue)

    async function loadProduct() {
        const response = await getProduct(id, 77, extras)
        setProduct(response)
    }

    useEffect(()=>{
        loadProduct()
        if (scrollRef.current)
            scrollRef.current.scrollTo({
                top: 0,
                behavior: "smooth"
            })
    },[id])

    useEffect(() => {
        if (typeof window.yaCounter49140634 !== "undefined" && product) {
            window.yaCounter49140634.reachGoal('productView');
        }
    }, [product])

    const breadcrumbTree = useMemo(() =>
    {
        if (!product) {
            return []
        }

        let treeSteps = [];

        treeSteps.push({
            title: product.title,
            url: product.url
        })

        if (product.group.length) {
            let groupData = catalogue?.catV6?.map[product.group]
            if (groupData) {
                treeSteps.push({
                    title: groupData.name,
                    url: groupData.url
                })
            }

            if (groupData.parent > 0) {
                let parentGroupData = catalogue?.catV6?.map[groupData.parent]
                if (parentGroupData) {
                    treeSteps.push({
                        title: parentGroupData.name,
                        url: parentGroupData.url
                    })
                }
            }
        }

        treeSteps.push({
            title: 'Главная',
            url: ''
        })
        
        return treeSteps.reverse();

    },[product, catalogue])

    const related = useMemo(() =>
    {

        if (!product) {
            return []
        }

        if (product.related && product.related.length) {
            return product.related
        }

        if (product.group > 0 && catalogue.categories.slave.map[product.group]) {
            let ids = []
            for (let id of catalogue.categories.slave.map[product.group].products) {
                if (parseInt(id) !== parseInt(product.id)) {
                    ids.push(id);
                }
            }
            return ids;
        }

        return []
    }, [product, catalogue])

    if (!product) {
        //onClose()
        return null
    }

    


    return <BottomModal onClose={onClose} ref={scrollRef}>
        <div style={{padding: '0px 16px'}}>
            <Breadcrumbs breadcrumbTree={breadcrumbTree} />
        </div>
        <ProductImages product={product}/>
        <div className={styles.titleContainer}>
            <h1 className={styles.title} title={product.title}>
                {product.title}
            </h1>
            <div style={{display: 'flex', marginBottom: 12}}>
                <SText weight={700} color={'#bfbfbf'} style={{marginRight: 12}}>
                    {getWeight(product.weight, product.ed_izm)}
                </SText>
                <SText size={14} weight={700} color={Colors.green}>
                    {getBadgeLabel(product)}
                </SText>
            </div>
        </div>
        <div className={'fake-shadow'}/>
        <Description product={product}/>

        <Recommended ids={related}/>
        <div style={{height: 90}}/>
        <div className={styles.amountControllerContainer}>
            <AmountController product={product}/>
        </div>
    </BottomModal>
}

export default ProductModal
