import React, {useContext} from "react";
import styles from './AddressAndPayment.module.scss'
import HelperContext from "../../../redux/HelperContext";
import {useSelector} from "react-redux";
import Colors from "../../../components/utils/Colors";
import SText from "../../../components/SText";
import {roundPrice} from "../../../components/utils/other";

const OrderBtn = ({disabled: _disabled = false, onPress}) => {
    const {totals, delivery, selectedMethod} = useContext(HelperContext)
    const slots = useSelector(state => state.deliverySlots)
    const cartSum = totals.cart.actual
    const minimalOrder = delivery.minimalOrder
    const difference = minimalOrder - cartSum
    const needsMore = difference > 0
    const disabled = _disabled || needsMore || slots.loading

    return <button className={styles.orderBtn} disabled={slots.loading || needsMore}
                   style={{background: needsMore ? Colors.yellow : Colors.green, opacity: disabled ? .5 : 1}}
                   onClick={onPress}>
        {slots.loading ? <div/> : (needsMore ?
            <div style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <SText size={12} weight={500} lineHeight={21}
                       color={Colors.darkGray}>{`Нужно еще добавить товаров на ${roundPrice(difference)} ₽`}</SText>
            </div> :
            <div style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                <SText weight={500} size={16} color={'#fff'}
                       style={{marginRight: 10}}>{`${selectedMethod === 'cash' ? 'Оформить' : 'Оплатить'}`}</SText>
            </div>)}
    </button>
}

export default OrderBtn