import React from "react";
import styles from './styles.module.css'
import SText from "../../../../components/SText";
import Nutritionals from "./Nutritionals";

const Description = ({product}) => {
    const text = product.short_description?.length ?
        product.short_description :
        'У нас так много товаров, что мы не успели заполнить описание товара. Скоро мы обязательно добавим эту информацию'


    return <div>
        <div>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'Описание товара'}</SText>
            <SText size={14} weight={400} div style={{padding: '0 16px 16px'}}>{text}</SText>
        </div>
        {product.storage && <div>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'Условия хранения'}</SText>
            <SText size={14} weight={400} div style={{padding: '0 16px 16px'}}>{product.storage}</SText>
        </div>}
        {product.sostav && <div>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'Состав'}</SText>
            <SText size={14} weight={400} div style={{padding: '0 16px 16px'}}>{product.sostav}</SText>
        </div>}
        <Nutritionals klet={product.klet_amount}
                      fats={product.fats_amount}
                      ugl={product.ugl_amount}
                      bel={product.bel_amount}
                      calories={product.calories_amount}/>
    </div>
}

export default Description
