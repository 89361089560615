import React, {useContext, useState} from "react";
import SText from "../SText";
import Colors from "../utils/Colors";
import styles from './Header.module.scss'
import userZaglushka from '../../assets/images/header/lk.svg'
import balls from '../../assets/images/header/ecoBalls.svg'
import catalogue from '../../assets/images/header/catalogue.svg'
import arrowDown from '../../assets/images/header/arrowDown.svg'
import closeSearch from '../../assets/images/header/closeSearch.svg'
import search from '../../assets/images/header/search.svg'
import whiteSearch from '../../assets/images/header/whiteSearch.svg'
import CatalogueNavigator from "./CatalogueNavigator";
import {NavLink, useNavigate, useLocation} from "react-router-dom";
import SearchPopUp from "./SearchPopUp";
import {SearchContext} from "../utils/search";
import {useSelector} from "react-redux";
import Auth from "../../screens/Auth";
import AccountNavigationModal from "../../screens/UserAccount/AccountNavigationModal";
import {BASE_DOMAIN} from "../../api";
import HeaderCartButton from "../../screens/Cart/HeaderCartButton";
import {prettyDate} from "../utils/other";
import moment from 'moment'
import MapDesktop from "../../screens/Map/MapDesktop";
import {useWindowDimensions} from "../utils/hooks";
import {useQueryParams} from "../utils/ModalSpectator";

export const HeaderInfo = () => {

    return <div className={styles.headerInfoWrapper}>
        <div className={styles.headerInfo}>
            <NavLink to={'/save-nature'}><SText style={{cursor: 'pointer', userSelect: 'none'}} size={14} weight={500}
                                                lineHeight={17}
                                                color={Colors.darkGray}>{'Бережём природу'}</SText></NavLink>
            <NavLink to={'/about'}><SText style={{cursor: 'pointer', userSelect: 'none'}} size={14} weight={500}
                                          lineHeight={17}
                                          color={Colors.darkGray}>{'О нас'}</SText></NavLink>
            <NavLink to={'/delivery'}><SText style={{cursor: 'pointer', userSelect: 'none'}} size={14} weight={500}
                                             lineHeight={17}
                                             color={Colors.darkGray}>{'Доставка'}</SText></NavLink>
            <NavLink to={'/pay'}><SText style={{cursor: 'pointer', userSelect: 'none'}} size={14} weight={500}
                                        lineHeight={17}
                                        color={Colors.darkGray}>{'Оплата'}</SText></NavLink>
            <a href="tel:+74951599009"><SText style={{cursor: 'pointer', userSelect: 'none'}} size={14} weight={500}
                                              lineHeight={17}
                                              color={Colors.darkGray}>{'8 (495) 159-90-09'}</SText></a>
        </div>
    </div>
}

export function getNearestDeliveryText({date, time}) {
    if (!date && !time) return ''
    const slotMoment = moment(date, 'YYYY-MM-DD')

    const nearestTime = time.split('-')[0]

    return `Доставим ${prettyDate(slotMoment).toLowerCase()} с ${nearestTime}`
}

const HeaderContent = () => {
    let [catalogueVisible, setCatalogueVisible] = useState(false)
    let [searchPopUpVisible, setSearchPopUpVisible] = useState(false)
    let user = useSelector(state => state.user)
    const slots = useSelector(state => state.deliverySlots)
    const formattedAddress = user.address.name ? user.address.name + ' ' : 'Выберите адрес доставки '
    const closestSlot = slots.selectedSlot.date ? getNearestDeliveryText(slots.selectedSlot) : 'И мы рассчитаем время и стоимость доставки'
    let [openLk, setOpenLk] = useState(false)
    let [openAuth, setOpenAuth] = useState(false)
    const [openMap, setOpenMap] = useState(false)
    const [focusedInput, setFocusedInput] = useState(false)
    const width = useWindowDimensions()
    const {query, onQueryChange} = useContext(SearchContext)
    const navigate = useNavigate()
    const location = useLocation()

    return <div className={styles.headerContentWrapper}>
        <div className={styles.headerContent}>

            <User user={user} setOpenLk={setOpenLk}/>

            <NavLink onClick={() => window.scrollTo(0, 0)} to={'/'}>
                <div style={{marginRight: width > 1439 ? 388 : focusedInput ? 91 : 23}} className={styles.logo}>
                    <SText size={width > 1439 ? 32 : 26} weight={900} lineHeight={width > 1439 ? 40 : 33}
                           color={Colors.darkGray}>{'Ecomarket'}</SText>
                </div>
            </NavLink>


            {openAuth && <Auth onClose={() => setOpenAuth(false)}/>}
            {openLk && <AccountNavigationModal onClose={() => {
                window.scrollTo(0, 0)
                setOpenLk(false)
            }}/>}


            {
                width > 1439
                    ? <div style={{left: focusedInput ? 0 : 194, width: focusedInput ? 519 : 325}}
                           className={styles.inputWrapper}>
                        <div onClick={() => setSearchPopUpVisible(true)} className={styles.input}>
                            <input onFocus={() => setFocusedInput(true)} onBlur={() => setFocusedInput(false)}
                                   placeholder={'Поиск по товарам'} value={query}
                                   onChange={(e) => onQueryChange(e.target.value)}
                                   type="text"/>
                            {query.length > 0 && <div onClick={(e) => {
                                onQueryChange('')
                            }} className={styles.closeSearch}>
                                <img src={closeSearch}/>
                            </div>}
                            <div className={styles.verticalLine}/>
                            <div onClick={() => {
                                navigate('/search?q=' + query);
                                setSearchPopUpVisible(false)
                            }} className={styles.search}>
                                <img src={search}/>
                            </div>
                        </div>
                    </div>
                    : focusedInput
                        ? <div className={styles.inputWrapper}>
                            <div onClick={() => setSearchPopUpVisible(true)} className={styles.input}>
                                <input onFocus={() => setSearchPopUpVisible(true)} autoFocus={true}
                                       onBlur={() => setFocusedInput(false)}
                                       placeholder={'Поиск по товарам'} value={query}
                                       onChange={(e) => onQueryChange(e.target.value)}
                                       type="text"/>
                                {query.length > 0 && <div onClick={(e) => {
                                    onQueryChange('')
                                }} className={styles.closeSearch}>
                                    <img src={closeSearch}/>
                                </div>}
                                <div className={styles.verticalLine}/>
                                <div onClick={() => {
                                    navigate('/search?q=' + query);
                                    setSearchPopUpVisible(false)
                                }} className={styles.search}>
                                    <img src={search}/>
                                </div>
                            </div>
                        </div>
                        : <div onClick={() => setFocusedInput(true)} className={styles.searchBtn}>
                            <img src={whiteSearch}/>
                        </div>
            }


            {/*            <div style={{left: focusedInput ? 0 : 194, width: focusedInput ? 519 : 325}}
                 className={styles.inputWrapper}>
                <div onClick={() => setSearchPopUpVisible(true)} className={styles.input}>
                    <input onFocus={() => setFocusedInput(true)} onBlur={() => setFocusedInput(false)}
                           placeholder={'Поиск по товарам'} value={query}
                           onChange={(e) => onQueryChange(e.target.value)}
                           type="text"/>
                    {query.length > 0 && <div onClick={(e) => {
                        onQueryChange('')
                    }} className={styles.closeSearch}>
                        <img src={closeSearch}/>
                    </div>}
                    <div className={styles.verticalLine}/>
                    <div className={styles.search}>
                        <img src={search}/>
                    </div>
                </div>
            </div>*/}

            {/*<div onClick={() => setSearchPopUpVisible(true)} className={styles.input}>
                <input placeholder={'Поиск по товарам'} value={query}
                       onChange={(e) => onQueryChange(e.target.value)}
                       type="text"/>
                {query.length > 0 && <div onClick={() => onQueryChange('')} className={styles.closeSearch}>
                    <img src={closeSearch}/>
                </div>}
                <div className={styles.verticalLine}/>
                <div className={styles.search}>
                    <img src={search}/>
                </div>
            </div>*/}


            {(searchPopUpVisible && query.length > 0 && location.pathname !== '/search') &&
                <SearchPopUp onClose={() => setSearchPopUpVisible(false)}/>}


            <div onClick={() => setCatalogueVisible(prev => !prev)} className={styles.catalogue}>
                <div style={{background: catalogueVisible ? Colors.green : Colors.darkGray}}>
                    <img src={catalogue}/>
                </div>
                <SText size={18} weight={700} lineHeight={23} color={Colors.darkGray}>{'Каталог'}</SText>
            </div>


            {catalogueVisible && <CatalogueNavigator onClose={() => setCatalogueVisible(false)}/>}


            <div onClick={() => setOpenMap(true)} className={styles.address}>
                <div style={{display: 'flex', alignItems: 'center', gap: 11, marginBottom: 2}}>
                    <div title={formattedAddress} className={styles.addressText} style={{marginBottom: 2}}>
                        <SText size={18} weight={900} lineHeight={23}
                               color={Colors.darkGray}>{formattedAddress}</SText>
                    </div>
                    <img src={arrowDown}/>
                </div>
                <div>
                    <SText size={12} weight={400} lineHeight={15} color={'#929292'}>{closestSlot}</SText>
                </div>
            </div>


            {openMap && <MapDesktop onClose={() => setOpenMap(false)}/>}


            <HeaderCartButton/>


        </div>
    </div>

    /*    return <div style={{display: 'flex', justifyContent: 'center', background: '#f8f8f8'}}>
            <div className={styles.headerContentWrapper}>
                <div className={styles.headerContent}>

                    <div className={styles.adaptive}>
                        <div onClick={onUserClick} className={styles.user}>
                            {user.profilePicture ?
                                <img style={{width: 44, height: 44, borderRadius: '50%', objectFit: 'cover'}}
                                     src={BASE_DOMAIN + user.profilePicture}/> :
                                <img src={userZaglushka}/>}
                            {user.bonuses > 0 && <div className={styles.balls}>
                                <img src={balls}/>
                                <SText size={11} weight={700} lineHeight={20} color={'#fff'}>{user.bonuses}</SText>
                            </div>}
                        </div>

                        <NavLink onClick={() => window.scrollTo(0, 0)} to={'/'}>
                            <div className={styles.logo}>
                                <SText size={32} weight={900} lineHeight={40} color={Colors.darkGray}>{'Ecomarket'}</SText>
                            </div>
                        </NavLink>
                    </div>

                    {openAuth && <Auth onClose={() => setOpenAuth(false)}/>}
                    {openLk && <AccountNavigationModal onClose={() => {
                        window.scrollTo(0, 0)
                        setOpenLk(false)
                    }}/>}


                    <div onClick={() => setSearchPopUpVisible(true)} className={styles.input}>
                        <input placeholder={'Поиск по товарам'} value={query}
                               onChange={(e) => onQueryChange(e.target.value)}
                               type="text"/>
                        {query.length > 0 && <div onClick={() => onQueryChange('')} className={styles.closeSearch}>
                            <img src={closeSearch}/>
                        </div>}
                        <div className={styles.verticalLine}/>
                        <div className={styles.search}>
                            <img src={search}/>
                        </div>
                    </div>

                    {(searchPopUpVisible && query.length > 0) &&
                        <SearchPopUp onClose={() => setSearchPopUpVisible(false)}/>}

                    <div onClick={() => setCatalogueVisible(prev => !prev)} className={styles.catalogue}>
                        <div style={{background: catalogueVisible ? Colors.green : Colors.darkGray}}>
                            <img src={catalogue}/>
                        </div>
                        <SText size={18} weight={700} lineHeight={23} color={Colors.darkGray}>{'Каталог'}</SText>
                    </div>
                    {catalogueVisible && <CatalogueNavigator onClose={() => setCatalogueVisible(false)}/>}

                    <div onClick={() => setOpenMap(true)} className={styles.address}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 11, marginBottom: 2}}>
                            <div title={formattedAddress} className={styles.addressText} style={{marginBottom: 2}}>
                                <SText size={18} weight={900} lineHeight={23}
                                       color={Colors.darkGray}>{formattedAddress}</SText>
                            </div>
                            <img src={arrowDown}/>
                        </div>
                        <div>
                            <SText size={12} weight={400} lineHeight={15} color={'#929292'}>{closestSlot}</SText>
                        </div>
                    </div>

                    <HeaderCartButton/>

                </div>
                {openMap && <MapDesktop onClose={() => setOpenMap(false)}/>}
            </div>
        </div>*/
}

export const User = ({user, setOpenLk}) => {
    const {push} = useQueryParams()

    const onUserClick = () => {
        if (user.apikey) setOpenLk(true)
        else push(['auth', ''])
    }

    const getBonuses = (amount) => {
        amount = String(amount)
        if (amount.length === 5) return '~' + amount.slice(0, 2) + 'k'
        if (amount.length === 6) return '~' + amount.slice(0, 3) + 'k'
        if (amount.length === 7) return '~' + amount.slice(0, 1) + 'kk'
        if (amount.length === 8) return '~' + amount.slice(0, 2) + 'kk'
        return amount
    }

    return <div className={styles.userAbsolute}>
        <div onClick={onUserClick} className={styles.user}>
            {user.profilePicture
                ? <img style={{width: 44, height: 44, borderRadius: '50%', objectFit: 'cover'}}
                       src={BASE_DOMAIN + user.profilePicture}/>
                : <img src={userZaglushka}/>}
            {user.bonuses > 0 && <div className={styles.balls}>
                <img src={balls}/>
                <SText size={11} weight={700} lineHeight={20}
                       color={'#fff'}>{user.bonuses > 9999 ? getBonuses(user.bonuses) : user.bonuses}</SText>
            </div>}
        </div>
    </div>
}

const Header = () => {

    return <div style={{position: 'sticky', top: 0, zIndex: 20}}>
        <HeaderContent/>
    </div>
}

export default Header